import { Action, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const reducer = rootReducer(history);
const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
    routerMiddleware(history),
  ],
});

const NODE_ENV = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

if (NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export type RootState = ReturnType<typeof reducer>;

export default store;
