import React, { useCallback, useMemo, useState } from 'react';
import ModalAlert from './ModalAlert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { pushTypingStatus } from './typingStatusSlice';
import { TaskAssignmentRole } from '../agent/tasks/types';
import Button from '../../components/Button';
import { TaskStatus } from '../../models/taskTypes';
import { pullSpellCheck } from './spellCheckSlice';

const TypingStatus = ({ readOnly }: { readOnly?: boolean }) => {
  const dispatch = useDispatch();

  const taskUid = useSelector((rootState: RootState) => rootState.workspace.repositories.task!.uid);
  const workStatus = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.task!.status,
  );
  const isUpdating = useSelector(
    (rootState: RootState) => rootState.workspace.typingStatus.isUpdating,
  );
  const isTypingComplete = useSelector(
    (rootState: RootState) => rootState.workspace.typingStatus.isTypingComplete,
  );
  const taskAssignments = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.task!.taskAssignments,
  );
  // 맞춤법 검사 진행 여부
  const isSpellChecked = useSelector(
    (rootState: RootState) => rootState.workspace.spellCheck.isSpellChecked,
  );

  // 타이핑 상태 변경 확인창
  const [isOpenTypingStatus, setIsOpenTypingStatus] = useState(false);
  // 맞춤법 검사 유도창
  const [isOpenSpellCheck, setIsOpenSpellCheck] = useState(false);

  // 타이핑 할당 정보
  const typingAgentName = useMemo(() => {
    const taskAssignment = taskAssignments.filter(
      (item) => item.role === TaskAssignmentRole.TYPING,
    )[0];

    if (!taskAssignment) {
      return;
    }

    return taskAssignment.agent.name;
  }, [taskAssignments]);

  const handleCancelAlertTypingStatus = useCallback(() => {
    setIsOpenTypingStatus(false);
  }, []);

  const handleConfirmAlertTypingStatus = useCallback(() => {
    setIsOpenTypingStatus(false);
    dispatch(pushTypingStatus(taskUid));
  }, [dispatch, taskUid]);

  const handleChangeIsOpen = useCallback(() => {
    if (isSpellChecked) {
      setIsOpenTypingStatus(true);
    } else {
      setIsOpenSpellCheck(true);
    }
  }, [isSpellChecked]);

  // 맞춤법 검사 유도창 - 맞춤법 검사 진행
  const handleConfirmAlertSpellCheck = useCallback(() => {
    dispatch(pullSpellCheck());
    setIsOpenSpellCheck(false);
  }, [dispatch]);

  // 맞춤법 검사 유도창 - 닫기
  const handleCancelAlertSpellCheck = useCallback(() => {
    setIsOpenSpellCheck(false);
  }, []);

  return (
    <>
      {/* 완료 버튼 비활성화 상태: 타이피스트가 지정되지 않은 경우, 타이핑 완료 상태로 변경했을 때, 작업상태가 '타이핑 중'이 아님*/}
      <Button
        size="large"
        onClick={handleChangeIsOpen}
        type="primary"
        disabled={
          readOnly ||
          !typingAgentName ||
          isTypingComplete ||
          isUpdating ||
          workStatus !== TaskStatus.PROCEEDING_TYPE
        }
        className="w-full"
      >
        타이핑 완료
      </Button>

      {isOpenTypingStatus && (
        // 타이핑 상태 변경 확인창
        <ModalAlert
          maxWidth="xs"
          title="알림"
          content={
            <>
              타이핑 완료로 상태 변경 시 속기사님의 타이핑 검수가 시작되고 현재 타이핑 완료된
              내용으로 타이피스트 평가가 이루어 집니다.
              <br />
              타이핑 완료 상태로 변경하시겠습니까?
            </>
          }
          isOpen={true}
          onClose={handleCancelAlertTypingStatus}
          onConfirm={handleConfirmAlertTypingStatus}
        />
      )}

      {isOpenSpellCheck && (
        // 맞춤범 검사 유도창
        <ModalAlert
          maxWidth="xs"
          title="알림"
          content={
            <>
              맞춤법 검사 후 수정사항이 없어야 타이핑 완료를 할 수 있습니다.
              <br />
              맞춤법 검사를 진행해 주시기를 바랍니다.
            </>
          }
          isOpen={true}
          textConfirm="맞춤법 검사"
          onClose={handleCancelAlertSpellCheck}
          onConfirm={handleConfirmAlertSpellCheck}
        />
      )}
    </>
  );
};

export default React.memo(TypingStatus);
