import React from 'react';
import { Col as AntdCol, ColProps } from 'antd';
import classNames from 'classnames';

const Col = (props: ColProps) => {
  const { children, className, ...rest } = props;
  return (
    <AntdCol {...rest} className={classNames(`col`, { [className as string]: className })}>
      {children}
    </AntdCol>
  );
};

export default Col;
