import { Editor, Range, Transforms } from 'slate'
import debug from 'debug'

import {
  CURRENT_ACTION,
  CURRENT_ACTION as ACTION,
  UPDATE_STATUS,
  WindowEditor,
} from './window-editor'

/**
 * The `withHistory` plugin keeps track of the operation history of a Slate
 * editor as operations are applied to it, using undo and redo stacks.
 */

const log = debug('slate:w')

export const withWindow = <T extends Editor>(
  editor: T,
  options?: Partial<WindowEditor['window']['options']>
) => {
  const e = editor as T & WindowEditor

  e.window = {
    options: {
      showMinimap: false,
      showLog: false,
      scrollDelay: 50 /* milliseconds */,
      frameThreshold: 500,
      adjustLimit: 5,
      ...options,
    },

    currentAction: null,

    /**
     * 렌더링후, height, scroll 위치 정보를 업데이트함. 업데이트 된 사항이 있으면 true로 표시되어, adjust가 작동하지 않도록 막음.
     */
    updateStatus: UPDATE_STATUS.need_update,

    adjustCount: 5,

    /**
     * 전체 리스트의 크기
     */
    totalHeight: 10000,

    /**
     * 전체 아이템의 갯수
     * 초기값은 1이어야 한다.
     */
    totalItemCount: 0,

    /**
     * 이 영역에 랜더링된 첫번째 아이템의 index
     */
    startItemIndex: 0,

    /**
     * 이 영역에 랜더링된 아이템 갯수
     */
    itemCount: 5,

    /**
     * scrollToItem 중일 경우 타겟 인덱스
     */
    scrollToItemIndex: 0,

    /**
     * 전체 아이템 리스트 중에 화면에 출력할 일부 아이템들만 랜더링된다.
     * 이때 랜더링된 영역의 정보
     */
    container: {
      top: 0,
      bottom: 0,
      height: 0,
      meanOfItemHeight: 100,
    },

    reservedScrollTop: null,

    /**
     * 현재 출력중인 아이템들의 위치를 파악해서 action을 파악한다.
     * @param container
     */
    evaluateAction(container: HTMLDivElement): CURRENT_ACTION | null {
      const { current } = this.extractSize(container)
      const frameTop = Math.max(
        current.frameTop - this.options.frameThreshold,
        0
      )
      const frameBottom = Math.min(
        current.frameBottom + this.options.frameThreshold,
        current.height
      )

      // decide currentAction
      return current.containerTop <= frameTop /* 0,1,2 OR 3,4 */
        ? current.containerBottom >= frameBottom /* 0 OR 1,2 */
          ? null
          : current.containerBottom < frameTop /* 1 OR 2 */
          ? this.startItemIndex + this.itemCount === this.totalItemCount
            ? null
            : ACTION.JUMPING_DOWN
          : ACTION.WALKING_DOWN
        : current.containerTop < frameBottom /* 3 OR 4 */
        ? this.startItemIndex === 0
          ? null
          : ACTION.WALKING_UP
        : ACTION.JUMPING_UP
    },

    /**
     * 특정 index로 스크롤 이동하기
     * @param indexItem
     */
    scrollToItem(indexItem): void {
      switch (this.updateStatus) {
        case UPDATE_STATUS.updated:
          break
        case UPDATE_STATUS.need_update:
        case UPDATE_STATUS.is_updating:
        case UPDATE_STATUS.need_render:
          log(
            'scrollToItem',
            'skipped',
            `${this.startItemIndex}~${this.startItemIndex +
              this.itemCount -
              1} -> ${indexItem}`,
            {
              currentAction: this.currentAction,
              updateStatus: this.updateStatus,
            }
          )
          return
      }

      if (this.currentAction !== null) {
        log(
          'scrollToItem',
          'skipped',
          `${this.startItemIndex}~${this.startItemIndex +
            this.itemCount -
            1} -> ${indexItem}`,
          {
            currentAction: this.currentAction,
            updateStatus: this.updateStatus,
          }
        )
        return
      }

      log(
        'scrollToItem: ',
        `${this.startItemIndex}~${this.startItemIndex +
          this.itemCount -
          1} -> ${indexItem}`,
        {
          currentAction: this.currentAction,
          updateStatus: this.updateStatus,
        }
      )

      this.currentAction = ACTION.JUMPING_TO_INDEX
      this.updateStatus = UPDATE_STATUS.need_update

      this.startItemIndex = indexItem
      this.scrollToItemIndex = indexItem
      this.itemCount = Math.min(this.totalItemCount - indexItem, 5)
      this.container.top =
        ((this.totalHeight - this.container.meanOfItemHeight) * indexItem) /
        this.totalItemCount

      this.adjustCount = this.options.adjustLimit
      e.onChange()
    },

    scrollTo(container: HTMLDivElement): void {
      switch (this.updateStatus) {
        case UPDATE_STATUS.updated:
          break
        case UPDATE_STATUS.need_update:
        case UPDATE_STATUS.is_updating:
        case UPDATE_STATUS.need_render:
          log('scrollTo', 'skipped', {
            currentAction: this.currentAction,
            updateStatus: this.updateStatus,
          })
          return
      }

      if (this.currentAction !== null) {
        log('scrollTo', 'skipped', {
          currentAction: this.currentAction,
          updateStatus: this.updateStatus,
        })
        return
      }

      this.currentAction = this.evaluateAction(container)

      if (this.currentAction === null) {
        log('scrollTo', 'currentAction = null')
        return
      }

      log('scrollTo', 'currentAction = ', this.currentAction)
      const isChanged = this.updateIndexes(this.currentAction, container)

      this.adjustCount = this.options.adjustLimit

      if (isChanged) {
        this.updateStatus = UPDATE_STATUS.need_update
        log(' ㄴ', 'onChange', {
          currentAction: this.currentAction,
          updateStatus: this.updateStatus,
        })
        e.onChange()
      } else {
        this.currentAction = null
        this.updateStatus = this.options.showMinimap
          ? UPDATE_STATUS.need_render
          : UPDATE_STATUS.updated
        this.options.showMinimap && e.onChange()
        log(' ㄴ', 'no change', {
          currentAction: this.currentAction,
          updateStatus: this.updateStatus,
        })
      }
    },

    updateIndexes(
      action: CURRENT_ACTION,
      container: HTMLDivElement,
      skipDetach: boolean = false
    ): boolean {
      const { current } = this.extractSize(container)
      log(' ㄴ', current)

      let isChanged
      switch (action) {
        case ACTION.WALKING_DOWN: {
          isChanged = !skipDetach && this.detachTop(container)
          isChanged = this.attachBottom(container) || isChanged
          break
        }
        case ACTION.WALKING_UP: {
          isChanged = !skipDetach && this.detachBottom(container)
          isChanged = this.attachTop(container) || isChanged
          break
        }
        case ACTION.JUMPING_UP:
        case ACTION.JUMPING_DOWN: {
          isChanged = this.jump(container)
          break
        }
        default: {
          isChanged = !skipDetach && this.detachTop(container)
        }
      }

      return isChanged
    },

    /**
     * render후에 실행된다.(useEffect)
     * 혹시 attach가 빠진부분이 없는지 체크한다.
     * @param container
     */
    adjust(container: HTMLDivElement): void {
      log('adjust - ', {
        currentAction: this.currentAction,
        updateStatus: this.updateStatus,
      })

      switch (this.updateStatus) {
        case UPDATE_STATUS.updated:
        case UPDATE_STATUS.need_update: // unreached
          break
        case UPDATE_STATUS.is_updating:
        case UPDATE_STATUS.need_render:
          log(' ㄴ', 'skipped', {
            currentAction: this.currentAction,
            updateStatus: this.updateStatus,
          })
          return
      }

      // 주석처리사유: 스크롤이 아닌 문서 편집중 리랜더 되었다면 adjust가 필요.
      // if (!this.currentAction) {
      //   log(" ㄴ", "skipped", { currentAction: this.currentAction, updateStatus: this.updateStatus });
      //   return;
      // }

      if (!this.adjustCount) {
        log(' ㄴ', 'skipped adjustCount: ', this.adjustCount)
        this.currentAction = null
        return
      }

      this.adjustCount--

      const action = this.evaluateAction(container)

      const isChanged = this.updateIndexes(action, container, false)

      if (isChanged) {
        this.updateStatus = UPDATE_STATUS.need_update
        e.onChange()
        log(' ㄴ', 'onChange', {
          currentAction: this.currentAction,
          updateStatus: this.updateStatus,
        })
      } else {
        this.currentAction = null
        this.resolveSelection()
        log(' ㄴ', 'no change', {
          currentAction: this.currentAction,
          updateStatus: this.updateStatus,
        })
      }
    },

    jump(container: HTMLDivElement): boolean {
      const { current } = this.extractSize(container)

      const ratio = current.frameTop / current.height

      const startItemIndex = Math.trunc(this.totalItemCount * ratio)
      const itemCount = Math.min(10, this.totalItemCount - startItemIndex)
      log(' ㄴ jump: ', startItemIndex, startItemIndex + itemCount - 1)

      if (
        startItemIndex === this.startItemIndex &&
        itemCount === this.itemCount
      ) {
        return false
      }
      this.container.top = current.frameTop
      this.startItemIndex = startItemIndex
      this.itemCount = itemCount
      return true
    },

    detachTop(container: HTMLDivElement): boolean {
      const { current } = this.extractSize(container)
      const frameTop = current.frameTop - this.options.frameThreshold

      let i = 0
      let newContainerTop = current.containerTop
      for (const block of container.childNodes) {
        // @ts-ignore
        const blockBottom =
          // @ts-ignore
          current.containerTop + block.offsetTop + block.offsetHeight
        if (blockBottom < frameTop) {
          i++
        } else {
          // @ts-ignore
          newContainerTop += block.offsetTop // 새로운 container의 top 지정
          break
        }
      }
      this.startItemIndex = this.startItemIndex + i
      this.itemCount = this.itemCount - i
      this.container.top = newContainerTop
      this.container.bottom = undefined

      log(' ㄴ detachTop: detach ', i)
      log(
        ' ㄴ detachTop: start ',
        this.startItemIndex,
        'end ',
        this.startItemIndex + this.itemCount - 1
      )
      return !!i
    },

    /**
     * startIndex를 변경하지 않는다.
     * @param container
     */
    detachBottom(container: HTMLDivElement): boolean {
      const { current } = this.extractSize(container)
      const frameBottom = current.frameBottom + this.options.frameThreshold

      log(
        ' ㄴ detachBottom',
        `frameBottom: ${current.frameBottom} - containerBottom: ${current.containerBottom}`
      )

      let detachCount = 0
      let newContainerBottom

      for (let idx = container.childNodes.length; idx > 0; idx--) {
        const block = container.childNodes[idx - 1]

        // @ts-ignore
        const blockTop = current.containerTop + block.offsetTop
        if (blockTop > frameBottom) {
          detachCount++
        } else {
          newContainerBottom =
            current.frameHeight -
            // @ts-ignore
            (current.containerTop + block.offsetTop + block.offsetHeight) // 새로운 container의 top 지정
          break
        }
      }
      this.itemCount = this.itemCount - detachCount
      this.container.top = undefined
      this.container.bottom = newContainerBottom

      log(' ㄴ detachBottom: detach ', detachCount, newContainerBottom)
      log(
        ' ㄴ detachBottom: start ',
        this.startItemIndex,
        'end ',
        this.startItemIndex + this.itemCount - 1
      )
      return !!detachCount
    },

    /**
     * 항상 detachBottom이 실행된 후에 실행된다.
     * endIndex를 변경하지 않는다.
     * @param container
     */
    attachTop(container: HTMLDivElement): boolean {
      const { current } = this.extractSize(container)
      const frameTop = Math.max(
        current.frameTop - this.options.frameThreshold,
        0
      )
      if (current.containerTop < frameTop) {
        return false
      }

      const currentStartIndex = this.startItemIndex
      const currentItemCount = container.childNodes.length
      const currentMeanOfItemHeight = current.containerHeight / currentItemCount

      const i = Math.ceil(
        (current.containerTop - frameTop) / currentMeanOfItemHeight
      )
      const nextStartIndex = Math.max(this.startItemIndex - i, 0)

      this.itemCount = this.startItemIndex + this.itemCount - nextStartIndex
      this.startItemIndex = nextStartIndex

      log(
        ' ㄴ attachTop: ',
        `currentTop: ${currentStartIndex} - nextTop: ${nextStartIndex}`
      )
      log(
        ' ㄴ attachTop: start ',
        this.startItemIndex,
        'end ',
        this.startItemIndex + this.itemCount - 1
      )

      return currentStartIndex > nextStartIndex
    },

    /**
     * 항상 detachTop이 실행된 후에 실행된다.
     * startIndex를 변경하지 않는다.
     * @param container
     */
    attachBottom(container: HTMLDivElement): boolean {
      const { current } = this.extractSize(container)
      const frameBottom = current.frameBottom + this.options.frameThreshold
      if (frameBottom < current.containerBottom) {
        return false
      }

      const currentItemCount = container.childNodes.length
      const currentEndIndex = this.startItemIndex + this.itemCount - 1
      const currentMeanOfItemHeight = current.containerHeight / currentItemCount

      const i = Math.ceil(
        Math.max(0, frameBottom - current.containerBottom) /
          currentMeanOfItemHeight
      )
      const nextEndIndex = Math.min(
        currentEndIndex + i,
        this.totalItemCount - 1
      )
      this.itemCount = nextEndIndex - this.startItemIndex + 1

      log(
        ' ㄴ attachBottom: ',
        `currentEnd: ${currentEndIndex} - nextEnd: ${nextEndIndex}`
      )
      log(
        ' ㄴ attachBottom: start ',
        this.startItemIndex,
        'end ',
        this.startItemIndex + this.itemCount - 1
      )

      return currentEndIndex < nextEndIndex
    },

    /**
     * 랜더링한 후 meanOfItemHeight, totalHeight, container의 offsetTop 정보를 재계산한다.
     * @param container
     */
    update(container: HTMLDivElement): void {
      log('update - ', {
        currentAction: this.currentAction,
        updateStatus: this.updateStatus,
      })
      const { windowable, current } = this.extractSize(container)

      switch (this.updateStatus) {
        case UPDATE_STATUS.updated:
          return
        case UPDATE_STATUS.need_update:
          break
        case UPDATE_STATUS.is_updating:
          // scroll 이동
          if (this.reservedScrollTop !== null) {
            windowable.scrollTo(0, this.reservedScrollTop)
            log(' ㄴ', 'scroll move: ', this.reservedScrollTop)
            this.reservedScrollTop = null
          }

          // minimap enabled일 경우 처리
          if (this.options.showMinimap) {
            this.updateStatus = UPDATE_STATUS.need_render
            e.onChange()
          } else {
            this.updateStatus = UPDATE_STATUS.updated
          }

          log(
            ' ㄴ',
            `skipped${this.options.showMinimap}` ? ' & onChange' : '',
            {
              currentAction: this.currentAction,
              updateStatus: this.updateStatus,
            }
          )
          return
        case UPDATE_STATUS.need_render: // unreached
          break
      }

      log(' ㄴ', 'before', current)

      // update meanOfItemHeight
      const newMeanOfItemHeight = current.containerHeight / this.itemCount

      // update totalHeight
      const newTotalHeight =
        newMeanOfItemHeight * (this.totalItemCount - this.itemCount) +
        current.containerHeight

      let newContainerTop
      let newContainerBottom
      let reservedScrollTop

      let isChanged = false

      if (this.currentAction === ACTION.JUMPING_TO_INDEX) {
        log(
          ' ㄴ',
          'jumping_to_index',
          `${this.startItemIndex}~${this.startItemIndex +
            this.itemCount -
            1} -> ${this.scrollToItemIndex}`
        )

        const childIndex = this.scrollToItemIndex - this.startItemIndex
        const block = container.childNodes[childIndex]

        if (this.startItemIndex === 0) {
          newContainerTop = 0
        } else if (
          this.startItemIndex + this.itemCount ===
          this.totalItemCount
        ) {
          newContainerBottom = current.frameHeight - newTotalHeight
          newContainerTop = newTotalHeight - current.containerHeight
        } else {
          newContainerTop = this.container.top || current.containerTop
        }
        // @ts-ignore
        reservedScrollTop = newContainerTop + block.offsetTop
        isChanged = true
      } else {
        // update container top and move scroll

        if (this.startItemIndex === 0) {
          newContainerTop = 0
        } else if (
          this.startItemIndex + this.itemCount ===
          this.totalItemCount
        ) {
          newContainerBottom = undefined
          newContainerTop = newTotalHeight - current.containerHeight
        } else {
          newContainerTop = Math.trunc(
            ((newTotalHeight - current.containerHeight) /
              (this.totalItemCount - this.itemCount)) *
              this.startItemIndex
          )
        }
        reservedScrollTop =
          current.frameTop - current.containerTop + newContainerTop
      }

      this.reservedScrollTop = reservedScrollTop

      isChanged =
        this.container.top !== newContainerTop ||
        newTotalHeight !== this.totalHeight ||
        isChanged

      this.totalHeight = newTotalHeight
      this.container.meanOfItemHeight = newMeanOfItemHeight
      this.container.bottom = newContainerBottom
      this.container.top = newContainerBottom ? undefined : newContainerTop

      log(' ㄴ', 'after', {
        frameTop: reservedScrollTop,
        frameBottom: reservedScrollTop + current.frameHeight,
        containerTop: newContainerTop,
        containerBottom: newContainerTop + current.containerHeight,
        height: newTotalHeight,
      })

      log(
        ' ㄴ mean: ',
        newMeanOfItemHeight,
        ', height: ',
        newTotalHeight,
        ', container top: ',
        newContainerTop,
        ', container height: ',
        current.containerHeight
      )

      if (isChanged) {
        this.updateStatus = UPDATE_STATUS.is_updating
        log(' ㄴ', 'onChange', {
          currentAction: this.currentAction,
          updateStatus: this.updateStatus,
        })
        e.onChange()
      } else {
        this.updateStatus = UPDATE_STATUS.updated
        log(' ㄴ', 'no change', {
          currentAction: this.currentAction,
          updateStatus: this.updateStatus,
        })
      }
    },

    resolveSelection() {
      const endIndex = this.startItemIndex + this.itemCount - 1

      const { selection } = e

      if (selection) {
        const [start, end] = Range.edges(selection)

        if (
          start.path[0] >= this.startItemIndex &&
          start.path[0] <= endIndex &&
          end.path[0] >= this.startItemIndex &&
          end.path[0] <= endIndex
        ) {
          return
        }
        Transforms.deselect(e)
      }
    },

    extractSize(container: HTMLDivElement) {
      const windowable = container.offsetParent!
      const current = {
        frameTop: windowable.scrollTop,
        frameBottom: windowable.scrollTop + windowable.clientHeight,
        frameHeight: windowable.clientHeight,
        containerTop: container.offsetTop,
        containerBottom: container.offsetTop + container.scrollHeight,
        containerHeight: container.scrollHeight,
        height: windowable.scrollHeight,
      }
      return { windowable, current }
    },

    // log: function(...arg: any[]) {
    //   this.options.showLog && console.debug(...arg);
    // },
  }

  return e
}
