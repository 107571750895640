import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import {
  SpellCheckData,
  SpellCheckErrorType,
  SpellCheckItem,
  SpellCheckResultItem,
  SpellCheckState,
} from './types';
import { BaseEditor, Node } from 'slate';
import api from '../../api';
import { config } from '../../config';
import { ErrorCode } from '../../models';
import { ReactEditor, WindowEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import { replaceInEditor } from '../../utils/findReplace';
import { toastr } from 'react-redux-toastr';

const initialState: SpellCheckState = {
  isOpen: false,
  data: [],
  sentences: [],
  candidates: {},
  changes: {},
  isFetching: false,
  isCheckingLength: false,
  isSpellChecked: false,
  isCommitting: false,
};

export const spellCheckSlice = createSlice({
  name: 'SPELL-CHECK',
  initialState,
  reducers: {
    resetSpellCheck(state) {
      state.isOpen = initialState.isOpen;
      state.data = initialState.data;
      state.sentences = initialState.sentences;
      state.candidates = initialState.candidates;
      state.changes = initialState.changes;
      state.isFetching = initialState.isFetching;
      state.isCheckingLength = initialState.isCheckingLength;
      state.isSpellChecked = initialState.isSpellChecked;
      state.isCommitting = initialState.isCommitting;
    },
    openSpellCheck(state) {
      state.isOpen = true;
      state.candidates = {};
      state.changes = {};
    },
    closeSpellCheck(state) {
      state.isOpen = initialState.isOpen;
    },
    changeSpellChecked(
      state,
      { payload: isSpellChecked }: PayloadAction<SpellCheckState['isSpellChecked']>,
    ) {
      state.isSpellChecked = isSpellChecked;
    },
    changeSpellCheckingLimit(
      state,
      { payload: isCheckingLength }: PayloadAction<SpellCheckState['isCheckingLength']>,
    ) {
      state.isCheckingLength = isCheckingLength;
    },
    changeSpellCheckError(state, { payload: error }: PayloadAction<SpellCheckState['error']>) {
      state.error = error;
    },
    startSpellCheckFetch(state) {
      state.isFetching = true;
      state.isCheckingLength = false;
      state.error = undefined;
    },
    successSpellCheckFetch(state, { payload }: PayloadAction<SpellCheckData['data']>) {
      state.isFetching = false;
      state.error = undefined;
      state.data = payload;
      state.sentences = payload.map((item, index) =>
        resolveSpellCheckData(state.candidates, item.result, item.sentence, index, 0, ''),
      );
    },
    failSpellCheckFetch(state, { payload }: PayloadAction<SpellCheckState['error']>) {
      state.isFetching = false;
      if (payload) {
        state.error = payload;
      }
    },
    // 변경할 목록에 추가한다. 오류 단어 목록해서 해당 단어 제거
    addChanges(state, { payload: candidate }: PayloadAction<SpellCheckResultItem>) {
      const key = candidate.offset!.join('.');
      state.changes[key] = {
        wordReplace: candidate.output,
        wordFind: candidate.input,
        offset: candidate.offset!,
      };
      delete state.candidates[key];
    },
    startCommitting(state) {
      state.isCommitting = true;
    },
    // 교정할 목록의 내용을 에디터에 반영한다.
    finishCommitting(state) {
      state.isCommitting = false;
      state.isOpen = false;
      state.data = [];
      state.sentences = [];
      state.candidates = {};
      state.changes = {};
      state.isFetching = false;
      state.isCheckingLength = false;
      state.isSpellChecked = true;
    },
  },
});

export const {
  resetSpellCheck,
  openSpellCheck,
  closeSpellCheck,
  startSpellCheckFetch,
  successSpellCheckFetch,
  failSpellCheckFetch,
  addChanges,
  startCommitting,
  finishCommitting,
  changeSpellChecked,
  changeSpellCheckingLimit,
  changeSpellCheckError,
} = spellCheckSlice.actions;
export default spellCheckSlice.reducer;

export const commit = (
  editor: BaseEditor & ReactEditor & WindowEditor & HistoryEditor,
): AppThunk => {
  return async (dispatch, getState) => {
    replaceInEditor(Object.values(getState().workspace.spellCheck.changes), editor);
    toastr.info('알림', '반영 완료했습니다.');
  };
};

// 맞춤법 검사 요청을 할 수 있는지 확인을 먼저 진행후 맞춤법 검사 요청을 진행한다. 맞춤법 검사 요청하기 위한 query(string) 가공
export const pullSpellCheck = (): AppThunk => {
  return async (dispatch, getState) => {
    // 한 번 요청시 최대 입력 텍스트
    // 문단(문장)당 최대 글자 수
    const { sentence: maxSentenceLength, text: maxQueryLength } = config.limit.spellcheck;
    const script = getState().workspace.editor.script;
    let queryValue = '';
    let queryLength = 0;

    // 5만자 넘는 경우 배열에 쿼리 넣음
    const queryArray: string[] = [];

    try {
      dispatch(changeSpellCheckingLimit(true));

      script.forEach((paragraph, paraIndex) => {
        const sentence = Node.string(paragraph);
        const textLength = sentence.length + 1;

        // 문단(문장)당 최대 글자 수를 넘기는 확인
        if (textLength > maxSentenceLength) {
          throw new Error('SentenceLengthOverflow');
        } else {
          queryLength += textLength;
          queryValue += sentence;
        }

        // 한 번 요청시 최대 입력 글자 수를 넘기는 확인. Or 마지막 문단 확인
        if (queryLength > maxQueryLength || paraIndex === script.length - 1) {
          queryArray.push(queryValue);
          queryLength = 0;
          queryValue = '';
        } else {
          queryValue += '\n';
        }
      });
    } catch (error) {
      if (error instanceof Error && error.message === 'SentenceLengthOverflow') {
        dispatch(changeSpellCheckError(String(ErrorCode.INPUT_SENTENCE_OVERFLOW)));
      } else {
        throw error;
      }
      return;
    }

    // 팝업 노출
    dispatch(openSpellCheck());
    // api 호출 시작
    dispatch(startSpellCheckFetch());

    // 5만자 넘는 경우 배열에 쿼리 넣은 경우
    const result = queryArray.map((query, index) => {
      return api.spellCheck(query!);
    });

    Promise.all(result)
      .then((sentences) => {
        let data: SpellCheckItem[] = [];
        sentences.forEach((sentence) => {
          data = [...data, ...sentence];
        });
        dispatch(successSpellCheckFetch(data));
      })
      .catch((error) => {
        dispatch(failSpellCheckFetch());
      });
  };
};

/**
 * spell check api 호출 후 받은 데이터의 한 문단을 정리한다. recursive하게 처리한다.
 * @param candidates error인 result만 모은 object
 * @param result result item 목록에서 이미 처리된 앞부분을 제외한 나머지 items
 * @param nextText sentence에서 이미 처리된 앞부분을 잘라낸 나머지 text
 * @param sentenceOffset 현재 처리하는 sentence의 index. offset 계산을 위해 계속 전달한다.
 * @param inputOffset 현재 처리하는 result item input의 index. offset 계산을 위해 계속 전달한다.
 * @param prevNoErrorText 현재 result item 전에 처리해야 할 no_error input text를 넘겨 받는다.
 */
const resolveSpellCheckData: (
  candidates: SpellCheckState['candidates'],
  result: SpellCheckResultItem[],
  nextText: string,
  sentenceOffset: number,
  inputOffset: number,
  prevNoErrorText: string,
) => SpellCheckResultItem[] = (
  candidates,
  result,
  nextText,
  sentenceOffset,
  inputOffset,
  prevNoErrorText,
) => {
  const prevResult: SpellCheckResultItem[] = [];
  const currentResult: SpellCheckResultItem[] = [];
  const resultItem = result.shift()!;

  // result가 빈 배열일 경우(마지막에 도달했을 경우)
  if (!resultItem) {
    prevNoErrorText += nextText;
    if (prevNoErrorText) {
      prevResult.push({
        input: prevNoErrorText,
        output: prevNoErrorText,
        etype: SpellCheckErrorType.NO_ERROR,
      });
    }
    return prevResult;
  }

  const { input, output, etype, help } = resultItem;

  const inputIndex = nextText.indexOf(input);
  const inputStartOffset = inputOffset + inputIndex;
  const inputEndOffset = inputStartOffset + input.length;
  const prevInput = nextText.substring(0, inputIndex);
  nextText = nextText.substring(inputIndex + input.length);

  if (resultItem.etype === SpellCheckErrorType.NO_ERROR) {
    // No Error일 경우, 이번 input까지 mergedText에 포함
    prevNoErrorText += prevInput + input;
  } else {
    // error 일 경우
    // mergedText는 받은 것을 그대로 넘겨줌. 이번 input 전까지만 mergedText에 포함
    prevNoErrorText += prevInput;

    if (prevNoErrorText) {
      prevResult.push({
        input: prevNoErrorText,
        output: prevNoErrorText,
        etype: SpellCheckErrorType.NO_ERROR,
      });
      prevNoErrorText = '';
    }

    const currentResultItem = {
      input,
      output,
      etype,
      help,
      offset: [sentenceOffset, inputStartOffset] as [number, number],
    };
    currentResult.push(currentResultItem);

    // error만 별도로 저장
    candidates[currentResultItem.offset.join('.')] = currentResultItem;
  }

  return prevResult.concat(
    currentResult,
    resolveSpellCheckData(
      candidates,
      result,
      nextText,
      sentenceOffset,
      inputEndOffset,
      prevNoErrorText,
    ),
  );
};
