import React, { Suspense, useEffect, useMemo } from "react";
import { Route, Switch, useLocation } from 'react-router';
import { isExpired } from 'react-jwt';
import { replace } from "connected-react-router";
import { config } from 'src/config';
import { useDispatch, useSelector } from 'react-redux';
import { isChrome } from 'react-device-detect';
import { RootState } from 'src/app/store';
import lazyRetry from 'src/layout/lazyRetry';
import { decode } from 'jsonwebtoken';
import { AuthState } from 'src/models/authTypes';
import { successLoginRequest } from 'src/features/login/authSlice';

import Login from 'src/features/login/Login';
import Layout from 'src/layout/Layout';
import PageNotFound from "./features/PageNotFound";
import { Agent } from "src/models/agentTypes";
import { Spin } from "antd";
import { EntryDiv } from "./features/workspace/Entry";
const WorkspaceEntry = React.lazy(() => lazyRetry(() => import('src/features/workspace/Entry')));

function App() {
  const {
    isLogged,
  } = useSelector((rootState: RootState) => rootState.auth);

  const dispatch = useDispatch();
  const location = useLocation();
  /* 이전 링크 */
  const prevLink = useMemo(() => {
    const locationState = location.state as { prevLink?: string };
    return (locationState && locationState?.prevLink) || null;
  }, [location.state]);

  /***
   * 1. 로그인 상태 확인
   *  1.1 로그아웃 상태
   *    A. 토큰 유무
   *      A.1. 토큰 있음
   *        a. 토큰의 유효 기간 확인
   *         a.1. 토큰이 만료되지 않음 → 첫번째 화면으로 이동
   *         a.2. 토큰이 만료됨 → 로그인 화면 이동
   *      A.2. 토큰 없음 → 로그인 화면 이동
   * 1.2 로그인 상태 → 액션 없음
   * ***/
  useEffect(() => {
    // 1. 로그인 상태 확인
    // 1.1 로그아웃 상태
    if (!isLogged) {
      // '로그인 유지' 체크에 따른 토큰 저장 위치 다름
      const accessToken = sessionStorage.getItem(config.API_TOKEN) || localStorage.getItem(config.API_TOKEN);
      // A. 토큰 유무
      // A.1. 토큰 있음
      if (accessToken) {
        // a. 토큰의 유효 기간 확인
        const isApiTokenExpired = isExpired(accessToken);
        // a.1. 토큰이 만료되지 않음 → 첫번째 화면으로 이동
        if (!isApiTokenExpired) {
          // decode된 JWT
          const myDecodedToken = decode(accessToken) as { agent: Agent };
          const { agent } = myDecodedToken;
          dispatch(
            successLoginRequest({
              agent,
              token: {
                accessToken,
              },
            } as AuthState),
          );
        } else {
          // 유효기간 만료된 토큰 제거
          sessionStorage.removeItem(config.API_TOKEN);
          localStorage.removeItem(config.API_TOKEN);
          // a.2. 토큰이 만료됨 → 로그인 화면 이동
          dispatch(replace(config.page.link.login));
        }
      } else {
        // A.2. 토큰 없음 → 로그인 화면 이동
        dispatch(replace(config.page.link.login));
      }
    }
  }, [dispatch, prevLink, isLogged]);

  if (!isChrome) {
    return (
      <EntryDiv>
        <span style={{ textTransform: 'capitalize' }}>Chrome 브라우저로 접속해주세요.</span>
      </EntryDiv>
    );
  }


  return (
    <Suspense fallback={<Spin tip="Loading" />}>
    <Switch>
      <Route exact={true} path={config.page.link.login} component={Login} />
      <Route exact={true} path="/editor/:id" component={WorkspaceEntry} />
      <Route exact={true} path="/404" component={PageNotFound} />
      {isLogged && <Route path="/" component={Layout} />}
      {/* 페이지 없음 */}
      <Route path="/" component={PageNotFound} />
    </Switch>
    </Suspense>
  );
}

export default App;
