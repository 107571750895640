import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsideInfoBox from './AsideInfoBox';
import { timeStamp, toDateTime } from '../../utils/format';
import { RootState } from '../../app/store';
import _ from 'lodash';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';
import { changeSeek } from './playerSlice';
import { Product, Unknown } from 'src/models';
import { toastr } from 'react-redux-toastr';
import { TaskAssignmentRole } from '../agent/tasks/types';
import { TaskStatus } from "../../models/taskTypes";
import { LinkInText } from "../../components/link";

const AsideTaskInfo = ({ refPlayer }: { refPlayer: React.RefObject<ReactPlayer> }) => {
  const dispatch = useDispatch();

  const {
    description: orderDescription,
    phrases,
    responseFormats,
  } = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.order!.requirementDetails,
  );

  const { attachments, productType } = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.order!,
  );

  const {
    source: { name: playFileName },
    recordedAt,
  } = useSelector((rootState: RootState) => rootState.workspace.repositories.playFile!);

  const taskAssignments = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.task!.taskAssignments,
  );

  const taskClippings = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.task!.details!.clippings,
  );

  const status = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.task!.status,
  );

  // 다운로드 가능한 참조 파일
  const downloadReferenceFiles = useMemo(() => {
    if (!attachments) {
      return [];
    }
    return _.filter([...attachments], (fileObj) => fileObj.isPublic);
  }, [attachments]);

  /* 타이핑 작업이 아니면, "타이핑 마감 기한" 정보는 노출 하지 않는다. */
  const typingDueAt = useMemo(() => {
    if (status !== TaskStatus.PROCEEDING_TYPE && taskAssignments.length === 0) {
      return null;
    }

    const taskAssignment = taskAssignments.filter(
      (item) => item.role === TaskAssignmentRole.TYPING,
    )[0];

    if (!taskAssignment) {
      return null;
    }

    const { dueAt } = taskAssignment;

    return dueAt;
  }, [taskAssignments, status]);

  const clippings = useMemo(() => {
    return _.sortBy(taskClippings, 'start');
  }, [taskClippings]);

  /* 부분 녹취 시간으로 이동 */
  const handleSeekTo = useCallback(
    (event) => {
      const target = event.target;
      const { time } = target.dataset;
      if (time) {
        const value = Number(time);
        if (value || value === 0) {
          dispatch(changeSeek(value));
          refPlayer.current!.seekTo(value, 'seconds');
        }
      }
    },
    [dispatch, refPlayer],
  );

  const handleCopyFileName = useCallback(() => {
    onCopyFileName(playFileName);
  }, [playFileName]);

  return (
    <>
      {status === TaskStatus.PROCEEDING_TYPE && (
        <AsideInfoBox className="due-at edit-area" title="타이핑 마감 기한">
          {typingDueAt ? toDateTime(typingDueAt, 'YYYY-MM-DD H:mm A') : '-'}
        </AsideInfoBox>
      )}

      {productType !== Product.CAPTION && (
        <AsideInfoBox className="due-at edit-area" title="녹음일" direction="row">
          {recordedAt
            ? toDateTime(recordedAt, 'YYYY-MM-DD')
            : productType === Product.CERTIFICATED
            ? Unknown.UNKNOWN
            : '-'}
        </AsideInfoBox>
      )}

      <AsideInfoBox className="play-file-name" title="파일명">
        {(
          <span onClick={handleCopyFileName} style={{ cursor: 'pointer' }}>
            {playFileName}
          </span>
        ) || '-'}
      </AsideInfoBox>

      <AsideInfoBox className="description edit-area" title="내용 설명">
        <LinkInText>{orderDescription || '-'}</LinkInText>
      </AsideInfoBox>

      {
        // 부분 녹취 있는 경우
        clippings.length > 0 && (
          <AsideInfoBox className="description" title="부분 녹취">
            <ol className="list-clipping">
              {clippings.map((clipping, index) => (
                <li key={'clipping-' + index}>
                  {index + 1}.
                  <button data-time={clipping.start} onClick={handleSeekTo}>
                    {timeStamp(clipping.start)}
                  </button>
                  ~
                  <button data-time={clipping.end} onClick={handleSeekTo}>
                    {timeStamp(clipping.end)}
                  </button>
                </li>
              ))}
            </ol>
          </AsideInfoBox>
        )
      }

      <AsideInfoBox className="phrases" title="참조 단어">
        {phrases || '-'}
      </AsideInfoBox>

      <AsideInfoBox className="response-formats" title="요청 파일 형식">
        {responseFormats && responseFormats.length > 0 ? responseFormats.join(', ') : '-'}
      </AsideInfoBox>

      {
        // 다운로드 가능한 참조 파일 있는 경우
        downloadReferenceFiles.length > 0 && (
          <AsideInfoBox className="description" title="참조 파일">
            <ol>
              {downloadReferenceFiles.map((fileObj, index) => (
                <li key={'file-' + index}>
                  {index + 1}.
                  <a
                    href={fileObj.downloadLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={true}
                    className="link-down"
                    title="새창에서 파일 다운로드"
                  >
                    {fileObj.name}
                  </a>
                </li>
              ))}
            </ol>
          </AsideInfoBox>
        )
      }
    </>
  );
};
export default React.memo(AsideTaskInfo);

export const StyledOfAsideInfo = styled.aside`
  flex: 1 0 258px;
  
  .list-clipping {
    li {
      margin-top: 10px;
    }

    button {
      background: transparent;
      text-decoration: underline;
    }
  }
`;

/*파일명 복사*/
export const onCopyFileName = (fileName: string) => {
  if (window.navigator.clipboard) {
    window.navigator.clipboard
      .writeText(fileName)
      .then(() => {
        toastr.info('알림', '파일명을 복사했습니다.');
      })
      .catch(() => {
        toastr.error('알림', '파일명 복사에 실패했습니다.');
      });
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = fileName;
    textArea.style.position = 'fixed';
    textArea.style.zIndex = '-10';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        toastr.info('알림', '파일명을 복사했습니다.');
      } else {
        toastr.error('알림', '파일명 복사에 실패했습니다.');
      }
    } catch (err) {
      toastr.error('알림', '파일명 복사에 실패했습니다.');
    }

    document.body.removeChild(textArea);
    return;
  }
};
