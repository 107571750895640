import React from 'react';
import { Input, InputProps } from 'antd';
import styled from '@emotion/styled';
import classNames from 'classnames';

const InputText = (props: InputProps) => {
  const { className, ...restProps } = props;

  return (
    <>
      <StyledInput
        className={classNames(`input-text`, { [className as string]: className })}
        {...restProps}
      />
    </>
  );
};
export default InputText;

const StyledInput = styled(Input)`
  &.input-type-text {
  }
`;