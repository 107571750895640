export enum AgentRole {
  EXPERT = 'EXPERT',
  QC = 'QC',
  SYNC = 'SYNC',
  TYPING = 'TYPING',
}

export interface Agent {
  id: number;
  loginId: string;
  name: string;
  authorType: 'agent';
  roles: AgentRole[];
}
