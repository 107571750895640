export enum UPDATE_STATUS {
  /**
   * scroll이나 adjust 과정에서 현재 출력중인 아이템 영역이 변경되었을 때,
   * 각종 요소의 높이 및 위치를 변경해야 한다. need_update로 지정하면 update 함수가 이 과정을 진행한다.
   */
  need_update = 'need_update',

  /**
   * update 함수에 의해 높이 및 위치값이 변경되면, is_updating으로 설정한다.
   * 그러면 render가 다시 실행되기 전에 adjust가 실행되는 것을 방지한다.
   */
  is_updating = 'is_updating',

  /**
   * showMinimap일 경우에만 필요한 상태이다.
   * render-update-adjust 사이클에서 미니맵을 렌더하기 위해 한번의 사이클을 더 돌게 한다.
   */
  need_render = 'need_render',

  /**
   * 현재 각종 요소의 높이 및 위치가 정상적인 상태일 때를 나타낸다.
   */
  updated = 'updated',
}

export enum CURRENT_ACTION {
  WALKING_DOWN = 'walking_down',
  WALKING_UP = 'walking_up',
  JUMPING_UP = 'jumping_up',
  JUMPING_DOWN = 'jumping_down',
  JUMPING_TO_INDEX = 'jumping_to_index',
}

/**
 * `WindowEditor` contains helpers for window-enabled editors.
 */
export interface WindowEditor {
  window: {
    options: {
      /**
       * minimap 출력 여부
       */
      showMinimap: boolean

      /**
       * log 출력 여부
       */
      showLog: boolean

      /**
       * scroll throttle 간격
       */
      scrollDelay: number /* milliseconds */

      /**
       * 스크롤 후 재조정 제한횟수. 제한하지 않으면 무한루프에 빠질 가능성이 있음.
       */
      adjustLimit: number

      /**
       * 윈도우 밖 영역은 랜더링하지 않는다. 이때, 스크롤을 대비하여 윈도우 밖 보이지 않는 영역도 일부 랜더링을 해놓는다.
       * frameThreshold에 지정된 픽셀만큼 윈도우 상단, 하단 영역을 각각 더 랜더링 해 놓는다.
       */
      frameThreshold: number
    }

    currentAction: null | CURRENT_ACTION

    updateStatus: UPDATE_STATUS

    adjustCount: number

    /**
     * 전체 리스트의 크기
     */
    totalHeight: number

    /**
     * 전체 아이템의 갯수
     */
    totalItemCount: number

    /**
     * 이 영역에 랜더링된 첫번째 아이템의 index
     */
    startItemIndex: number

    /**
     * 이 영역에 랜더링된 아이템 갯수
     */
    itemCount: number

    /**
     * scrollToItem 중일 경우 타겟 인덱스
     */
    scrollToItemIndex: number

    /**
     * 전체 아이템 리스트 중에 화면에 출력할 일부 아이템들만 랜더링된다.
     * 이때 랜더링된 영역의 정보
     */
    container: {
      top?: number
      bottom?: number
      height: number
      meanOfItemHeight: number
    }

    /**
     * update단계에서 재계산된 위치에 따라 scroll변경 해주어야 하는 값을 저장한다.
     * rerender가 완료된 다음, is_updating 상태에서 처리된다.
     */
    reservedScrollTop: number | null

    /**
     * 주어진 위치로 windowable를 이동시키고, 이동시킨 windowable에 container가 위치하도록 조정한다.
     * @param container block-container element
     */
    scrollTo: (container: HTMLDivElement) => void

    scrollToItem: (startItemIndex: number) => void

    update: (container: HTMLDivElement) => void

    [key: string]: any
  }
}
