import { isMacOs, isWindows } from 'react-device-detect';
import { ShortcutEvents, ShortcutKeys } from "../features/workspace/types";

// 방향키
export const arrowKeysSymbol = (key: string) => {
  switch (key) {
    case ShortcutKeys.ARROW_UP:
      return '↑';
    case ShortcutKeys.ARROW_DOWN:
      return '↓';
    case ShortcutKeys.ARROW_LEFT:
      return '←';
    case ShortcutKeys.ARROW_RIGHT:
      return '→';
    default:
      return '';
  }
};

// Single Shortcut Possible Values.
// 단독 단축키 가능한 키
const singleShortcutPossibleKeys = (): { value: string; label: string }[] => {
  const options = [{ value: 'Tab', label: 'Tab' }];
  // F1-F12
  for (let i = 1; i <= 12; i++) {
    options.push({ value: `F${i}`, label: `F${i}` });
  }

  return options;
};

// 기본키의 옵션값의 기본
const primaryKeyDefaultOptions = (): { value: string; label: string }[] => {
  const options = singleShortcutPossibleKeys();

  // a-z
  for (let i = 97; i <= 122; i++) {
    const keyCode = String.fromCharCode(i);
    options.push({ value: keyCode, label: keyCode.toUpperCase() });
  }

  // 0-9
  for (let i = 48; i <= 57; i++) {
    const keyCode = String.fromCharCode(i);
    options.push({ value: keyCode, label: keyCode });
  }

  // - = [ ] \ ; ’ , . / `
  '-=[]\\;’,./`'.split('').forEach((key) => {
    options.push({ value: key, label: key });
  });

  // 방향키
  [...Object.values(ShortcutKeys).filter((item) => item.indexOf('Arrow') > -1)].forEach((key) => {
    const label = arrowKeysSymbol(key);
    options.push({ value: key, label });
  });

  return options;
};

// "ctrl" === metaKey: true("cmd")
/*
 * event.key
 *   windows에서는 소문자/대문자. mac에서는 무조건 소문자 (shiftKey 값은 소문자/대문자에 따라 다름)
 * event.code
 *   event.key: 1 와 event.key: ! (shift 와 함께) 의 event.code: "Digit1" 로 모두 같다.(keyCode: 49 역시 같음)
 * shiftKey
 * ctrlKey(win) === metaKey(mac)
 * */
const initialShortcutKeys: {
  key: { [p: string]: string[] };
  title: { [p: string]: string };
  singleShortcutPossibleKeys: { value: string; label: string }[];
  primaryKeyDefaultOptions: { value: string; label: string }[];
  secondaryKeyDefaultOptions: { value: string; label: string }[];
} = {
  key: {
    [ShortcutEvents.SAVE]: ['s', ShortcutKeys.CTRL], // 스크립트 저장
    [ShortcutEvents.UNDO]: ['z', ShortcutKeys.CTRL], // 실행취소(Undo)
    [ShortcutEvents.REDO]: ['z', ShortcutKeys.SHIFT, ShortcutKeys.CTRL], // 재실행(Redo)

    [ShortcutEvents.PLAY_PAUSE]: ['Tab'], // 재생/일시 정지
    [ShortcutEvents.REWIND]: ['[', ShortcutKeys.CTRL], // 3초 되감기(뒤로 이동)
    [ShortcutEvents.FAST_FORWARD]: [']', ShortcutKeys.CTRL], // 3초 빨리 감기(앞으로 이동)
    [ShortcutEvents.SPEED_UP]: ['F3'], // 재생 속도 빠르게
    [ShortcutEvents.SPEED_DOWN]: ['F2'], // 재생 속도 느리게
    [ShortcutEvents.SPEED_RESET]: ['F4'], // 재생 속도 초기화
    [ShortcutEvents.PLAY_CURRENT_LOCATION]: ['e', ShortcutKeys.CTRL], // 현재 위치 재생

    [ShortcutEvents.FIND_REPLACE]: ['f', ShortcutKeys.CTRL], // 찾기/바꾸기
    [ShortcutEvents.SPELL_CHECK]: ['F7'], // 맞춤법 검사
    [ShortcutEvents.SHORTCUT_KEY]: ['/', ShortcutKeys.CTRL], // 단축키 안내
  },
  title: {
    [ShortcutEvents.SAVE]: '저장',
    [ShortcutEvents.UNDO]: '실행 취소',
    [ShortcutEvents.REDO]: '다시 실행',
    [ShortcutEvents.PLAY_PAUSE]: '재생/일시 정지',
    [ShortcutEvents.REWIND]: '초 뒤로 이동',
    [ShortcutEvents.FAST_FORWARD]: '초 앞으로 이동',
    [ShortcutEvents.SPEED_UP]: '재생 속도 빠르게',
    [ShortcutEvents.SPEED_DOWN]: '재생 속도 느리게',
    [ShortcutEvents.SPEED_RESET]: '재생 속도 초기화',
    [ShortcutEvents.PLAY_CURRENT_LOCATION]: '현재 위치 재생',
    [ShortcutEvents.FIND_REPLACE]: '찾기/바꾸기',
    [ShortcutEvents.SPELL_CHECK]: '맞춤법 검사',
    [ShortcutEvents.SHORTCUT_KEY]: '단축키 안내',
  },
  singleShortcutPossibleKeys: singleShortcutPossibleKeys(),
  primaryKeyDefaultOptions: primaryKeyDefaultOptions(),
  secondaryKeyDefaultOptions: [
    { value: ShortcutKeys.CTRL, label: isMacOs ? 'Cmd' : 'Ctrl' },
    { value: ShortcutKeys.SHIFT, label: 'Shift' },
    { value: ShortcutKeys.CTRL_SHIFT, label: isMacOs ? 'Cmd + Shift' : 'Ctrl + Shift' },
    { value: 'none', label: '없음' },
  ],
};

export default initialShortcutKeys;
