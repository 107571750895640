import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { InputText, InputPassword } from 'src/components/input';
import Checkbox from 'src/components/Checkbox';
import Button from 'src/components/Button';
import { StyledOfUnorderedList } from 'src/components/styledOfComponents';
import { Form } from 'antd';
import { FormItem } from 'src/components/form';
import Logo from 'src/components/logo';
import { Login as TLogin } from 'src/models/authTypes';
import { closePopupFailureLogin, loginRequest } from './authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { RootState } from 'src/app/store';
import { Route } from 'src/models';
import { Modal as AntdModal } from 'antd';
import { Helmet } from 'react-helmet-async';

enum FormNames {
  USER_NAME = 'username',
  PASSWORD = 'password',
  REMEMBER = 'remember',
}

const Login = (props: Route) => {
  const { location } = props;
  const dispatch = useDispatch();
  const { isLogged, errorMessage, requesting } = useSelector(
    (rootState: RootState) => rootState.auth,
  );

  // 이전 링크
  const prevLink = useMemo(() => {
    const locationState = location.state as { prevLink?: string }; // {prevLink?:string}
    return (locationState && locationState?.prevLink) || null;
  }, [location.state]);

  const [formValues, setFormValues] = useState<TLogin>({
    [FormNames.USER_NAME]: undefined,
    [FormNames.PASSWORD]: undefined,
    [FormNames.REMEMBER]: false,
  });
  const [componentDisabled, setComponentDisabled] = useState<boolean>(false);

  // form 입력값 변경시
  const handleValuesChange = useCallback((changedValues, allValues) => {
    setFormValues(allValues);
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(loginRequest(formValues));
    },
    [dispatch, formValues],
  );

  // 로그인 상태 확인: 로그인
  useLayoutEffect(() => {
    if (isLogged) {
      // 이전 링크가 있다면 이전 링크로 아니면 '/' 로 이동.
      dispatch(replace(prevLink || '/'));
    }
  }, [dispatch, isLogged, prevLink]);

  // [팝업] 로그인 실패 알림창
  useEffect(() => {
    if (errorMessage === '로그인 오류') {
      AntdModal.error({
        title: '로그인 오류',
        okText: '확인',
        content: (
          <p>
            아이디 또는 비밀번호를 잘못 입력되었습니다.
            <br />
            입력하신 내용을 다시 확인해주세요.
          </p>
        ),
        onOk() {
          dispatch(closePopupFailureLogin());
        },
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    if (requesting) {
      setComponentDisabled(true);
    } else {
      setComponentDisabled(false);
    }
  }, [requesting]);

  return (
    <>
      <Helmet>
        <title>로그인</title>
      </Helmet>
      <StyledOfLogin>
        <div className="wrap">
          <div className="inner">
            <header className="header">
              <h1>
                <Logo />
                <span className="text">작업자 페이지</span>
              </h1>
            </header>

            <Form
              name="login"
              onFinish={handleSubmit}
              onValuesChange={handleValuesChange}
              disabled={componentDisabled}
              className="align-left"
              autoComplete="off"
            >
              <FormItem name={FormNames.USER_NAME}>
                <InputText size="large" placeholder="아이디를 입력하세요" />
              </FormItem>
              <FormItem name={FormNames.PASSWORD}>
                <InputPassword size="large" placeholder="비밀번호를 입력하세요" />
              </FormItem>
              <FormItem
                name={FormNames.REMEMBER}
                valuePropName="checked"
                className={`${formValues.remember ? 'mb-0' : 'mb-3'}`}
              >
                <Checkbox>로그인 상태 유지</Checkbox>
              </FormItem>
              {formValues.remember && (
                <StyledOfUnorderedList color={'red'} className="mb-3">
                  <li>개인 정보 보호를 위해 공용 PC에서는 사용에 유의해 주시기 바랍니다.</li>
                  <li>
                    일부 브라우저에서 제공하는 개인 정보보호 브라우징 (InPrivate 브라우징) 상태로
                    인터넷 이용 시 쿠키가 저장되지 않아 로그인 상태 유지 기능을 사용할 수 없습니다.
                  </li>
                </StyledOfUnorderedList>
              )}
              <FormItem>
                <Button
                  htmlType="submit"
                  type="primary"
                  width="fill"
                  size="large"
                  disabled={!(formValues.username && formValues.password)}
                >
                  로그인
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </StyledOfLogin>
    </>
  );
};
export default Login;

const StyledOfLogin = styled.main`
  display: table;
  width: 100%;
  height: 100%;

  > .wrap {
    display: table-cell;
    overflow: auto;
    text-align: center;
    vertical-align: middle;

    > .inner {
      display: inline-block;
      max-width: 300px;
      margin: 30px 10px 20px;
    }
  }

  .header {
    margin-bottom: 30px;

    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }

    .logo {
      margin-right: 5px;
    }

    .text {
      display: inline-block;
      font-size: 22px;
      font-weight: bold;
    }

    @media (max-width: 300px) {
      h1 {
        flex-direction: column;
      }

      .logo {
        margin-bottom: 10px;
      }
    }
  }

  .form-item {
    width: 300px;
  }
`;
