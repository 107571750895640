import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import type { Route as TRoute } from 'src/models';
import { config } from 'src/config';
import { RootState } from 'src/app/store';
import lazyRetry from 'src/layout/lazyRetry';
import Button from 'src/components/Button';
import { logoutRequest } from 'src/features/login/authSlice';
import PageNotFound from 'src/features/PageNotFound';
import Logo from 'src/components/logo';
import styleSetting from 'src/assets/variables';
import { breadcrumbNameMap, gnvItems, TBreadcrumbInfo } from 'src/layout/GlobalNavigation';
import { UserOutlined, HomeOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout as AntdLayout, Menu, Spin } from 'antd';
import { Helmet } from 'react-helmet-async';

const { Content, Sider } = AntdLayout;

const AgentTaskAssignmentList = React.lazy(() =>
  lazyRetry(() => import('src/features/agent/tasks/AgentTaskAssignmentList')),
);

const Layout = (props: TRoute) => {
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = props;

  const { name } = useSelector((rootState: RootState) => rootState.auth.agent);

  const [pageTitle, setPageTitle] = useState('');
  // gnv 현재 선택/열린 메뉴
  const [openKeys, setOpenKeys] = useState<string[]>(['1']);

  /*** 로그아웃 실행 ***/
  const handleLogout = useCallback(() => {
    dispatch(logoutRequest());
  }, [dispatch]);

  /***Breadcrumb ***/
  const pathSnippets = useMemo(() => {
    return pathname.split('/').filter((i) => i);
  }, [pathname]);

  const extraBreadcrumbItems = useMemo(() => {
    return pathSnippets
      .map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        const breadcrumbInfo = breadcrumbNameMap[url] as TBreadcrumbInfo;
        if (!breadcrumbInfo) {
          return null;
        }
        const { title, key } = breadcrumbInfo;
        // gnv 현재 선택/열린 메뉴
        setOpenKeys(key);

        const last = pathSnippets.length === index + 1;
        if (last) {
          setPageTitle(title);
        }

        return (
          <Breadcrumb.Item key={url}>
            {!last && <Link to={url}>{title}</Link>}
            {last && <>{title}</>}
          </Breadcrumb.Item>
        );
      })
      .filter((item) => item !== null);
  }, [pathSnippets]);

  const breadcrumbItems = useMemo(() => {
    return [
      <Breadcrumb.Item key="home">
        <Link to="/">
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems as JSX.Element[]);
  }, [extraBreadcrumbItems]);

  /*** gnv - submenu ***/
  const handleOpenChangeGnv: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    const rootSubmenuKeys = ['sub2'];

    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AntdLayout style={{ minHeight: '100vh' }}>
        <StyledOfSiderLayout>
          <header className="header">
            <h1>
              <Logo size="small" className={'mr-1'} />
              <span className="text">작업자 페이지</span>
            </h1>
            <dl>
              <dt className="text-hidden">이름</dt>
              <dd>
                <div className="icon-user mr-2">
                  <UserOutlined />
                </div>
                <strong>{name}</strong> 님
              </dd>
            </dl>
            <div className="wrap-buttons">
              <Button size="small" onClick={handleLogout} className="btn-logout">
                로그아웃
              </Button>
            </div>
          </header>
          <Menu
            theme="dark"
            defaultSelectedKeys={openKeys}
            openKeys={openKeys}
            selectedKeys={openKeys}
            mode="inline"
            items={gnvItems}
            onOpenChange={handleOpenChangeGnv}
          />
        </StyledOfSiderLayout>

        <AntdLayout className="site-layout">
          <StyledOfBreadcrumbLayout>
            <h1>{pageTitle}</h1>
            <Breadcrumb>{breadcrumbItems}</Breadcrumb>
          </StyledOfBreadcrumbLayout>

          <StyledOfContentLayout>
            <StyledOfContent>
              <Suspense
                fallback={
                  <StyledOfFallback>
                    <Spin tip="Loading" />
                  </StyledOfFallback>
                }
              >
                <Switch>
                  <Route
                    exact={true}
                    path={config.page.link.assignmentList}
                    component={AgentTaskAssignmentList}
                  />
                  <Route path="/" exact={true} component={AgentTaskAssignmentList} />
                  <Route path="/" component={PageNotFound} />
                </Switch>
              </Suspense>
            </StyledOfContent>
          </StyledOfContentLayout>
        </AntdLayout>
      </AntdLayout>
    </>
  );
};
export default Layout;

const StyledOfFallback = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledOfContentLayout = styled(Content)`
  position: relative;
  padding: 25px 15px 100px !important;
  background-color: #fff;
`;

const StyledOfContent = styled.div`
  margin: 0 !important;
  min-height: 360px !important;
`;

const StyledOfBreadcrumbLayout = styled.div`
  height: 80px;
  margin: 0 !important;
  padding: 17px 20px 10px;
  border-bottom: 1px solid #d3d3d3;

  h1 {
    margin-bottom: 5px;
    font-size: 20px;
  }
`;

const StyledOfSiderLayout = styled(Sider)`
  .header {
    margin-bottom: 20px;
    padding: 0 0 15px;
    box-shadow: 0px -1px 1px 1px #b9c5ce;

    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      margin-bottom: 0;
      background-color: #fff;

      .text {
        font-size: 12px;
        letter-spacing: -1px;
        font-style: italic;
      }
    }

    dl {
      margin: 20px 0 0;
      padding: 0 15px 0 24px;

      dd {
        font-size: 14px;
        color: #fff;
        word-break: break-all;
      }
    }

    .icon-user {
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      line-height: 25px;
      background-color: ${styleSetting.color.pointColor};
      text-align: center;
      vertical-align: middle;

      svg {
        fill: #fff;
      }
    }

    .wrap-buttons {
      margin: 15px 5px 0;
      text-align: center;

      button {
        width: 100%;
        max-width: 150px;
      }
    }
  }

  &.collapsed {
    .header {
      padding: 0;

      h1 {
        .text {
          display: none;
        }
      }

      dl {
        display: none;
      }
    }
  }

  ul.ant-menu.ant-menu-root {
    border-inline-end: none;
  }
`;
