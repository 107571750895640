import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { timeStamp } from '../../utils/format';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { ScriptParagraph } from './types';
import { CustomCheckbox } from '../../components/CustomMui';
import { removeSpacesBeginningParagraphs } from './utility';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ModalContentCopy = ({ onClose, isOpen }: Props) => {
  const [value, setValue] = React.useState('');
  const editorScript = useSelector(
    (rootState: RootState) => rootState.workspace.editor.script,
  ) as ScriptParagraph[];
  const script = useMemo(() => {
    return removeSpacesBeginningParagraphs(editorScript) as ScriptParagraph[];
  }, [editorScript]);

  // 체크 박스
  const [option, setOption] = useState({
    showSpeaker: false,
    showTimestamp: false,
    showLinebreak: false,
  });

  const initValue = useCallback(() => {
    let speaker = '화자 미지정';
    // 화자 문단(화자 이름이 들어가는 문단)
    let isSpeakerBlock = false;
    // 화자 문단이면서, 블럭의 내용이 비어 있는 경우를 확인하기 위함(내용이 비어 있지 않은 블럭에서 화자, 타임스탬프 출력하게 됨.)
    let isEmptySpeakerBlock = false;

    const scriptResult = script
      .map((paragraph, index) => {
        const meta = [];

        // 블럭의 내용이 비어있는지 확인
        const isEmptyBlock =
          paragraph.children.length === 1 && paragraph.children[0].text.length === 0;
        // 화자를 갖는 문단 - paragraph.data.type 은 undefined || "lineBreak"
        isSpeakerBlock = !paragraph.data.type;

        // 화자 문단이면서, 블럭의 내용이 비어 있는 경우를 확인하기 위함(내용이 비어 있지 않은 블럭에서 화자, 타임스탬프 출력하게 됨.)
        isEmptySpeakerBlock = isEmptyBlock && isSpeakerBlock;

        /* 빈문단 에 대한 처리 */
        if (isEmptyBlock) {
          // 화자를 갖는 문단
          if (isSpeakerBlock) {
            // "줄 내림 표시" 활성화 상태에서만 빈문단인 경우에 노출하지 않음
            if (option.showLinebreak) {
              return '';
            }
            // 줄내림 문단(위 문단과 화자가 같음)
          } else {
            return '';
          }
        }

        /* 화자이름 */
        if (isSpeakerBlock) {
          // paragraph.data.speaker 은 undefined || '화자없음' || 화자명
          const speakerName = paragraph.data.speaker;
          if (speakerName && speakerName !== '화자없음') {
            speaker = speakerName;
          }
        }

        /* 문단 구분 - 줄바꿈 */
        if (index > 0) {
          if (isSpeakerBlock) {
            meta.push('\n\n');
          } else {
            // 줄 내림 상태가 비활성화 상태라면 줄 내림 추가.
            if (option.showLinebreak) {
              meta.push(`\n\n`);
            }
          }
        }

        let timeStampValue: undefined | string;
        let speakerValue: undefined | string;

        /* 시간 출력 (조건: 블럭의 내용이 비어있지 않음) */
        if (option.showTimestamp) {
          const stringTimeStamp = timeStamp(paragraph.data.start, 'HH:mm:ss');

          // 화자를 갖는 문단 || "줄 내림 표시" 활성화
          if (isSpeakerBlock || option.showLinebreak) {
            timeStampValue = stringTimeStamp;
          }
        }

        /* 화자 출력 (조건: 블럭의 내용이 비어있지 않음) */
        if (option.showSpeaker) {
          // 화자를 갖는 문단 || "줄 내림 표시" 활성화
          if (isSpeakerBlock || option.showLinebreak) {
            speakerValue = `[${speaker}]`;
          }
        }

        /* 옵션값에 따른 출력 */
        if (timeStampValue && speakerValue) {
          meta.push(`${timeStampValue} ${speakerValue}\t`);
        } else if (timeStampValue) {
          meta.push(`${timeStampValue}\t`);
        } else if (speakerValue) {
          meta.push(`${speakerValue}\t`);
        }

        /* 글 내용 */
        meta.push(paragraph.children.map((word) => word.text).join(''));

        return meta.join('');
      }).filter(paragraph => paragraph.length > 0);

    // 비어있는 값을 제거한 결과값에서 첫번째 문단의 시작문자가 줄내림 문자로 시작한다면, 줄내림 문자를 제거한다.
    if (scriptResult[0].indexOf("\n\n") === 0) {
      scriptResult[0] = scriptResult[0].replace(/\n\n/, "");
    }

    setValue(scriptResult.join(""));
  }, [script, option]);

  const handleOptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOption({ ...option, [event.target.name]: event.target.checked });
    },
    [option],
  );

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  const handleCopy = useCallback(() => {
    // @ts-ignore
    toastr.info('알림', '클립보드에 복사되었습니다.');
  }, []);

  useEffect(() => {
    if (isOpen) {
      initValue();
    }
  }, [isOpen, initValue]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      className="wrap-dialog dialog-content-copy"
    >
      <DialogTitle
        id="confirmation-dialog-title"
        className="wrap-dialog-title"
        disableTypography={true}
      >
        <h1 className="dialog-title">
          녹취록 복사<span className="text-hidden">에디터 내용 복사</span>
        </h1>
        <div className="wrap-print-options">
          <FormControlLabel
            className="checkbox"
            control={
              <CustomCheckbox
                checked={option.showTimestamp}
                onChange={handleOptionChange}
                name="showTimestamp"
              />
            }
            label="시간 표시"
          />
          <FormControlLabel
            className="checkbox"
            control={
              <CustomCheckbox
                checked={option.showSpeaker}
                onChange={handleOptionChange}
                name="showSpeaker"
              />
            }
            label="화자 표시"
          />
          <FormControlLabel
            className="checkbox"
            control={
              <CustomCheckbox
                checked={option.showLinebreak}
                onChange={handleOptionChange}
                name="showLinebreak"
              />
            }
            label="줄 내림 표시"
          />
        </div>
      </DialogTitle>

      <DialogContent dividers={true} className="dialog-content">
        <textarea onChange={handleChange} value={value} />
      </DialogContent>
      <DialogActions className="dialog-actions">
        <button
          type="button"
          autoFocus={true}
          onClick={onClose}
          className="button-cancel button-color4"
        >
          취소
        </button>

        <CopyToClipboard
          text={value}
          onCopy={handleCopy}
          options={{
            format: 'text/plain',
          }}
        >
          <button type="button" className="button-copy-finish button-color4">
            복사
          </button>
        </CopyToClipboard>
      </DialogActions>
    </Dialog>
  );
};

export default ModalContentCopy;
