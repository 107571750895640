import { Product, ISO8601Timestamp } from './index';
import { ScriptFormat, ScriptParagraph } from '../features/workspace/types';

export interface TaskAgent {
  id: number;
  name: string;
}

export interface TaskAssignment {
  id: number;
  role: string;
  canceledAt: string;
  dueAt: string;
  completedAt: string;
  createdAt: string;
  agent: TaskAgent;
}

export enum TTaskEncodingStatus {
  READY = '준비중',
  PROCEEDING = '진행중',
  COMPLETED = '완료',
  ERROR = '에러',
}

export enum TaskSttStatus {
  READY = '준비중',
  UPLOADING = '업로드중',
  PROCEEDING = '진행중',
  COMPLETED = '완료',
  ERROR = '에러',
}

export interface Order {
  id: number; // 기본키
  uid: string; // uuid, int 타입의 id는 유추가 가능하여 api 호출시에는 uuid를 사용함.
  userId: number; // 주문한 고객의 id
  userName: string; // 고객명
  userEmail: string; // 고객 이메일
  userMobile: string; // 고객 핸드폰
  userPhone?: string; // 고객 연락처
  userAddress?: string; // 고객 주소
  userAddressDetail?: string; // 고객 상세주소
  productType: Product; // 상품 유형
  memo: string | null; // typex 매니저가 남기는 메모
  requirementDetails: OrderRequirementDetails; // 주문상세 내용을 json 형식으로 저장, description, phrases, verbatim, cd_included, response_formats, speakers
  dueAt: ISO8601Timestamp | null;
  createdAt: ISO8601Timestamp;
  updatedAt: ISO8601Timestamp;
  attachments: TypeReferenceFile[];
  priceEstimated: null; // 초기 견적 가격. 시스템에 의해 결정된 가격, 산출불가시 0
  priceFixed: number | null; // 실제 가격, 고객이 지불해야 할 금액, 운영자가 기입
  isCompleted: number | boolean; // 완료 여부
  completedAt: ISO8601Timestamp; // 완료일
  deletedAt: ISO8601Timestamp | null; // 삭제일, 삭제처리시 삭제일이 기록된다.
  mailSent: boolean; // 등기 발송 현황.
  speakerCount: number;
}

export interface File {
  id: string;
  uid: string;
  name: string;
  key: string;
  path: string;
  size: number;
  duration: number;
  durationText: string;
  downloadLink: string; // 플레이 파일 - 유효시간 3시간
  type: string;
  encodingStatus: TTaskEncodingStatus; // 인코딩 진행상황, original 파일은 항상 완료
  memo?: string;
  language: string;
  details: {
    mediaInfo: {
      track: any[];
    };
    clippings: [{ start: number; end: number }];
  };
  expiredAt?: ISO8601Timestamp;
  recordedAt?: ISO8601Timestamp;
  createdAt: ISO8601Timestamp | Date;
  updatedAt: ISO8601Timestamp;
  order: Order;
  source: File;
}


export interface Task {
  id: number;
  uid: string; // uuid, int 타입의 id는 유추가 가능하여 api 호출시에는 uuid를 사용함.
  durationClippedText: string; // 작업건의 실작업시간(녹취시간)의 테스트(hh:mm:ss)버전
  hasClippings: boolean; // 작업건이 부분녹취구간을 가졌는지의 여부
  durationClipped: number; // 녹취시간(초), 전체녹취일 경우 duration과 같은 값을 가진다. duration을 알 수 없을 경우 0값을 가진다.
  durationClippedRound?: number; // durationClipped 반올림
  sttStatus: TaskSttStatus;
  originalFile: File;
  playFile: File; // 이 작업건의 웹재생용 파일
  taskAssignments: TaskAssignment[]; // 작업건 할당 내역
  editorLink: string;
  createdAt: ISO8601Timestamp;
  password: string;
  incentive: number; // 인센티브, 백분율로 입력된다.
  qcScore: number; // QC평점(1~5)
  qcComment: string;
  editedAt: ISO8601Timestamp; // 최근 작업 일시, script나 speaker 정보가 변경된 일시만 기록
  productType: Product;
  contractedAt: ISO8601Timestamp;
  expiredAt: ISO8601Timestamp;
  speakerCount: number;
  script: {
    value: ScriptParagraph[];
  };
  scriptFormat: ScriptFormat;
  memo?: string;
  details: {
    speakers: SpeakerInfo[]; // 등록한 화자 정보
    clippings: [{ start: number; end: number }];
  };
  status: TaskStatus; // 작업 진행 상황
  updatedAt: ISO8601Timestamp;
}

export interface TSpeaker {
  name: string;
  feature: string;
}

export enum TaskStatus {
  NOT_ASSIGNED = '미할당',
  PROCEEDING_TYPE = '타이핑중',
  COMPLETED_TYPE = '타이핑 완료',
  PROCEEDING_QC = '타이핑 검수중',
  COMPLETED_QC = '타이핑 검수 완료',
  PROCEEDING_SYNC = '자막제작중',
  COMPLETED_SYNC = '자막제작 완료',
  PROCEEDING_QC_SYNC = '자막검수중',
  COMPLETED_QC_SYNC = '자막검수 완료',
  COMPLETED = '작업 완료',
}

export interface SpeakerInfo {
  name: string;
  feature: string;
}

export interface User {
  id: number; // 기본 키
  name: string; // 고객 이름
  email: string; // 고객 이메일
  mobile: string; // 고객 휴대폰 번호
  phone?: string; // 고객 연락처
  memo?: string; // 고객특이사항, typex 매니저가 남기는 메모
  createdAt: ISO8601Timestamp;
  updateAt: ISO8601Timestamp;
}

export type TypeReferenceFile = File & {
  isPublic: boolean;
};

export class OrderRequirementDetails {
  description?: string;
  phrases?: string;
  verbatim?: boolean;
  cdIncluded?: boolean;
  speakers?: SpeakerInfo[];
  responseFormats?: string[];
  totalDuration?: number; // -1일 경우 측정불가, 원본파일들의 실제 재생시간의 합
  estimatedCost?: number; // -1일 경우 측정불가
}
