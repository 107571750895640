import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  addChanges,
  changeSpellChecked,
  closeSpellCheck,
  startCommitting,
} from './spellCheckSlice';
import { SpellCheckErrorType, SpellCheckResultItem, SpellCheckState } from './types';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';

import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import Button from '../../components/Button';
import { isEqual } from 'lodash';
import ModalAlert from './ModalAlert';
import { Input } from 'antd';
import { RootState } from '../../app/store';
import LoadingProgress from '../../components/LoadingProgress';

const ModalSpellCheck = () => {
  const dispatch = useDispatch();
  const { sentences, candidates, changes, isFetching } = useSelector(
    (rootState: RootState) => rootState.workspace.spellCheck,
  );

  const [isOpenModalInputWord, setIsOpenModalInputWord] = useState(false); // '직접 입력' 모달창 노출
  const [wordToChange, setWordToChange] = useState<{} | SpellCheckResultItem>({}); // '직접 입력' 하여 변경할 단어의 정보
  const [inputWord, setInputWord] = useState(''); // '직접 입력' 모달창에서 입력된 단어
  const [isErrorInputWord, setIsErrorInputWord] = useState(false); // '직접 입력' 모달창에서 입력된 단어에 오류가 있음

  const [isOpenAlertChangeAll, setIsOpenAlertChangeAll] = useState(false); // '모두 변경' 모달창 노출
  const [isOpenAlertFinish, setIsOpenAlertFinish] = useState(false); // '맞춤법 완료 확인' 모달창 노출

  const contentRef = useRef<HTMLDivElement>(null);
  const asideRef = useRef<HTMLDivElement>(null);

  const candidatesLength = useMemo(() => {
    return Object.keys(candidates).length;
  }, [candidates]);

  const changesLength = useMemo(() => {
    return Object.keys(changes).length;
  }, [changes]);

  /*
  * 클릭한 단어가 있는 위치로 스크롤 이동
  * - 목록에서 클릭 내용에서 스크롤 이동
  * - 내용에서 클릭 목록에서 스크롤 이동
  찾아가야 하는 아이디가
  * - word_*_*: 본문(내용) = contentRef
  * - input_*_*: 맞춤법 오류 목록 = asideRef
  * */
  const handleMoveScroll = useCallback(
    (event) => {
      let topPaddingOfBox = 0;
      const currentElement = event.target;
      const { targetId } = currentElement.dataset;

      if (!targetId) {
        return;
      }

      const targetWord = document.getElementById(targetId);
      if (!targetWord) {
        return;
      }

      const offsetParent = targetWord.offsetParent;
      if (!offsetParent) {
        return;
      }

      // 스크롤 박스의 상단 여백
      const firstElementChild = offsetParent.firstElementChild;
      if (firstElementChild) {
        topPaddingOfBox = (firstElementChild as HTMLElement).offsetTop;
      }

      // 이동 엘리먼트의 부모 엘리먼트의 상단 여백 (맞춤법 오류 목록에서 li의 상단 여백)
      let parentElement = targetWord.parentElement;
      if (parentElement) {
        const firstOffset = parentElement.offsetTop;
        if (parentElement.tagName === 'SPAN') {
          parentElement = parentElement.parentElement;
          if (parentElement) {
            topPaddingOfBox += firstOffset - parentElement.offsetTop;
          }
        }
      }

      if (contentRef.current && targetId.indexOf('word_') > -1) {
        // 본문(내용) 스크롤 이동
        contentRef.current.scrollTo(0, targetWord.offsetTop - topPaddingOfBox);
      } else if (asideRef.current && targetId.indexOf('input_') > -1) {
        // 맞춤법 오류 목록의 스크롤 이동
        asideRef.current.scrollTo(0, targetWord.offsetTop - topPaddingOfBox);
      }
    },
    [contentRef, asideRef],
  );

  // 마우스 오버시 - 같은 오류 단어에 밑줄
  const handleMouseEnter = useCallback((event) => {
    const currentElement = event.target;
    const { targetId } = currentElement.dataset;

    if (targetId) {
      const targetWord = document.getElementById(targetId);
      if (targetWord) {
        targetWord.classList.add('hover');
      }
    }
  }, []);

  // 마우스 오버시 해제
  const handleMouseLeave = useCallback((event) => {
    const currentTarget = event.currentTarget;
    const { targetId } = currentTarget.dataset;
    if (targetId) {
      const targetWord = document.getElementById(targetId);
      if (targetWord) {
        targetWord.classList.remove('hover');
      }
    }
  }, []);

  // 맞춤법 검사 팝업창 - 닫기
  const handleCloseSpellCheck = useCallback(() => {
    dispatch(closeSpellCheck());
  }, [dispatch]);

  // 맞춤법 검사 팝업창 - 맞춤법 검사 변경 내용 없음 완료 확인 - 맞춤법 검사 완료 상태로 변경
  const handleConfirmSpellCheck = useCallback(() => {
    dispatch(closeSpellCheck());
    // 맞춤법 검사 진행 했음을 증명
    dispatch(changeSpellChecked(true));
  }, []);

  // 맞춤법 검사 팝업창 - 맞춤법 반영
  const handleCommit = useCallback(() => {
    dispatch(startCommitting());
  }, []);

  // [팝업] 모두 변경 - 팝업 노출
  const handleChangeAll = useCallback((event) => {
    setIsOpenAlertChangeAll(true);
  }, []);

  // [팝업] 모두 변경 - 변경 버튼 클릭
  const handleConfirmAlertChangeAll = useCallback(() => {
    // '오류 의심'은 제외하고 변경
    const values = Object.values(candidates).filter(
      ({ etype }) => etype !== SpellCheckErrorType.DOUBT,
    );
    values.forEach((candidate) => {
      dispatch(addChanges(candidate));
    });
    setIsOpenAlertChangeAll(false);
  }, [dispatch, candidates]);

  // [팝업] 모두 변경 - 취소 버튼 클릭
  const handleCloseAlertChangeAll = useCallback(() => {
    setIsOpenAlertChangeAll(false);
  }, []);

  const handleChangeWord = useCallback(
    (candidate: SpellCheckResultItem) =>
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (candidate.etype === SpellCheckErrorType.DOUBT) {
          return;
        }
        dispatch(addChanges(candidate));
      },
    [dispatch],
  );

  // [팝업] 직접 수정 - 팝업 노출.
  const handleInputWord = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { currentTarget } = event;
      const { offset: offsetStr } = currentTarget.dataset;
      if (offsetStr) {
        const key = offsetStr.split(',').join('.');
        setIsOpenModalInputWord(true);
        setWordToChange(candidates[key]);
      }
    },
    [candidates],
  );

  // [팝업] 직접 수정 - 적용 버튼 클릭
  const handleConfirmModalInputWord = useCallback(() => {
    const value = inputWord?.trim() || '';
    // 입력 텍스트 없는 경우. 빈 문자열
    if (value.length === 0) {
      setIsErrorInputWord(true);
      return;
    } else {
      dispatch(addChanges({ ...wordToChange, output: value } as SpellCheckResultItem));
      // 초기화
      setIsOpenModalInputWord(false);
      setWordToChange({});
      setInputWord('');
    }
  }, [dispatch, inputWord, wordToChange]);

  // [팝업] 직접 수정 - 취소 버튼 클릭
  const handleCloseModalInputWord = useCallback(() => {
    // 초기화
    setIsOpenModalInputWord(false);
    setWordToChange({});
    setIsErrorInputWord(false);
    setInputWord('');
  }, []);

  // [팝업] 직접 수정 - 텍스트 입력
  const handleChangeInputWord = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      setInputWord(value);
      if (isErrorInputWord) {
        setIsErrorInputWord(false);
      }
    },
    [isErrorInputWord],
  );

  // [팝업] 맞춤법 완료 확인 - 팝업 노출. ('확인' 버튼 클릭 - 교정 내용 없이 맞춤법 검사 완료하고자 할때)
  const handleModalFinish = useCallback(() => {
    setIsOpenAlertFinish(true);
  }, []);

  // [팝업] 맞춤법 완료 확인 - 맞춤법 검사 완료 상태로 변경
  const handleConfirmAlertFinish = useCallback(() => {
    setIsOpenAlertFinish(false);
    dispatch(closeSpellCheck());
    // 맞춤법 검사 진행 했음을 증명
    dispatch(changeSpellChecked(true));
  }, []);

  // [팝업] 맞춤법 완료 확인 - 모달창 닫힘
  const handleCloseAlertFinish = useCallback(() => {
    setIsOpenAlertFinish(false);
  }, []);

  return (
    <>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth="md"
        className={classNames('wrap-dialog dialog-column dialog-spell-check', {
          'isFetching': isFetching,
        })}
      >
        <DialogTitle className="wrap-dialog-title" disableTypography={true}>
          <h1 className="text-hidden">맞춤법 검사</h1>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <section className="sec-content">
            <header className="sec-header">
              <h2 className="sec-title">맞춤법 검사</h2>
            </header>
            <div className="wrap-content" ref={contentRef}>
              {isFetching && (
                <div className="wrap-loading">
                  <LoadingProgress />
                </div>
              )}
              {!isFetching &&
                sentences.map((item, sentenceIndex) => {
                  return (
                    <RenderSentence
                      key={`sentence-${sentenceIndex}`}
                      sentence={item}
                      changes={changes}
                      onMoveScroll={handleMoveScroll}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  );
                })}
            </div>
          </section>

          <section className="sec-aside">
            <header className="sec-header">
              <h2 className="sec-title">
                맞춤법 오류 <span className="text-point">{!isFetching && candidatesLength}</span>개
              </h2>
              {candidatesLength > 1 && (
                <button
                  type="button"
                  className="btn"
                  disabled={isFetching}
                  onClick={handleChangeAll}
                >
                  모두 변경
                </button>
              )}
            </header>
            <div className="wrap-content" ref={asideRef}>
              {isFetching && (
                <div className="wrap-loading">
                  <LoadingProgress />
                </div>
              )}
              {!isFetching && (
                <>
                  {candidatesLength === 0 ? (
                    <p className="text-no-data">맞춤법 오류가 없습니다.</p>
                  ) : (
                    <ul className="list-error-word">
                      {Object.entries(candidates).map(([key, candidate]) => {
                        const { offset } = candidate;
                        if (changes[offset!.join('.')]) {
                          return undefined;
                        }
                        const id = `input_${offset![0]}_${offset![1]}`;
                        return (
                          <RenderErrorWord
                            id={id}
                            key={id}
                            word={candidate}
                            data-offset={offset}
                            onMoveScroll={handleMoveScroll}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onChangeWord={handleChangeWord(candidate)}
                            onInputWord={handleInputWord}
                          />
                        );
                      })}
                    </ul>
                  )}
                </>
              )}
            </div>
          </section>
        </DialogContent>

        <DialogActions className="dialog-actions">
          {(isFetching || candidatesLength > 0 || changesLength > 0) && (
            <Button type="primary" ghost={true} onClick={handleCloseSpellCheck}>
              취소
            </Button>
          )}
          {!isFetching && (
            <>
              {candidatesLength === 0 && changesLength === 0 ? (
                <Button type="primary" disabled={isFetching} onClick={handleConfirmSpellCheck}>
                  확인
                </Button>
              ) : (
                <>
                  {changesLength === 0 ? (
                    <Button type="primary" disabled={isFetching} onClick={handleModalFinish}>
                      확인
                    </Button>
                  ) : (
                    <Button type="primary" disabled={isFetching} onClick={handleCommit}>
                      반영
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>

      {isOpenAlertChangeAll && (
        <ModalAlert
          maxWidth="sm"
          title="모두 변경하시겠습니까?"
          content={
            <>
              <StyledModalAlertText>‘오류 의심’ 어절 은 제외하고 변경됩니다.</StyledModalAlertText>
            </>
          }
          textConfirm="변경"
          isOpen={true}
          onClose={handleCloseAlertChangeAll}
          onConfirm={handleConfirmAlertChangeAll}
        />
      )}

      {
        // 팝업 - 직접 수정
        isOpenModalInputWord && (
          <ModalAlert
            maxWidth="sm"
            title="직접 수정"
            content={
              <StyledInputWord>
                <Input
                  value={inputWord}
                  placeholder="대치어 입력"
                  onChange={handleChangeInputWord}
                  className={classNames('', { 'is-error': isErrorInputWord })}
                  autoFocus={true}
                />
                {isErrorInputWord && <p className="text-feedback">대치어를 입력해 주세요.</p>}
              </StyledInputWord>
            }
            textConfirm="적용"
            isOpen={true}
            onClose={handleCloseModalInputWord}
            onConfirm={handleConfirmModalInputWord}
          />
        )
      }

      {
        // 팝업 - 맞춤법 완료 확인
        isOpenAlertFinish && (
          <ModalAlert
            maxWidth="sm"
            title="맞춤법 검사를 완료하시겠습니까?"
            content={
              <>
                <StyledModalAlertText>
                  교정할 텍스트가 있으면 교정 후 완료해주세요.
                </StyledModalAlertText>
              </>
            }
            textConfirm="완료"
            isOpen={true}
            onClose={handleCloseAlertFinish}
            onConfirm={handleConfirmAlertFinish}
          />
        )
      }
    </>
  );
};
export default ModalSpellCheck;

// 출력되는 문단(문장)
const RenderSentence = React.memo(
  ({
    sentence,
    changes,
    onMoveScroll,
    onMouseEnter,
    onMouseLeave,
  }: {
    sentence: SpellCheckResultItem[];
    changes: SpellCheckState['changes'];
    onMoveScroll: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseEnter: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseLeave: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }) => {
    const handleMoveScroll = useCallback(
      (event) => {
        // 교정 적용된 단어를 제외한 단어에서만 실행
        const { currentTarget } = event;
        if (currentTarget.className.indexOf('text-error-changed') === -1) {
          onMoveScroll(event);
        }
      },
      [onMoveScroll],
    );
    const handleMouseEnter = useCallback(
      (event) => {
        // 교정 적용된 단어를 제외한 단어에서만 실행
        const { currentTarget } = event;
        if (currentTarget.className.indexOf('text-error-changed') === -1) {
          onMouseEnter(event);
        }
      },
      [onMouseEnter],
    );
    const handleMouseLeave = useCallback(
      (event) => {
        // 교정 적용된 단어를 제외한 단어에서만 실행
        const { currentTarget } = event;
        if (currentTarget.className.indexOf('text-error-changed') === -1) {
          onMouseLeave(event);
        }
      },
      [onMouseLeave],
    );

    return (
      <p>
        {sentence.length === 0 && <br />}
        {sentence.map((word) => {
          const { input, offset, etype } = word;

          if (etype === SpellCheckErrorType.NO_ERROR) {
            return input;
          } else if (offset) {
            const idWord = `word_${offset[0]}_${offset[1]}`;
            const targetId = `input_${offset[0]}_${offset[1]}`;
            let color = getErrorWordColor(etype);

            const change = changes[offset.join('.')];
            if (change) {
              color = 'changed';
            }

            const text = change ? change.wordReplace : input;

            return (
              <button
                key={idWord}
                type="button"
                id={idWord}
                data-target-id={targetId}
                className={classNames('bg-transparent text-wrong-word', {
                  [`text-error-${color}`]: color.length > 0,
                })}
                data-offset={offset}
                data-input={input}
                onClick={handleMoveScroll}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {text}
              </button>
            );
          }
        })}
      </p>
    );
  },
  (prev, next) => isEqual(prev, next),
);

// 출력되는 오류 단어 목록
const RenderErrorWord = React.memo(
  ({
    id,
    word,
    onMoveScroll,
    onMouseEnter,
    onMouseLeave,
    onChangeWord,
    onInputWord,
  }: {
    id: string;
    word: SpellCheckResultItem;
    onMoveScroll: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseEnter: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onMouseLeave: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onChangeWord: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onInputWord: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }) => {
    const { input, output, offset, etype, help } = word;
    const targetId = `word_${offset && offset[0]}_${offset && offset[1]}`;
    const color = getErrorWordColor(etype);

    const handleMoveScroll = useCallback(
      (event) => {
        onMoveScroll(event);
      },
      [onMoveScroll],
    );

    const handleMouseEnter = useCallback(
      (event) => {
        onMouseEnter(event);
      },
      [onMouseEnter],
    );

    const handleMouseLeave = useCallback(
      (event) => {
        onMouseLeave(event);
      },
      [onMouseLeave],
    );

    const handleChangeWord = useCallback(
      (event) => {
        onChangeWord(event);
      },
      [onChangeWord],
    );

    const handleInputWord = useCallback(
      (event) => {
        onInputWord(event);
      },
      [onInputWord],
    );

    return (
      <li key={id}>
        <span className="text">
          <button
            type="button"
            id={id}
            data-target-id={targetId}
            className={`text-wrong-word text-error-${color}`}
            onClick={handleMoveScroll}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            title={(help || []).join(' ')}
          >
            {input}
          </button>
        </span>
        <span className="icon-arrow">
          <ArrowRightOutlined />
        </span>
        <span className="text">
          <button
            type="button"
            className={classNames('text-output', {
              'text-error-doubt': etype === SpellCheckErrorType.DOUBT,
            })}
            data-offset={offset}
            data-output={output}
            data-etype={etype}
            onClick={handleChangeWord}
          >
            {etype === SpellCheckErrorType.DOUBT ? '(오류 의심)' : output}
          </button>
        </span>
        <span>
          <Button
            icon={<EditOutlined />}
            className="btn-change-output"
            data-offset={offset}
            onClick={handleInputWord}
          />
        </span>
      </li>
    );
  },
  (prev, next) => isEqual(prev, next),
);

// 오류 타입에 따른 텍스트 색상
const getErrorWordColor = (etype: string): string => {
  const colorType = {
    [SpellCheckErrorType.SPELL as string]: SpellCheckErrorType.SPELL,
    [SpellCheckErrorType.SPACE_SPELL as string]: SpellCheckErrorType.SPELL,
    [SpellCheckErrorType.SPACE as string]: SpellCheckErrorType.SPACE,
    [SpellCheckErrorType.DOUBT as string]: SpellCheckErrorType.DOUBT,
  };
  return colorType[etype] || '';
};

const DialogTitle = withStyles(() => ({
  root: {
    padding: '18px 0 0',
  },
}))(MuiDialogTitle);

const DialogContent = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowY: 'hidden',
    paddingTop: '0',
    paddingBottom: '0',
  },
}))(MuiDialogContent);

const StyledInputWord = styled.div`
  input[type='text'] {
    width: 300px;

    &.is-error {
      border-color: #f5222d;
    }
  }
`;

const StyledModalAlertText = styled.p`
  color: #8c8c8c !important;
`;
