import React from 'react';
import { NavLink } from 'react-router-dom';
import { config } from '../config';
import { DatabaseOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';

/*** Breadcrumb & GNV ***/
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const assignmentListLink = config.page.link.assignmentList;
const assignmentListTitle = config.page.title.assignmentList;
const assignmentListKeys = config.page.gnvKeys.assignmentList;

export const gnvItems: MenuItem[] = [
  getItem(
    <NavLink to={assignmentListLink}>{assignmentListTitle}</NavLink>,
    assignmentListKeys[0],
    <DatabaseOutlined />,
  ),
];

export type TBreadcrumbInfo = { title: string; key: string[] };
export const breadcrumbNameMap: Record<string, TBreadcrumbInfo> = {
  '/agent/assignment': {
    title: assignmentListTitle,
    key: assignmentListKeys,
  },
};
