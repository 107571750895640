import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Result } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import Button from 'src/components/Button';
import { RootState } from 'src/app/store';
import { config } from 'src/config';
import styled from '@emotion/styled';

const PageNotFound = () => {
  const dispatch = useDispatch();

  const pathname = useSelector((rootState: RootState) => rootState.router.location.pathname);
  const {
    isLogged,
  } = useSelector((rootState: RootState) => rootState.auth);

  const handleBackHome = useCallback(() => {
    dispatch(replace('/'));
  }, [dispatch]);

  useEffect(() => {
    if (isLogged && pathname !== config.page.link.pageNotFound) {
      dispatch(replace(config.page.link.pageNotFound));
    }
  }, [dispatch, pathname, isLogged]);

  return (
    <>
      <Helmet>
        <title>{config.page.title.pageNotFound}</title>
      </Helmet>
      <StyledOfPageNotFound>
        <Result
          status="404"
          title="404"
          subTitle="요청한 페이지를 찾을 수 없습니다."
          extra={
            <Button type="primary" onClick={handleBackHome}>
              Home
            </Button>
          }
        />
      </StyledOfPageNotFound>
    </>
  );
};
export default PageNotFound;

const StyledOfPageNotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
`;
