import React, { FormEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import {
  changeChecked,
  changeWordFind,
  changeWordReplace,
  closeFindReplace,
  editedScript,
  openFindReplace,
  startFinding,
  startReplacing,
  startSeeking,
} from './findReplaceSlice';
import { ScriptParagraph } from './types';
import InputText from 'src/components/InputText';
import Button from 'src/components/Button';
import { SearchOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import Row from 'src/components/Row';
import Col from 'src/components/Col';
import { Text } from 'src/components/Typography';
import Checkbox from 'src/components/Checkbox';
import CheckboxGroup from 'src/components/CheckboxGroup';
import styled from '@emotion/styled';
import { toastr } from 'react-redux-toastr';
import Drawer from 'src/components/Drawer';

const AsideFindReplace = ({ readOnly }: { readOnly?: boolean }) => {
  const dispatch = useDispatch();
  const editorScript = useSelector(
    (rootState: RootState) => rootState.workspace.editor.script,
  ) as ScriptParagraph[];
  const {
    wordFind,
    wordReplace,
    checkedIndexes,
    results,
    noSearchResults,
    isFinding,
    isReplacing,
    isScriptEdited,
  } = useSelector((rootState: RootState) => rootState.workspace.findReplace);

  const handleChangeWordFind: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      // 검색 결과 상태값이 null 아닌 경우(검색어 입력후 검색을 진행했으면 null 이 아님.) - 검색 결과 초기화 시키기
      if (noSearchResults !== null) {
        dispatch(closeFindReplace());
        dispatch(openFindReplace());
      }
      dispatch(changeWordFind(event.target.value));
    },
    [dispatch, noSearchResults],
  );

  const handleChangeWordReplace: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      dispatch(changeWordReplace(event.target.value));
    },
    [dispatch],
  );

  /***
   * FindReplace에서는 startFinding까지만 담당.
   * find는 EditorBox에서 담당.
   * */
  const handleFind = useCallback(
    (event: FormEvent | MouseEvent) => {
      event.preventDefault();
      // 빈문자열은 검색 불가 하다. (공백 2개 이상은 검색 허용)
      if (wordFind === '' || wordFind === ' ') {
        toastr.warning('검색어가 없습니다.', '찾을 검색어를 입력하세요');
        dispatch(changeWordFind(''));
      } else {
        dispatch(startFinding());
        dispatch(editedScript(false));
      }
    },
    [dispatch, editorScript, wordFind],
  );

  /***
   * FindReplace는 startReplacing까지만 담당.
   * replace는 EditorBox에서 담당.
   * */
  const handleReplace: React.MouseEventHandler = useCallback(() => {
    dispatch(startReplacing());
  }, [dispatch, editorScript]);

  const handleToggle = useCallback(
    (value: number) => () => {
      dispatch(changeChecked(value));
    },
    [checkedIndexes],
  );

  const handleToggleAll = useCallback(() => {
    dispatch(changeChecked(null));
  }, []);

  const handleSeek = useCallback(
    (index: number) => () => {
      dispatch(startSeeking(index));
    },
    [],
  );

  return (
    <>
      <StyledOfSearch>
        <form onSubmit={handleFind}>
          <Row gutter={[10, 6]}>
            <Col span={19}>
              <InputText
                id="word-find"
                placeholder="찾기"
                size="small"
                value={wordFind}
                onChange={handleChangeWordFind}
              />
            </Col>
            <Col span={5}>
              <Button
                size="small"
                width="fill"
                icon={<SearchOutlined />}
                htmlType="submit"
                onClick={handleFind}
                disabled={wordFind.length === 0}
                loading={isFinding || isReplacing}
              />
            </Col>

            <Col span={19}>
              <InputText
                id="word-replace"
                placeholder="바꾸기"
                size="small"
                value={wordReplace}
                disabled={readOnly}
                onChange={handleChangeWordReplace}
              />
            </Col>
            <Col span={5}>
              <Button
                size="small"
                width="fill"
                type="primary"
                onClick={handleReplace}
                disabled={readOnly || results.length === 0 || isScriptEdited}
                loading={isReplacing}
              >
                바꾸기
              </Button>
            </Col>
          </Row>
        </form>
        <Divider />
        {isFinding && '검색중...'}
        {isReplacing && '변경중...'}
        <dl className="mb-2">
          <dt className="inline-block mr-2">검색 결과:</dt>
          <dd className="inline-block">{results.length} 개</dd>
          <dt className="inline-block mr-2 ml-2">/ 선택:</dt>
          <dd className="inline-block">{checkedIndexes.length} 개</dd>
        </dl>
        <Checkbox
          onChange={handleToggleAll}
          checked={results.length > 0 && checkedIndexes.length === results.length}
        >
          전체 선택
        </Checkbox>
        <Divider />
      </StyledOfSearch>
      <StyledOfFindReplaceSearchResults>
        {noSearchResults && <p>"{wordFind}" 검색 결과가 없습니다.</p>}
        {noSearchResults === false && (
          <CheckboxGroup value={checkedIndexes}>
            <Row>
              {results.map((result, i) => {
                return (
                  <Col span={24} key={i}>
                    <Checkbox value={i} onClick={handleToggle(i)} />
                    <p onClick={handleSeek(i)}>
                      {result.context.previous}
                      <Text mark={true}>{result.wordFind}</Text>
                      {result.context.next}
                    </p>
                  </Col>
                );
              })}
            </Row>
          </CheckboxGroup>
        )}
      </StyledOfFindReplaceSearchResults>
    </>
  );
};
export default React.memo(AsideFindReplace);

export const StyledAsideFindReplace = styled(Drawer)`
  .ant-drawer-body {
    padding-top: 0;
    position: relative;

    div[class*='ant-divider'] {
      margin: 10px 0;
    }
  }
`;

const StyledOfSearch = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  padding-top: 15px;
  background: #fff;
`;

const StyledOfFindReplaceSearchResults = styled.div`
  .ant-divider {
    margin: 10px 0;
  }

  .col {
    display: flex;
    margin-bottom: 10px;

    > .checkbox {
      margin-right: 5px;
    }

    > p {
      line-height: 1;
      cursor: pointer;
    }
  }
`;
