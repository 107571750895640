// 리스트 페이징
export interface Pagination<D> {
  total: number;
  perPage: number;
  currentPage: number;
  lastPage: number;
  from: number;
  to: number;
  data: D[];
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
