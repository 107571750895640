import apiClient, { Data } from './apiClient';
import { Login } from 'src/models/authTypes';

/**
 * 로그인 요청
 * @param params - 로그인 아이디, 비밀번호, 로그인 상태 유지
 */
const postLogin = async (params: Login) => {
  const { remember, ...rest } = params;
  return await apiClient.post(`/login`, { ...rest });
};


export { postLogin };
