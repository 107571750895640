import React from 'react';
import { Form } from 'antd';
import type { FormItemProps } from 'antd';
import styled from '@emotion/styled';
import classNames from 'classnames';

const FormItem = (props: FormItemProps) => {
  const { children, className, ...rest } = props;
  return (
    <StyledOfFormItem
      {...rest}
      className={classNames('form-item', { [className as string]: className })}
    >
      {children}
    </StyledOfFormItem>
  );
};

export default FormItem;

const StyledOfFormItem = styled(Form.Item)(() => {
  return `
    min-height: auto;
    margin: 0 0 10px 0;
    line-height: 1;
  `;
});
