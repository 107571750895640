import { SortDirection } from "../models/listTypes";

export const configFilter = {
  agentTask: {
    defaultSortKey: 'task.id',
    defaultSortDirection: SortDirection.DESC,
    defaultPage: 1,
    defaultPagePer: 1000,
    defaultDateKey: 'assignment.created_at',
  },
};
