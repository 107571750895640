import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Theme, withStyles } from '@material-ui/core/styles';

const CustomFormControlLabel = withStyles((theme: Theme) => ({
  root: {
    marginLeft: '-3px',
  },
}))(FormControlLabel);

const CustomCheckbox = withStyles({
  root: {
    padding: '0 1px 0 0',
    color: '#a4a4a4',
    '&$checked': {
      color: '#0099ff',
    },
  },
  checked: {},
})((props: CheckboxProps) => <MuiCheckbox color="default" className="icon-checkbox" {...props} />);

export { CustomFormControlLabel, CustomCheckbox };
