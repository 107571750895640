import { ComponentType } from "react";

/*
 * lazyRetry기능은 오류 발생 시 브라우저 새로 고침을 처리.
 */
const lazyRetry = (
  componentImport: () => Promise<any>,
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );

    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh

          // 오류 메시지에 관련 메시지가 포함되어 있는지 확인. - ChunkLoadError: Loading chunk 2 failed.
          const chunkFailedMessage = /Loading chunk [\d]+ failed/;
          if (chunkFailedMessage.test(error)) {
            // 인터넷 연결되어 있다면 새로고침.
            return window.navigator.onLine && window.location.reload();
          }
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export default lazyRetry;
