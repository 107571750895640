import React from 'react';
import { Input, InputProps } from 'antd';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { PasswordProps } from "antd/es/input";

export const InputText = (props: InputProps) => {
  const { className, ...restProps } = props;

  return (
    <>
      <StyledInput
        className={classNames(`input-type-text ${className ? className : ''}`)}
        {...restProps}
      />
    </>
  );
};
export const InputPassword = (props: PasswordProps) => {
  const { className, ...restProps } = props;

  return (
    <>
      <StyledInputPassword
        className={classNames(`input-type-text ${className ? className : ''}`)}
        {...restProps}
        visibilityToggle={false}
      />
    </>
  );
};
export default {};

const StyledInput = styled(Input)`
  &.input-type-text {
  }
`;

const StyledInputPassword = StyledInput.withComponent(Input.Password);
