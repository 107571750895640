import React, { useCallback, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiTypography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';

import { CustomCheckbox, CustomFormControlLabel } from '../../components/CustomMui';
import { Task } from '../../models/taskTypes';
import { LinkInText } from '../../components/link';

const DialogTitle = withStyles(() => ({
  root: {
    width: '500px',
    paddingTop: '20px',
  },
}))(MuiDialogTitle);

const TypographyTitle = withStyles(() => ({
  root: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#f92828',
    justifyContent: 'space-between',
  },
}))(MuiTypography);

const DialogContent = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    paddingTop: '0',
    paddingBottom: '0',
  },
}))(MuiDialogContent);

const TypographyContent = withStyles(() => ({
  root: {
    marginBottom: '8px',
    fontSize: '14px',
  },
}))(MuiTypography);

const DialogContentText = withStyles(() => ({
  root: {
    overflowY: 'auto',
    height: '200px',
    margin: '0',
    padding: '10px 14px',
    whiteSpace: 'pre-wrap',
    border: '1px solid #c4c4c4',
    fontSize: '12px',
  },
}))(MuiDialogContentText);

const DialogActions = withStyles(() => ({
  root: {
    paddingTop: '16px',
    paddingBottom: '20px',
    justifyContent: 'space-between',
  },
}))(MuiDialogActions);

const ModalCheckOrder = ({
  taskUid,
  description,
}: {
  taskUid: Task['uid'];
  description: string;
}) => {
  const [open, setOpen] = useState(true);
  const [state, setState] = useState({
    checkedOrder: false,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    },
    [state],
  );

  const handleClose = useCallback(() => {
    sessionStorage.setItem(`tasks.${taskUid}.isCheckOrder`, 'true');
    setOpen(false);
  }, [taskUid]);

  return (
    <Dialog open={open} className="wrap-dialog">
      <DialogTitle className="wrap-dialog-title" disableTypography={true}>
        <TypographyTitle variant="h6">타이피스트 유의사항</TypographyTitle>
      </DialogTitle>

      <DialogContent className="dialog-content">
        <TypographyContent variant="body1" gutterBottom={true}>
          아래의 유의사항을 잘 지켜서 작업 부탁드립니다.
        </TypographyContent>
        <LinkInText>
          <DialogContentText>{description}</DialogContentText>
        </LinkInText>
      </DialogContent>

      <DialogActions className="dialog-actions">
        <CustomFormControlLabel
          control={
            <CustomCheckbox
              checked={state.checkedOrder}
              onChange={handleChange}
              name="checkedOrder"
              color="primary"
            />
          }
          label="유의사항을 충분히 이해하고 숙지하였습니다."
        />
        <button className="button-color5" disabled={!state.checkedOrder} onClick={handleClose}>
          확인
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCheckOrder;
