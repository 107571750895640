import React from 'react';
import { Checkbox as AntdCheckbox, CheckboxProps } from 'antd';
import classNames from 'classnames';

const Checkbox = (props: CheckboxProps) => {
  const { children, className, ...rest } = props;
  if (children) {
    return (
      <AntdCheckbox
        {...rest}
        className={classNames(`checkbox`, { [className as string]: className })}
      >
        {children}
      </AntdCheckbox>
    );
  } else {
    return (
      <AntdCheckbox
        {...rest}
        className={classNames(`checkbox`, { [className as string]: className })}
      />
    );
  }
};

export default Checkbox;
