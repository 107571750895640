import type {
  FindReplaceItem,
  FindReplaceResult,
  ScriptParagraph,
} from '../features/workspace/types';
import { BaseEditor, Location, Transforms } from 'slate';
import { ReactEditor, WindowEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';

/**
 * 찾으려는 텍스트의 시작, 끝 위치를 입력받아 editor paragraph의 position을 반환한다.
 * @param paragraph
 * @param textStartOffset
 * @param textEndOffset
 */
export const getWordLocation = ({
  paragraph,
  textStartOffset,
  textEndOffset,
}: {
  paragraph: ScriptParagraph;
  textStartOffset: number;
  textEndOffset: number;
}) => {
  type TWordPoint = {
    offset: null | number;
    index: null | number /* node(어절) index */;
  };

  const anchor: TWordPoint = {
    offset: null,
    index: null,
  };
  const focus: TWordPoint = {
    offset: null,
    index: null,
  };

  const offset = {
    start: 0,
    end: 0,
  };

  paragraph.children.some((element, index) => {
    offset.end += element.text.length;

    if (null === anchor.index && textStartOffset < offset.end) {
      anchor.index = index;
      anchor.offset = textStartOffset - offset.start;
    }

    if (null === focus.index && textEndOffset <= offset.end) {
      focus.index = index;
      focus.offset = textEndOffset - offset.start;
    }

    if (null === anchor.index || null === focus.index) {
      offset.start = offset.end;
    }
    return false;
  });

  return {
    wordStartIndex: anchor.index!,
    wordStartOffset: anchor.offset!,
    wordEndIndex: focus.index!,
    wordEndOffset: focus.offset!,
  };
};

/**
 * paragraphText에서 특정 단어의 앞뒤 문맥을 찾아서 반환한다.
 * @param paragraphText
 * @param textStartOffset 특정 단어의 시작 오프셋
 * @param nextWordsOffset 특정 단어의 끝 오프셋
 */
export const findContextWords = (
  paragraphText: string,
  textStartOffset: number,
  nextWordsOffset: number,
): FindReplaceResult['context'] => {
  const text = paragraphText;
  const start = textStartOffset;
  const end = nextWordsOffset;
  const o = Math.max(0, start - 22);
  let i = text.slice(o, start);
  let a = text.slice(end, end + 22);
  o > 0 && (i = i.split(' ').slice(1).join(' '));
  end + 22 < text.length && (a = a.split(' ').slice(0, -1).join(' '));
  return {
    previous: i,
    next: a,
  };
};

export const replaceInEditor = (
  changes: FindReplaceItem[],
  editor: BaseEditor & ReactEditor & WindowEditor & HistoryEditor,
) => {
  const adjustOffset: number[] = [];
  changes.forEach((change) => {
    const paragraphOffset = change.offset[0];
    const wordOffset = change.offset[1];
    const word = change.wordFind;
    const wordReplace = change.wordReplace;
    const paragraph = editor.children[paragraphOffset] as ScriptParagraph;

    // adjustOffset[paragraphOffset] || (adjustOffset[paragraphOffset] = 0);
    if (!adjustOffset[paragraphOffset]) {
      adjustOffset[paragraphOffset] = 0;
    }

    const startOffset = wordOffset + adjustOffset[paragraphOffset];
    const endOffset = startOffset + word.length;

    const { wordStartIndex, wordStartOffset, wordEndIndex, wordEndOffset } = getWordLocation({
      paragraph,
      textStartOffset: startOffset,
      textEndOffset: endOffset,
    });

    const at: Location = {
      anchor: {
        path: [paragraphOffset, wordStartIndex],
        offset: wordStartOffset,
      },
      focus: {
        path: [paragraphOffset, wordEndIndex],
        offset: wordEndOffset,
      },
    };

    Transforms.insertText(editor, wordReplace, { at });
    adjustOffset[paragraphOffset] += wordReplace.length - word.length;
  });
};
