import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RealignmentState } from './types';
import { Task } from '../../models/taskTypes';
import { AppThunk } from '../../app/store';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';
import { Error } from '../../api/apiClient';
import { pullTask } from './workspaceSlice';

const initialState: RealignmentState = {
  isOpen: false,
  isFetching: false,
  meta: '',
};

export const realignmentSlice = createSlice({
  name: 'REALIGNMENT',
  initialState,
  reducers: {
    startRealignment(state) {
      state.isFetching = true;
    },
    successRealignment(state, { payload }: PayloadAction<RealignmentState['meta']>) {
      state.isFetching = false;
      state.meta = payload;
    },
    openMetaDialog(state) {
      state.isOpen = true;
    },
    closeMetaDialog(state) {
      state.isOpen = false;
    },
  },
});

export default realignmentSlice.reducer;

export const {
  startRealignment,
  successRealignment,
  openMetaDialog,
  closeMetaDialog,
} = realignmentSlice.actions;

export const realignment =
  (taskUid: Task['uid']): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(startRealignment());
        const meta = await api.realignment(taskUid);
        dispatch(successRealignment(meta));
        dispatch(pullTask(taskUid));
        dispatch(openMetaDialog());
      } catch (err) {
        const {
          data: { code, message }, status,
        } = err as AxiosResponse<Error>;
        if (code) {
          toastr.warning('알림', message);
        }
      }
    };
