import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { closeMetaDialog } from './realignmentSlice';
import ModalAlert from './ModalAlert';
import { Card } from 'antd';
import styled from '@emotion/styled/dist/emotion-styled.cjs';

const ModalRealignment = () => {
  const dispatch = useDispatch();

  const meta = useSelector((rootState: RootState) => rootState.workspace.realignment.meta);
  const isOpen = useSelector((rootStat: RootState) => rootStat.workspace.realignment.isOpen);

  const handleClose = useCallback(() => {
    dispatch(closeMetaDialog());
  }, [dispatch]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalAlert
      title="싱크보정 결과"
      content={
        <>
          <p>
            스크립트의 싱크를 보정했습니다.
            <br /> 히스토리 내역에서 이전 상태로 복구할 수 있습니다.
          </p>
          <br />
          <StyledModalRealignment>
            <h6 className="title">상세내역</h6>
            <Card size="small" className="card">
              <pre>{meta}</pre>
            </Card>
          </StyledModalRealignment>
        </>
      }
      isOpen={isOpen}
      onConfirm={handleClose}
    />
  );
};

export default ModalRealignment;

const StyledModalRealignment = styled.section`
  padding-top: 20px;
  border-top: 1px solid #f0f0f0;
  font-size: 12px;

  .title {
    margin-bottom: 5px;
    font-size: inherit;
    font-weight: 500;
  }

  .card {
    font-size: inherit;
  }
`;
