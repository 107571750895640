import React, { useEffect, useRef } from 'react';
import { Editor, Editor as SlateEditor, Range, Text, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { ClassNames } from '@emotion/react';
import { Button, Menu, Portal } from '../../components/EditorToolbar';
import './types';
import { ScriptWord } from './types';

export type TypeToolbarButtonKeys = keyof Pick<ScriptWord, 'unrecognizable'>;

const HoveringToolbar = () => {
  const ref = useRef<HTMLDivElement>(null);
  const editor = useSlate() as Editor;

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      SlateEditor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection && domSelection.getRangeAt(0);
    const rect = domRange && domRange.getBoundingClientRect();

    if (rect) {
      el.style.opacity = '1';
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
      el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
    }
  });

  return (
    <Portal>
      <ClassNames>
        {({ css }) => (
          <Menu
            ref={ref}
            className={css`
              position: absolute;
              z-index: 1;
              top: -10000px;
              left: -10000px;
              margin-top: -6px;
              padding: 0;
              border-radius: 4px;
              font-size: 0;
              background-color: #fff;
              opacity: 0;
              transition: opacity 0.75s;
            `}
          >
            <FormatButton format="unrecognizable" text="식별불가" />
          </Menu>
        )}
      </ClassNames>
    </Portal>
  );
};

const isFormatActive = (editor: Editor, format: TypeToolbarButtonKeys) => {
  const generator = SlateEditor.nodes(editor, {
    match: (n) => (n as ScriptWord)[format] === true,
    mode: 'all',
  });
  const yieldValue = generator.next();
  const value = yieldValue.value;
  return !!value;
};

const toggleFormat = (editor: Editor, format: TypeToolbarButtonKeys) => {
  const isActive = isFormatActive(editor, format);

  Transforms.setNodes(
    editor,
    { [format]: isActive ? undefined : true },
    { match: Text.isText, split: true },
  );
};

const FormatButton = ({ format, text }: { format: TypeToolbarButtonKeys; text: string }) => {
  const editor = useSlate();
  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    toggleFormat(editor, format);
  };

  return (
    <ClassNames>
      {({ css }) => (
        <Button
          reversed={false}
          active={isFormatActive(editor, format)}
          onMouseDown={handleMouseDown}
          className={css`
            border: none;
            font-size: 12px;
            line-height: 2;
            margin: 0 !important;
            padding: 0 7px;
            background: transparent;
            vertical-align: top;
          `}
        >
          {text}
        </Button>
      )}
    </ClassNames>
  );
};

export default HoveringToolbar;
