import { SortDirection, Pagination } from 'src/models/listTypes';
import { ISO8601Timestamp } from 'src/models';
import { TaskStatus, Task, Order, TaskAssignment } from 'src/models/taskTypes';

export interface AgentTaskListItem {
  readonly id: number; // No
  readonly type: number; // 서비스
  readonly orderId: number; // 작업그룹
  readonly createdAt: ISO8601Timestamp; // 할당일시
  readonly dueAt: ISO8601Timestamp; // 마감일시
  readonly editorLink: Task['editorLink']; // 에디터
  readonly originalFile: Task['originalFile'] & { order: Order };
  readonly sttStatus: Task['sttStatus'];
  readonly attachments?: {
    downloadLink: string;
  }[]; // text 파일
  status: TaskStatus; // 작업 진행 상황
  taskAssignments: TaskAssignment[]; // 작업건 할당 내역
}

export enum TaskAssignmentRole {
  TYPING = 'TYPING',
  QC = 'QC',
  SYNC = 'SYNC',
}

export type AgentTaskListData = Pagination<AgentTaskListItem> & { role?: TaskAssignmentRole };

export interface AgentTaskListFilter {
  readonly page?: number /* 페이징 번호 */;
  readonly perPage?: number /* 리스트 갯수 */;

  readonly sortKey?: string /* 정렬키 */;
  readonly sortDirection?: SortDirection.ASC | SortDirection.DESC /* 정렬 방향 */;

  readonly startAt?: Date | ISO8601Timestamp /* 등록일: 검색시작일 */;
  readonly endAt?:
    | Date
    | ISO8601Timestamp /* 등록일: 검색종료일 - ISO8601Timestamp Date string */;

  readonly dateKey?: string /* 활동상태 */;
  readonly role?: TaskAssignmentRole;
}

type AgentTaskFilterType =
  | 'sortKey'
  | 'sortDirection'
  | 'perPage'
  | 'page'
  | 'startAt'
  | 'endAt'
  | 'dateKey';

export interface AgentTaskListState {
  filter: Pick<AgentTaskListFilter, AgentTaskFilterType>;
  isFetching: boolean;
  isValid: boolean;
  isDirty: boolean;
  typingData: AgentTaskListData;
  qcData: AgentTaskListData;
  syncData: AgentTaskListData;
}
