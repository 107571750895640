import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox/Group';
import classNames from 'classnames';

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { className, ...rest } = props;
  return (
    <AntdCheckbox.Group
      {...rest}
      className={classNames(`checkbox-group`, { [className as string]: className })}
    />
  );
};

export default CheckboxGroup;
