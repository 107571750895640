import React, {
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { isMacOs, isMobile, isWindows } from 'react-device-detect';
import queryString from 'query-string';
import styled from '@emotion/styled';

import { closePopupFailurePullTask, pullTask } from './workspaceSlice';
import { RootState } from 'src/app/store';
import { RouteComponentProps, withRouter } from 'react-router';

import Workspace from './Workspace';
import { ScriptFormat } from './types';

import { ThemeProvider } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';

import { Modal as AntdModal, Spin } from 'antd';
import _ from 'lodash';
import { replace } from 'connected-react-router';
import { config } from 'src/config';
import { toastr } from "react-redux-toastr";
import { Helmet } from "react-helmet-async";
import { Task } from "../../models/taskTypes";
import { ErrorCode } from "../../models";

const theme = unstable_createMuiStrictModeTheme();

const WorkspaceIndex = ({
  match: {
    params: { id: taskUid },
  },
  location,
}: RouteComponentProps<{ id: Task['uid'] }>) => {
  const dispatch = useDispatch();
  const { task, order, isFetching, error } = useSelector(
    (rootState: RootState) => rootState.workspace.repositories,
  );


  /*** 쿼리 스트링: 비디오 파일 요청 관련(type) ***/
  const { type: mediaType } = useMemo(() => {
    return queryString.parse(location.search);
  }, [location]);

  /*** 지원하지 않는 디바이스(모바일) 접속인지 확인 ***/
  const unSupportedDevice = useMemo(() => {
    return isMobile || (!isMacOs && !isWindows);
  }, []);

  /*작업 내용 호출*/
  useEffect(() => {
    dispatch(pullTask(taskUid, mediaType === 'video' ? mediaType : undefined));
  }, [dispatch, taskUid]);

  /*** [팝업] 할당 받지 않은 에디터 접근시 알림창 ***/
  useEffect(() => {
    const errorCode = _.get(error, 'code');
    if (errorCode === ErrorCode.NO_PERMISSION) {
      AntdModal.error({
        title: '할당 받지 않은 작업입니다.',
        content: <p>할당 받지 않은 작업으로 접근 할 수 없습니다.</p>,
        okText: '확인',
        onOk() {
          dispatch(replace(config.page.link.assignmentList));
          dispatch(closePopupFailurePullTask());
        },
      });
    }
  }, [error]);

  /* 에디터를 벗어나면 에디터 관련 토스트 창 모두 제거 */
  useEffect(() => {
    return () => {
      toastr.clean();
    }
  }, []);

  if (unSupportedDevice) {
    return (
      <EntryDiv>
        <p>모바일은 지원하지 않습니다.</p>
        <br />
        <br />
        <p>PC에서 접속하시기 바랍니다.</p>
      </EntryDiv>
    );
  }

  // fetch 중
  if (isFetching) {
    return (
      <EntryDiv>
        <div>loading...</div>
        {<LinearProgress style={{ width: '200px', marginTop: '10px' }} />}
      </EntryDiv>
    );
  }

  if (!task || !order) {
    return (
      <EntryDiv>
        <Spin tip="Loading" size="large" />
      </EntryDiv>
    );
  }

  /* scriptFormat 가 'txt' */
  if (task.scriptFormat === ScriptFormat.TXT) {
    return <EntryDiv>task.scriptFormat: txt</EntryDiv>;
  }

  return (
    <>
      <Helmet>
        <title>typeX 에디터</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Workspace task={task} />
      </ThemeProvider>
    </>
  );
};

export default withRouter(WorkspaceIndex);

export const EntryDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  background-color: #252525;
`;
