import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { reducer as toastrReducer } from 'react-redux-toastr';

import player from '../features/workspace/playerSlice';
import editor from '../features/workspace/editorBoxSlice';
import repositories from '../features/workspace/workspaceSlice';
import scriptHistory from '../features/workspace/scriptHistorySlice';
import typingStatus from '../features/workspace/typingStatusSlice';
import editSpeakers from '../features/workspace/speakersSlice';
import findReplace from '../features/workspace/findReplaceSlice';
import spellCheck from '../features/workspace/spellCheckSlice';
import shortcutKeyList from '../features/workspace/shortcutKeysSlice';
import auth from '../features/login/authSlice';
import agentTaskList from '../features/agent/tasks/agentTaskListSlice';
import realignment from '../features/workspace/realignmentSlice';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    workspace: combineReducers({
      repositories,
      player,
      editor,
      scriptHistory,
      typingStatus,
      editSpeakers,
      findReplace,
      realignment,
      spellCheck,
      shortcutKeyList,
    }),
    toastr: toastrReducer,
    auth,
    agent: combineReducers({
      tasks: agentTaskList,
    }),
  });

export default rootReducer;
