import React from 'react';
import classNames from 'classnames';
import { Typography } from 'antd';
import type { TextProps } from 'antd/es/typography/Text';
import type { LinkProps } from 'antd/es/typography/Link';
import type { TitleProps } from 'antd/es/typography/Title';
import type { ParagraphProps } from 'antd/es/typography/Paragraph';

interface InternalTypographyProps {
  children: React.ReactNode;
  className?: string;
}

/*** Text ***/
export const Text = (props: InternalTypographyProps & TextProps) => {
  const { children, className, ...rest } = props;
  return (
    <Typography.Text {...rest} className={classNames('text', { [className as string]: className })}>
      {children}
    </Typography.Text>
  );
};

/*** Link ***/
export const Link = (props: InternalTypographyProps & LinkProps) => {
  const { children, className, ...rest } = props;
  return (
    <Typography.Link {...rest} className={classNames('link', { [className as string]: className })}>
      {children}
    </Typography.Link>
  );
};

/*** Title ***/
export const Title = (props: InternalTypographyProps & TitleProps) => {
  const { children, className, ...rest } = props;
  return (
    <Typography.Title
      {...rest}
      className={classNames('title', { [className as string]: className })}
    >
      {children}
    </Typography.Title>
  );
};

/*** Paragraph ***/
export const Paragraph = (props: InternalTypographyProps & ParagraphProps) => {
  const { children, className, ...rest } = props;
  return (
    <Typography.Paragraph
      {...rest}
      className={classNames('paragraph', { [className as string]: className })}
    >
      {children}
    </Typography.Paragraph>
  );
};
