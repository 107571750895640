import * as H from 'history';
import { match } from 'react-router';

export type ISO8601Timestamp = string;

// 라우트 타입
export interface Route {
  history: H.History;
  location: H.Location;
  match: match | null;
}

export enum Product {
  BASIC = 'basic',
  CERTIFICATED = 'certificated',
  CAPTION = 'caption',
}

export enum ProductLabel {
  BASIC = '일반 타이핑',
  CERTIFICATED = '녹취 공증',
  CAPTION = '영상 자막 제작',
}

// form 프로퍼티 타입
export type FormProperty = {
  [p: string]: any;
};

export enum Unknown {
  UNKNOWN = '모름',
}

export enum ErrorCode {
  AUTHENTICATION_FAILED = 40101,
  INVALID_TASK_PASSWORD = 40301,
  VALIDATION = 42201,
  TASK_NOT_FOUND = 40403,
  TASK_SCRIPT_HISTORY_NOT_FOUND_ERROR = 40404,
  NO_PERMISSION = 40102, // (타이피스트)접근 권한 없음
  INVALID_TEXT = 42220, // 맞춤법 api 응답 관련 오류
  INPUT_TEXT_OVERFLOW = 42221, // 5만자 넘어서 발생하는 오류에 대한
  INPUT_SENTENCE_OVERFLOW = 42222, // 5천자 넘어서 발생한 오류에 대한
}