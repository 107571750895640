import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { config } from 'src/config';
import type {
  AgentTaskListData,
  AgentTaskListFilter,
  AgentTaskListState,

} from 'src/features/agent/tasks/types';
import { TaskAssignmentRole, AgentTaskListItem } from 'src/features/agent/tasks/types';
import { Agent } from 'src/models/agentTypes';
import api from 'src/api';
import { AppThunk } from 'src/app/store';
import { TaskAssignment } from "../../../models/taskTypes";

const initialData = {
  total: 0,
  currentPage: 1,
  lastPage: 1,
  perPage: config.agentTask.defaultPagePer,
  from: 0,
  to: 0,
  data: [],
  totalCaptionCount: 0,
};

const initialState: AgentTaskListState = {
  filter: {
    sortKey: config.agentTask.defaultSortKey,
    sortDirection: config.agentTask.defaultSortDirection,
    page: config.agentTask.defaultPage,
    perPage: config.agentTask.defaultPagePer,
    dateKey: config.agentTask.defaultDateKey,
  },
  isFetching: false,
  isValid: false,
  isDirty: false,
  typingData: initialData,
  qcData: initialData,
  syncData: initialData,
};

export const agentTaskListSlice = createSlice({
  name: 'AGENT-TASK-LIST',
  initialState,
  reducers: {
    initTaskListState(state) {
      state.filter = initialState.filter;
    },
    startAgentTaskListFetch(state) {
      state.isDirty = true;
      state.isFetching = true;
      state.isValid = false;
    },
    successAgentTaskListFetch(
      state,
      {
        payload: { data, filter },
      }: PayloadAction<{ data: AgentTaskListData; filter?: AgentTaskListFilter }>,
    ) {
      state.isDirty = false;
      state.isFetching = false;
      state.isValid = true;

      if (filter) {
        state.filter = {
          ...state.filter,
          ...filter,
        };

        if (filter.role === TaskAssignmentRole.TYPING) {
          state.typingData = { ...data, role: TaskAssignmentRole.TYPING };
        } else if (filter.role === TaskAssignmentRole.QC) {
          state.qcData = { ...data, role: TaskAssignmentRole.QC };
        } else if (filter.role === TaskAssignmentRole.SYNC) {
          state.syncData = { ...data, role: TaskAssignmentRole.SYNC };
        }
      }
    },
    failedAgentTaskListFetch(state) {
      state.isDirty = false;
      state.isFetching = false;
      state.isValid = false;
    },
  },
});

export const {
  initTaskListState,
  startAgentTaskListFetch,
  successAgentTaskListFetch,
  failedAgentTaskListFetch,
} = agentTaskListSlice.actions;
export default agentTaskListSlice.reducer;

/* 목록에 필요한 정보 정리 */
const listDataSelector = (data: any, role?: TaskAssignmentRole) => {
  const { data: listData } = data;

  const taskData = listData.map((item: AgentTaskListItem, index: number) => {
    let createdAt;
    let dueAt;

    if (role) {
      const taskAssignmentIndex = item.taskAssignments.findIndex(
        (AssignmentObj: TaskAssignment) => AssignmentObj.role === role,
      );

      const taskAssignment = item.taskAssignments[taskAssignmentIndex];
      if (taskAssignment) {
        createdAt = item.taskAssignments[taskAssignmentIndex].createdAt;
        dueAt = item.taskAssignments[taskAssignmentIndex].dueAt;
      }
    }

    return {
      ...item,
      key: `agentTask${role || 'ListRow'}${index}`,
      name: item.originalFile.name,
      orderId: item.originalFile.order.id,
      type: item.originalFile.order.productType,
      createdAt,
      dueAt,
    };
  });

  return {
    ...data,
    data: taskData,
  };
};

export const typingDataSelector = createSelector(
  (state: any) => state.agent.tasks.typingData,
  (state: any) => state.agent.tasks.typingData.role,
  listDataSelector,
);
export const qcDataSelector = createSelector(
  (state: any) => state.agent.tasks.qcData,
  (state: any) => state.agent.tasks.qcData.role,
  listDataSelector,
);
export const syncDataSelector = createSelector(
  (state: any) => state.agent.tasks.syncData,
  (state: any) => state.agent.tasks.syncData.role,
  listDataSelector,
);

/*** 할당 받은 작업건 호출 ***/
export const pullAgentTaskList =
  (id: Agent['id'], role: TaskAssignmentRole, filter?: AgentTaskListFilter): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(startAgentTaskListFetch());

      const filterState = getState().agent.tasks.filter;

      const resultFilter = {
        ...filterState,
        ...filter,
        role,
      };

      const result = await api.getAgentTaskList(id, resultFilter);

      dispatch(successAgentTaskListFetch({ data: result, filter: resultFilter }));
    } catch (err) {
      dispatch(failedAgentTaskListFetch());
    }
  };
