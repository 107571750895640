import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Editor, ScriptFormat, ScriptParagraph } from './types';

import { removeScriptInfoFromLocalStorage, successTaskFetch } from './workspaceSlice';
import { AppThunk } from '../../app/store';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { Task } from "../../models/taskTypes";

const initialState: Editor = {
  script: [],
  editedAt: null,
  isScriptUpdating: false,
  isScriptSaved: true,
  isEventsFromOutside: false,
  error: null,
};

export const editorBoxSlice = createSlice({
  name: 'EDITOR',
  initialState,
  reducers: {
    commitScript(
      state,
      {
        payload: { changedScript, isScriptSaved },
      }: PayloadAction<{ changedScript: ScriptParagraph[]; isScriptSaved?: boolean }>,
    ) {
      state.script = changedScript;
      state.isScriptSaved = isScriptSaved || false;
    },

    changeIsEventsFromOutside(state, { payload }: PayloadAction<boolean>) {
      // slate가 업데이트 되면서 slate 외부의 이벤트가 실행디어 바뀐 value 값을 editor.children 으로 넣어주어야 하는 확인값
      state.isEventsFromOutside = payload;
    },

    // task 스크립트 값 / 수정일시
    startTaskScriptUpdate(state) {
      state.isScriptUpdating = true;
      state.isScriptSaved = false; /* db에 업데이트 하고 있으니 db 저장 전 */
      state.error = null;
    },

    successTaskScriptUpdate(
      state,
      { payload: { editedAt } }: PayloadAction<{ editedAt: Task['editedAt'] }>,
    ) {
      state.isScriptUpdating = false;
      state.isScriptSaved = true;
      state.editedAt = editedAt;
      state.error = null;
    },
    failTaskScriptUpdate(state, { payload }: PayloadAction<any>) {
      state.isScriptUpdating = false;
      state.error = payload;
    },
  },
  extraReducers: {
    [successTaskFetch.type]: (state, { payload: { task } }: PayloadAction<{ task: Task }>) => {
      state.script = task.script.value;
      state.editedAt = task.editedAt;
      return state;
    },
  },
});

export default editorBoxSlice.reducer;

export const {
  commitScript,
  changeIsEventsFromOutside,
  startTaskScriptUpdate,
  successTaskScriptUpdate,
  failTaskScriptUpdate,
} = editorBoxSlice.actions;

/*** 작업건의 스크립트를 수정 ***/
type TypePushScript = {
  taskUid: Task['uid'];
  value?: ScriptParagraph[] | string;
  format?: ScriptFormat;
  option: { refresh?: boolean; callLocation?: string };
  calledByReducer?: boolean;
};
export const pushTaskScript =
  ({ taskUid, value, format, option, calledByReducer }: TypePushScript): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const isUpdating = state.workspace.editor.isScriptUpdating;
    // value(강제로 바꾸고 싶은 스크립트 내용)가 없다면 스토어에 있는 editor.script(현재 편집 화면의 스크립트 내용) 사용.
    const editorValue = value || state.workspace.editor.script;

    /* 결과에 따른 토스트 팝업(자동으로 닫히지 않는)이 노출되고 있다면, 시작하면서 모두 제거(2022.03.29) */
    if (option.callLocation !== 'speakerUpdate') {
      toastr.clean();
    }

    // 스크립트 업데이트가 동시에 한 번 이상 실행되는걸 막는다.
    if (isUpdating) {
      // 스크립트를 DB에 업데이트 중에서는 스크립트 수정을 했으나 저장 요청을 못하는 상황...(수동적 저장이 가능하게) 어떻게 하나...고민이 필요함
      return;
    }

    try {
      dispatch(startTaskScriptUpdate());
      const resultTask = await api.updateTaskScript(
        taskUid,
        editorValue,
        format || ScriptFormat.TPX,
        option.callLocation,
      );
      dispatch(
        successTaskScriptUpdate({
          editedAt: resultTask.editedAt,
        }),
      );

      // 임시 저장된 스크립트(로컬스토리지에 저장된 스크립트) 제거
      removeScriptInfoFromLocalStorage(taskUid);

      // 새로고침 해야 할 때.
      if (option.refresh) {
        window.location.replace('');
      }
    } catch (err) {
      const errorData = (err as AxiosResponse<TypeError>).data || {};
      dispatch(failTaskScriptUpdate(errorData));

      /* 네트워크 오류가 아닌 경우에만 센트리 보고 */
      if (String(err).indexOf('request failed. Error: Network Error') === -1) {
        const savedWorkerInfo =
          sessionStorage.getItem(`tasks.${taskUid}.workerInfo`) ||
          "{fileName: '', typingAgentNames: ''}";
        const workerInfo = JSON.parse(savedWorkerInfo);
        const message = calledByReducer
          ? '변경된 스크립트 저장에 실패: '
          : '스크립트 편집중 저장에 실패: ';

        Sentry.captureException(err);
        Sentry.captureMessage(
          message +
            JSON.stringify({
              '작업 id': workerInfo.taskId,
            }),
        );
      }

      /* 컴포넌트 이외에서 호출시(컴포넌트에서 호출시 오류 발생하여 이와 같이 조건을 추가함) */
      if (calledByReducer) {
        throw new Error(err);
      } else {
        // TODO - 기획서에 없는 것.
        toastr.error('오류', '편집 내용을 저장 중 오류가 발생했습니다', { timeOut: 0 });
      }
    }
  };
