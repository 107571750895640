import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiTypography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';

const DialogTitle = withStyles(() => ({
  root: {
    paddingTop: '20px',
    paddingBottom: '10px',
  },
}))(MuiDialogTitle);
//
const TypographyTitle = withStyles(() => ({
  root: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 1.5,
  },
}))(MuiTypography);
//
const DialogContent = withStyles(() => ({
  root: {
    paddingTop: '0',
    paddingBottom: '0',
  },
}))(MuiDialogContent);

const DialogContentText = styled.div`
  font-size: 12px;
`;

const ModalAlert = ({
  title,
  content,
  isOpen,
  onClose,
  onConfirm,
  textConfirm,
  textCancel,
  maxWidth,
  minWidth,
}: {
  title: string;
  content: any;
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  minWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  textConfirm?: string;
  textCancel?: string;
}) => {
  return (
    <>
      <StyledDialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="wrap-dialog"
        maxWidth={maxWidth}
        minwidth={minWidth}
      >
        <DialogTitle className="wrap-dialog-title" id="alert-dialog-title" disableTypography={true}>
          <TypographyTitle variant="h6">{title || '알림'}</TypographyTitle>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <DialogContentText id="alert-dialog-description">{content || ''}</DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions">
          {onClose && (
            <button type="button" onClick={onClose} className="button-cancel button-color4">
              {textCancel || '취소'}
            </button>
          )}

          {onConfirm && (
            <button type="button" onClick={onConfirm} className="button-cancel button-color4">
              {textConfirm || '확인'}
            </button>
          )}
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default ModalAlert;

const StyledDialog = styled(Dialog)(
  (props: DialogProps & { minwidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false }) => {
    const { minwidth } = props;

    const addStyles = {
      minWidth: 'auto',
    };

    switch (minwidth) {
      case 'sm':
        addStyles.minWidth = '300px';
        break;
    }

    return `
      .MuiDialog-paper {
        min-width: ${addStyles.minWidth}
      }
    `;
  },
);
