import React from 'react';
import { Drawer as AntdDrawer } from 'antd';
import { DrawerProps } from 'antd/es/drawer';
import classNames from 'classnames';

const Drawer = (props: DrawerProps) => {
  const { children, className, ...rest } = props;
  return (
    <AntdDrawer {...rest} className={classNames(`drawer`, { [className as string]: className })}>
      {children}
    </AntdDrawer>
  );
};

export default Drawer;
