import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, ErrorMessage, Login } from 'src/models/authTypes';
import { replace } from 'connected-react-router';
import { AppThunk } from 'src/app/store';
import api from 'src/api';
import { decode } from 'jsonwebtoken';
import _ from 'lodash';
import { config } from 'src/config';
import axios from 'axios';
import { AgentRole, Agent } from 'src/models/agentTypes';

const initialState: AuthState = {
  requesting: false,
  isLogged: false,
  agent: {},
  token: {},
};

export const authSlice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {
    /* 로그인 */
    startLoginRequest(state) {
      state.requesting = true;
    },
    successLoginRequest(state, { payload }: PayloadAction<AuthState>) {
      const {
        agent: { id, loginId, name, roles },
        token: { accessToken },
      } = payload;

      if (accessToken) {
        // 로그인 성공 시 Header에 Token 정보를 저장
        setAuthorizationToken(accessToken);
      }

      state.requesting = false;
      state.isLogged = true;
      state.agent = {
        id,
        loginId,
        name,
        roles,
      };

      // roles 에 'EXPERT' 있다면 '속기가' 임.
      if (_.includes(roles, AgentRole.EXPERT)) {
        state.agent.stenographer = true;
      }
    },

    failureLoginRequest(state, { payload }: PayloadAction<ErrorMessage>) {
      state.requesting = false;
      state.isLogged = false;
      if (payload) {
        state.errorMessage = payload;
      }
    },
    // handleClosePopupToCancelRequest
    closePopupFailureLogin(state) {
      state.errorMessage = undefined;
    },

    /* 로그아웃 */
    successLogoutRequest(state) {
      //
      state.requesting = false;
      state.isLogged = false;
      state.token = {};
      state.agent = {};
      setAuthorizationToken();
    },
  },
});

export const {
  startLoginRequest,
  successLoginRequest,
  failureLoginRequest,
  successLogoutRequest,
  closePopupFailureLogin,
} = authSlice.actions;

export default authSlice.reducer;

/*** 로그인 실행 ***/
export const loginRequest =
  (form: Login, prevLink?: string | null): AppThunk =>
  async (dispatch) => {
    try {
      const accessToken = (await api.postLogin(form)) as string;

      // decode된 JWT
      const myDecodedToken = decode(accessToken) as { agent: Agent; exp: number };
      const { agent } = myDecodedToken;

      // 토큰 저장
      if (form.remember) {
        // '로그인 유지' 체크 - 쿠기
        localStorage.setItem(config.API_TOKEN, accessToken);
      } else {
        // '로그인 유지' 미체크 - 세션스토리지
        sessionStorage.setItem(config.API_TOKEN, accessToken);
      }

      dispatch(
        successLoginRequest({
          agent,
          token: {
            accessToken,
          },
        } as AuthState),
      );
      dispatch(replace(prevLink ? prevLink : '/'));
    } catch (error) {
      let message: ErrorMessage;
      const { code } = error.data || { code: undefined };

      switch (code) {
        case 40302:
          message = '로그인 오류';
          break;
      }

      dispatch(failureLoginRequest(message));
    }
  };

/*** 로그아웃 실행 ***/
export const logoutRequest = (): AppThunk => async (dispatch) => {
  // 토큰 삭제
  localStorage.removeItem(config.API_TOKEN);
  sessionStorage.removeItem(config.API_TOKEN);
  dispatch(successLogoutRequest());
  dispatch(replace(config.page.link.login));
};

/**
 * 로그인 성공 시 Header에 Token 정보를 저장
 * jwtToken을 HTTP Request를 보낼 때 헤더에 포함시키기.
 * 토큰이 있으면 header에 포함시키고, 없으면 그 부분을 지우는 함수.
 */
const setAuthorizationToken = (token?: string) => {
  if (token) {
    // API 요청하는 콜마다 헤더에 accessToken 담아 보내도록 설정
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};
