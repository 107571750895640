import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import AsideInfoBox from './AsideInfoBox';
import { toastr } from 'react-redux-toastr';
import { RootState } from '../../app/store';
import classNames from 'classnames';
import {
  changeProcessChangScript,
  changeSetSpeakersAutomatically,
  changeSpeakersInfo,
  pushTaskSpeakers,
} from './speakersSlice';
import 'moment/locale/ko';
import ModalAlert from './ModalAlert';
import { delCookie, getCookie } from '../../utils/cookie';
import Button from '../../components/ButtonInEditor';
import styled from '@emotion/styled';
import { SpeakerInfo } from '../../models/taskTypes';

const StyleSpeakerAutomaticallySetDate = styled.p`
  margin-top: 5px;
  text-align: center;
`;

const AsideSpeaker = ({ readOnly }: { readOnly?: boolean }) => {
  const dispatch = useDispatch();
  const taskUid = useSelector((rootState: RootState) => rootState.workspace.repositories.task!.uid);
  const speakers = useSelector((rootState: RootState) => rootState.workspace.editSpeakers.speakers);
  const speakerOfFirstParagraph = useSelector(
    (rootState: RootState) => rootState.workspace.editSpeakers.speakerOfFirstParagraph,
  );
  const processChangScript = useSelector(
    (rootState: RootState) => rootState.workspace.editSpeakers.processChangScript,
  );

  const [speakerAutomaticallySetDate, setSpeakerAutomaticallySetDate] = useState<string | null>();
  const [openSetSpeakerAutomaticallyAlert, setOpenSetSpeakerAutomaticallyAlert] = useState(false);

  /*** 화자 추가 ***/
  const nextId = useRef(1);
  const handleAddSpeaker = useCallback(() => {
    while (_.find(speakers, { 'name': '화자 ' + nextId.current })) {
      nextId.current += 1;
    }

    const speaker = {
      name: '화자 ' + nextId.current,
      feature: '',
    };

    const newSpeakers = speakers.concat(speaker);

    // 화자 추가시: 등록된 화자 수가 2명이 아니면, 저장했던 '화자 자동 지정' 날짜 삭제
    if (newSpeakers.length !== 2) {
      setSpeakerAutomaticallySetDate(null);
      delCookie(`tasks.${taskUid}.speakerAutomaticallySetDate`);
    }

    dispatch(pushTaskSpeakers({ taskUid, value: newSpeakers }));
  }, [dispatch, speakers, taskUid]);

  /*** 화자 자동 지정 ***/
  const handleSetSpeakerAutomatically = useCallback(() => {
    setOpenSetSpeakerAutomaticallyAlert(true);
  }, []);

  /* 화자 자동 지정 확인창: 확인 */
  const handleConfirmSetSpeakerAutomaticallyAlert = useCallback(() => {
    setOpenSetSpeakerAutomaticallyAlert(false);
    if (speakerOfFirstParagraph) {
      const speakerIndex = speakers.findIndex(
        (speakerInfo) => speakerInfo.name === speakerOfFirstParagraph,
      );
      if (speakerIndex > -1) {
        dispatch(changeSetSpeakersAutomatically(taskUid, speakerIndex, speakers));
      } else {
        toastr.error('오류', '첫문장 "화자" 이름이 "화자 정보" 에 등록되어 있지 않습니다');
      }
    }
  }, [dispatch, taskUid, speakers, speakerOfFirstParagraph]);

  /* 화자 자동 지정 확인창: 취소 */
  const handleCancelSetSpeakerAutomaticallyAlert = useCallback(() => {
    setOpenSetSpeakerAutomaticallyAlert(false);
  }, []);

  // 화자 삭제시: 등록된 화자 수가 2명이 아니면, 저장했던 '화자 자동 지정' 날짜 삭제
  const handleSetSpeakerAutomaticallySetDate = useCallback(() => {
    setSpeakerAutomaticallySetDate(null);
  }, []);

  useEffect(() => {
    if (processChangScript !== 'changing') {
      setSpeakerAutomaticallySetDate(getCookie(`tasks.${taskUid}.speakerAutomaticallySetDate`));

      if (processChangScript !== 'ready') {
        dispatch(changeProcessChangScript('ready'));
      }
    }
  }, [dispatch, taskUid, processChangScript]);

  /* 첫번째 문단의 '화자'가 지정되지 않았다면, 저장했던 '화자 자동 지정' 날짜 삭제 */
  useEffect(() => {
    if (
      speakers.length === 2 &&
      (!speakerOfFirstParagraph || speakerOfFirstParagraph === '화자없음')
    ) {
      setSpeakerAutomaticallySetDate(null);
      delCookie(`tasks.${taskUid}.speakerAutomaticallySetDate`);
    }
  }, [dispatch, speakerOfFirstParagraph, speakers.length, taskUid]);

  return (
    <>
      <AsideInfoBox className="speaker" title="화자 정보">
        <button
          type="button"
          className="button-speaker-create button-color2"
          onClick={handleAddSpeaker}
          disabled={readOnly}
        >
          <span className="text-hidden">화자 추가</span>
        </button>
        {speakers.map((item, index) => {
          return (
            <SpeakerItem
              key={index + item.name}
              {...item}
              index={index}
              speakers={speakers}
              readOnly={readOnly}
              onSetSpeakerAutomaticallySetDate={handleSetSpeakerAutomaticallySetDate}
            />
          );
        })}
        {speakers.length === 2 && (
          <>
            <Button
              variant="contained"
              fullWidth={true}
              loading={processChangScript === 'changing'}
              onClick={handleSetSpeakerAutomatically}
              disabled={
                readOnly || !speakerOfFirstParagraph || speakerOfFirstParagraph === '화자없음'
              }
            >
              화자 자동 지정
            </Button>
            {
              /*로컬스토리지에 자동 지정 일시 저장되어 있다면 노출*/
              speakerAutomaticallySetDate && (
                <StyleSpeakerAutomaticallySetDate>
                  화자 자동 지정: {speakerAutomaticallySetDate}
                </StyleSpeakerAutomaticallySetDate>
              )
            }
          </>
        )}
      </AsideInfoBox>

      {
        /* 화자 자동 지정 확인창 */
        openSetSpeakerAutomaticallyAlert && (
          <ModalAlert
            title="알림"
            content={
              speakerAutomaticallySetDate ? (
                <>
                  최근 {speakerAutomaticallySetDate}에 자동 지정을 했습니다.
                  <br /> 화자 자동 지정을 다시 하시겠습니까?
                </>
              ) : (
                <>화자 자동 지정을 하시겠습니까?</>
              )
            }
            isOpen={openSetSpeakerAutomaticallyAlert}
            onClose={handleCancelSetSpeakerAutomaticallyAlert}
            onConfirm={handleConfirmSetSpeakerAutomaticallyAlert}
          />
        )
      }
    </>
  );
};
export default React.memo(AsideSpeaker, (prev, next) => _.isEqual(prev, next));

/**
 * Speaker item
 * @param props
 * @constructor
 */
type SpeakerItemProps = {
  index: number;
  readOnly?: boolean;
  speakers: SpeakerInfo[];
  onSetSpeakerAutomaticallySetDate: (value: null) => void;
} & SpeakerInfo;

const SpeakerItem = React.memo(
  ({
    index,
    name,
    feature,
    speakers,
    onSetSpeakerAutomaticallySetDate,
    readOnly,
  }: SpeakerItemProps) => {
    const dispatch = useDispatch();
    const taskUid = useSelector(
      (rootState: RootState) => rootState.workspace.repositories.task!.uid,
    );
    const isUpdating = useSelector(
      (rootState: RootState) => rootState.workspace.editSpeakers.isSpeakersUpdating,
    );
    const isScriptUpdating = useSelector(
      (rootState: RootState) => rootState.workspace.editor.isScriptUpdating,
    );
    const errorSpeakersUpdate = useSelector(
      (rootState: RootState) => rootState.workspace.editSpeakers.errorSpeakersUpdate,
    );

    const [newName, setNewName] = useState(name);
    const [newFeature, setNewFeature] = useState(feature);
    const [editingName, setEditingName] = useState(false);
    const [editingFeature, setEditingFeature] = useState(false);

    /*** 모드 변경 ***/
    const handleChangeMode = useCallback(
      (event) => {
        if (readOnly) {
          return;
        }
        const target = event.target;
        const targetName = target.getAttribute('data-name');

        // 화자 정보 업데이트 중과 변경된 화자 정보에 맞춰 문단별 화자 정보 업데이트가 완료되어야 '화자 정보' 편집이 가능하다.
        if (isUpdating || isScriptUpdating) {
          return false;
        }

        switch (targetName) {
          case 'name':
            setEditingName(!editingName);
            break;
          case 'feature':
            setEditingFeature(!editingFeature);
            break;
        }
      },
      [editingName, editingFeature, isUpdating, isScriptUpdating, readOnly],
    );

    /*** 이름, 특징 변경 ***/
    const handleChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const targetName = target.name;

      switch (targetName) {
        case 'name':
          setNewName(event.target.value);
          break;
        case 'feature':
          setNewFeature(event.target.value);
          break;
      }
    }, []);

    /*** 입력 취소 ***/
    const handleCancelChange = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        const target = event.target;
        const targetName = _.get(target, 'name');

        if (event.key !== 'Escape') {
          return;
        }

        switch (targetName) {
          case 'name':
            setNewName(name);
            setEditingName(!editingName);
            break;
          case 'feature':
            setNewFeature(feature);
            setEditingFeature(!editingFeature);
            break;
        }
      },
      [editingName, name, editingFeature, feature],
    );

    /*** 화자 수정 ***/
    const handleChangeSpeaker = useCallback(async () => {
      const changeSpeaker = {
        name: newName,
        feature: newFeature,
      };

      // editor > speaks : 바뀐 화자 변경 요청
      const changeSpeakers = _.map(speakers, (prevSpeaker, speakerIndex) => {
        if (speakerIndex === index) {
          return changeSpeaker;
        }
        return prevSpeaker;
      });

      await dispatch(
        changeSpeakersInfo({
          taskUid,
          updateSpeakers: changeSpeakers,
          beforeName: name,
          afterName: changeSpeaker.name,
        }),
      );
    }, [dispatch, taskUid, speakers, index, name, newName, newFeature]);

    /*** 이름 저장: enter, 포커스 아웃 ***/
    const handleSubmitName = useCallback(
      (event: any) => {
        event.preventDefault();

        // 한 칸 이상의 공백은 한 칸으로 수정하여 저장. (/\s{2,}/g, " "); // 두 칸 이상의 공백은 한 칸으로
        const trimName = newName.replace(/\s\s*/g, ' ').replace(/(^\s*)|(\s*$)/g, '');
        setNewName(trimName);

        // 변경 전 이름과 같지 않으 면서, 다른 화자명 과 중복 된다면 저장 안됨.
        const equalName = _.find(speakers, { 'name': trimName });
        if (name !== trimName) {
          if (equalName) {
            toastr.error('화자 이름 중복', '화자 이름은 중복될 수 없습니다.');
            setNewName(name);

            // '화자' 라는 이름 입력시 - 사용 불가
          } else if ('화자' === trimName) {
            toastr.error('사용 불가', '사용할 수 없는 이름 입니다.');
            setNewName(name);

            // 빈 값은 입력할 수 없다.
          } else if (trimName.length === 0) {
            toastr.error('사용 불가', '화자를 입력해 주세요.');
            setNewName(name);
          } else {
            // 이름 저장
            handleChangeSpeaker();
          }
        }

        setEditingName(!editingName);
      },
      [speakers, newName, editingName, handleChangeSpeaker, name],
    );

    /*** 특징 저장: enter, 포커스 아웃 ***/
    const handleSubmitFeature = useCallback(
      (event: any) => {
        event.preventDefault();

        if (feature !== newFeature) {
          // 한 칸 이상의 공백은 한 칸으로 수정하여 저장.
          const trimFeature = newFeature.replace(/\s\s*/g, ' ').replace(/(^\s*)|(\s*$)/g, '');
          setNewFeature(trimFeature);
          handleChangeSpeaker();
        }

        setEditingFeature(!editingFeature);
      },
      [newFeature, editingFeature, feature, handleChangeSpeaker],
    );

    /*** 화자 삭제 ***/
    const handleDeleteSpeaker = useCallback(() => {
      const changeSpeakers = speakers.filter((speaker) => speaker.name !== name);

      // 화자 삭제시: 등록된 화자 수가 2명이 아니면, 저장했던 '화자 자동 지정' 날짜 삭제
      if (changeSpeakers.length !== 2) {
        onSetSpeakerAutomaticallySetDate(null);
        delCookie(`tasks.${taskUid}.speakerAutomaticallySetDate`);
      }

      dispatch(
        changeSpeakersInfo({
          taskUid,
          updateSpeakers: changeSpeakers,
          beforeName: name,
          afterName: '',
        }),
      );
    }, [dispatch, taskUid, name, speakers, onSetSpeakerAutomaticallySetDate]);

    /* changeSpeakersInfo 화자 정보 변경 실패하면 수정전 값으로 초기화. */
    useEffect(() => {
      if (errorSpeakersUpdate) {
        setNewName(name);
        setNewFeature(feature);
      }
    }, [errorSpeakersUpdate, name, feature]);

    return (
      <div className="list-speaker">
        <form onSubmit={handleSubmitName}>
          <dl className="item">
            <dt className="term">이름</dt>
            <dd
              className={classNames('description', { 'edit-area': readOnly ? false : !isUpdating })}
            >
              {!editingName ? (
                <div className="input-value edit-text" onClick={handleChangeMode} data-name="name">
                  {newName}
                </div>
              ) : (
                <input
                  type="text"
                  name="name"
                  className="input-text edit-input"
                  value={newName}
                  placeholder="화자 입력"
                  autoFocus={true}
                  onChange={handleChangeInput}
                  onKeyDown={handleCancelChange}
                  onBlur={handleSubmitName}
                  disabled={readOnly}
                />
              )}
            </dd>
          </dl>
        </form>
        <form onSubmit={handleSubmitFeature}>
          <dl className="item">
            <dt className="term">특징</dt>
            <dd
              className={classNames('description', { 'edit-area': readOnly ? false : !isUpdating })}
            >
              {!editingFeature ? (
                <div
                  className="input-value edit-text"
                  onClick={handleChangeMode}
                  data-name="feature"
                >
                  {newFeature || '특징 입력'}
                </div>
              ) : (
                <input
                  type="text"
                  name="feature"
                  className="input-text edit-input"
                  placeholder="특징 입력"
                  value={newFeature}
                  autoFocus={true}
                  onChange={handleChangeInput}
                  onKeyDown={handleCancelChange}
                  onBlur={handleSubmitFeature}
                  disabled={readOnly}
                />
              )}
            </dd>
          </dl>
          <button
            type="button"
            className="button-speaker-delete button-color2"
            disabled={readOnly || isUpdating || isScriptUpdating}
            onClick={handleDeleteSpeaker}
          >
            <span className="text-hidden">화자 삭제</span>
          </button>
        </form>
      </div>
    );
  },
  (prev, next) => _.isEqual(prev, next),
);
