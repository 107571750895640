import React from 'react';
import styled from "@emotion/styled";
import classNames from "classnames";

const Logo = (props: any) => {
  const { size, className } = props;
  return (
    <StyledOfLogo className={classNames('logo', {'small': size === 'small', [className as string]: className})}>
      <span className="text-hidden">typeX</span>
    </StyledOfLogo>
  );
};

export default Logo;

const StyledOfLogo = styled.strong`
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 40px;

  &.small {
    width: 70px;
    height: 25px;
  }
`