import * as momentAll from 'moment';
import moment from 'moment';
import durationFormatSetup from 'moment-duration-format';

durationFormatSetup(momentAll);

export const timeStamp = (
  seconds: number | string,
  format = 'HH:mm:ss.SSS',
  option: moment.DurationFormatSettings = {
    stopTrim: 'hh mm ss SSS',
    trim: false,
  },
): string => {
  const duration = moment.duration(seconds, 'seconds');
  return duration.format(format, option);
};

export const toNum = (
  num: string | number | undefined | null,
  prefix: string = '',
  postfix: string = '',
): string => {
  if (num === null || num === undefined) {
    return '-';
  }

  let ret = num;

  if (typeof num === 'string') {
    ret = parseInt(num);
  }

  return `${prefix}${ret.toLocaleString()}${postfix}`;
};

export const toInt = (num: string | number): number => {
  if (typeof num === 'string') {
    return parseInt(num.replace(/,/g, ''));
  } else {
    return Math.round(num);
  }
};

export const toDateTime = (
  text: string | moment.Moment | Date | null | undefined,
  format = 'YYYY-MM-DD HH:mm:ss',
): string => {
  if (text === null || text === undefined) {
    return '-';
  }

  if (moment.isMoment(text)) {
    return text.format(format);
  }

  try {
    return moment(text).format(format);
  } catch (e) {
    return '-';
  }
};

export const toDate = (
  text: string | moment.Moment | Date | null | undefined,
  format = 'YYYY-MM-DD',
): string => {
  return toDateTime(text, format);
};

export const toTime = (text: string | moment.Moment | Date, format = 'HH:mm:ss'): string => {
  return toDateTime(text, format);
};

export function toSqlDateTime(plain: moment.MomentInput) {
  return moment(plain).format('YYYY-MM-DD HH:mm:ss');
}

export function diffTime(t1: string | number, t2?: string | number) {
  const A = moment(t1).format('YYYY-MM-DD HH:mm:ss');
  const now = moment().format('YYYY-MM-DD HH:mm:ss');
  let B = t2;
  if (!B) {
    B = now;
  }

  const A2 = moment(A, 'YYYY-MM-DD HH:mm:ss');
  const B2 = moment(B, 'YYYY-MM-DD HH:mm:ss');

  return {
    day: moment.duration(B2.diff(A2)).days(),
    hour: moment.duration(B2.diff(A2)).hours(),
    minute: moment.duration(B2.diff(A2)).minutes(),
    second: moment.duration(B2.diff(A2)).seconds(),
  };
}

// 시간 찍어보기 위해 임시로 넣음. 나중에 지울 예정
export function nowTime() {
  return moment().format('YYYY-MM-DD HH:mm:ss');
}
