import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FindReplaceItem, FindReplaceState } from './types';
import { range } from 'lodash';
import { BaseEditor, Node } from 'slate';
import { ReactEditor, WindowEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import { findContextWords, replaceInEditor } from '../../utils/findReplace';

const initialState: FindReplaceState = {
  recentWords: {
    wordFinds: [],
    wordReplaces: [],
  },
  isOpen: false,
  isFinding: false,
  isReplacing: false,
  wordFind: '',
  wordReplace: '',
  results: [],
  noSearchResults: null,
  checkedIndexes: [],
};

export const findReplaceSlice = createSlice({
  name: 'FIND-REPLACE',
  initialState,
  reducers: {
    resetFindReplace(state) {
      state.recentWords = initialState.recentWords;
      state.isOpen = initialState.isOpen;
      state.isFinding = initialState.isFinding;
      state.isReplacing = initialState.isReplacing;
      state.wordFind = initialState.wordFind;
      state.wordReplace = initialState.wordReplace;
      state.results = initialState.results;
      state.noSearchResults = initialState.noSearchResults;
      state.checkedIndexes = initialState.checkedIndexes;
    },
    openFindReplace(state) {
      state.isOpen = true;
    },
    closeFindReplace(state) {
      state.isOpen = false;
      state.wordFind = '';
      state.wordReplace = '';
      state.results = [];
      state.noSearchResults = null;
      state.checkedIndexes = [];
      state.isScriptEdited = false;
    },
    changeWordFind(state, { payload }: PayloadAction<FindReplaceState['wordFind']>) {
      state.wordFind = payload;
    },
    changeWordReplace(state, { payload }: PayloadAction<FindReplaceState['wordReplace']>) {
      state.wordReplace = payload;
    },
    startSeeking(state, { payload }: PayloadAction<number | undefined>) {
      state.seekToIndex = payload;
    },
    /* EditorBox.tsx */
    finishSeeking(state) {
      state.seekToIndex = undefined;
    },
    changeChecked(state, { payload }: PayloadAction<null | number>) {
      if (payload === null) {
        if (state.checkedIndexes.length === state.results.length) {
          state.checkedIndexes = [];
        } else {
          state.checkedIndexes = range(0, state.results.length);
        }
      } else {
        const newChecked = [...state.checkedIndexes];
        const currentIndex = state.checkedIndexes.indexOf(payload);
        if (currentIndex === -1) {
          newChecked.push(payload);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        state.checkedIndexes = newChecked;
      }
    },
    startFinding(state) {
      state.isFinding = true;
      state.recentWords.wordFinds.unshift(state.wordFind);
    },
    /* EditorBox.tsx */
    find(
      state,
      {
        payload: { editor },
      }: PayloadAction<{ editor: BaseEditor & ReactEditor & WindowEditor & HistoryEditor }>,
    ) {
      // 특수 문자를 포함 문자로 해석 (\도 하나만 적으면 효과 없음)
      const escapeCharacter = '^$|.*()+[?\\';
      const wordFind = state.wordFind.split('').map((character) => {
        if (escapeCharacter.indexOf(character) > -1) {
          return `\\${character}`;
        } else {
          return character;
        }
      });

      const rep = new RegExp(wordFind.join(''), 'ig');
      const paragraphs = editor.children;
      const results: FindReplaceState['results'] = [];
      paragraphs.forEach((paragraph, paraIndex) => {
        const text = Node.string(paragraph);

        // wordOffset 은 text.replace(rep, replacer); 실행을 통해 rep에 일치하는 문자열의 index.
        // - 조사된 전체 문자열 중에서 매치된 문자열의 index.(예를 들어, 조사될 전체 문자열이 abcd이고, 매치된 문자열이 bc면 이 매개변수의 값은 1이 됩니다.)
        function replacer(match: string, wordOffset: number): string {
          results.push({
            offset: [paraIndex, wordOffset],
            wordFind: match,
            context: findContextWords(text, wordOffset, wordOffset + match.length),
          });
          return match;
        }

        // var newStr = str.replace(regexp|substr, newSubstr|function)
        text.replace(rep, replacer);
      });
      state.results = results;
      state.noSearchResults = results.length === 0;
      state.checkedIndexes = range(0, results.length);
      state.isFinding = false;
    },
    startReplacing(state) {
      state.isReplacing = true;
    },
    /* EditorBox.tsx */
    replace(
      state,
      {
        payload: { editor },
      }: PayloadAction<{ editor: BaseEditor & ReactEditor & WindowEditor & HistoryEditor }>,
    ) {
      const changes = state.checkedIndexes.map((i) => {
        return {
          ...state.results[i],
          wordReplace: state.wordReplace,
        } as FindReplaceItem;
      });
      replaceInEditor(changes, editor);

      state.isReplacing = false;
      // 변경후 검색 결과 초기화
      state.wordFind = '';
      state.wordReplace = '';
      state.results = [];
      state.noSearchResults = null;
      state.checkedIndexes = [];
    },
    /*
      - "찾기 바꾸기" 에서 '검색'한 이후에 에디터 본문에서 직접 편집(바꾸기가 아닌)이 이뤄지면 editedScript 에 true 할당
      - "찾기 바꾸기" 에서 '검색'을 실행하면, editedScript 에 false 값을 할당
    *  */
    editedScript(state, { payload }: PayloadAction<boolean>) {
      state.isScriptEdited = payload;
    },
  },
});

export default findReplaceSlice.reducer;

export const {
  resetFindReplace,
  openFindReplace,
  changeWordFind,
  changeChecked,
  changeWordReplace,
  closeFindReplace,
  find,
  replace,
  startFinding,
  startReplacing,
  startSeeking,
  finishSeeking,
  editedScript,
} = findReplaceSlice.actions;
