import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MuiCircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import React from 'react';

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    bottom: {
      color: '#eee !important',
    },
    top: {
      color: '#1a90ff !important',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }),
);

const LoadingProgress = (props: CircularProgressProps) => {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <MuiCircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <MuiCircularProgress
        variant="indeterminate"
        disableShrink={true}
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
};

export default LoadingProgress;
