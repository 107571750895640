import React from 'react';
import { Row as AntdRow, RowProps } from 'antd';
import classNames from 'classnames';

const Row = (props: RowProps) => {
  const { children, className, ...rest } = props;
  return (
    <AntdRow {...rest} className={classNames(`row`, { [className as string]: className })}>
      {children}
    </AntdRow>
  );
};

export default Row;
