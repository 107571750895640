import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isWindows } from 'react-device-detect';
import classNames from 'classnames';
import { SettingOutlined } from '@ant-design/icons';
import Button from '../../components/Button';
import styled from '@emotion/styled/dist/emotion-styled.cjs';
import ModalAlert from './ModalAlert';
import { config } from '../../config';
import { ConfigProvider, Select, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import _ from 'lodash';
import { ShortcutEvents, ShortcutKeyObj, ShortcutKeys } from './types';
import { arrowKeysSymbol } from '../../config/shortcutKey';
import { toastr } from 'react-redux-toastr';
import { setShortcutKey, setShortcutKeys } from './shortcutKeysSlice';

interface PropsState {
  showState: boolean;
  windSeconds: number;
  onShowHotkey: () => void;
  onChangeViewMode: (value: boolean) => void;
}

const ModalHotkey = ({ showState, windSeconds, onShowHotkey, onChangeViewMode }: PropsState) => {
  const dispatch = useDispatch();
  const { shortcutKeys } = useSelector(
    (rootState: RootState) => rootState.workspace.shortcutKeyList,
  );

  // 단축키 설정을 변경하였는지 확인
  const [isChange, setIsChange] = useState(false);

  // 보기모드/편집모드
  const [isViewMode, setIsViewMode] = useState(true);

  // 모드에 따라 속성을 추가하거나 뺀다.(예: 보기모드에서만 더블 클릭시 창 닫히는 이벤트 실행)
  const [addProperty, setAddProperty] = useState({});

  // 편집모드에서 단축키 설정할 수 있는 속성
  const [shortcutProperty, setShortcutProperty] = useState({});

  const [isOpenModalSetShortcutKey, setIsOpenModalSetShortcutKey] = useState(false); // '단축키 설정' 모달창 노출
  const [editingShortcutKey, setEditingShortcutKey] = useState<
    undefined | { name: string; title: string; key: string[] }
  >(); // '단축키 설정' - 편집할 단축키

  const [isOpenAlertCancelChanges, setIsOpenAlertCancelChanges] = useState(false); // '변경내용 취소' 확인창 노출

  // 단축키 설정 - 기본키
  const [primaryKey, setPrimaryKey] = useState('');

  // 단축키 설정 - 조합키(보조키)
  const [secondaryKey, setSecondaryKey] = useState('');

  // 임시저장된 단축키 - 중복으로 등록된 단축키 정보
  const [duplicateKeys, setDuplicateKeys] = useState<undefined | ShortcutKeyObj>();

  // 기본키 - 기본 옵션값
  const primaryKeyOptions = useMemo(() => {
    return config.shortcutKey.primaryKeyDefaultOptions;
  }, []);

  // 조합키 - 기본 옵션값
  const secondaryKeyOptions = useMemo(() => {
    return config.shortcutKey.secondaryKeyDefaultOptions;
  }, []);

  // [팝업] 단축키 설정 - 기본키 셀렉트 박스에서 키입력하여 검색
  const selectSearch = useCallback((value) => value, []);

  // 편집모드로 변경
  const handleChangeMode = useCallback(() => {
    setIsViewMode(false);
  }, []);

  // [팝업]단축키 안내 - 저장 - 단축키 변경/보기모드로 변경
  const handleSaveSetting = useCallback(() => {
    if (!shortcutKeys) {
      return;
    }

    // 1. 임시저장된 단축키 목록을 "단축키"를 key로 "기능이름"을 value로 정리 - 중복된 단축키는 같은 key 의 value 가됨 (같은 단축키끼리 정리)
    const groupingShortcuts: ShortcutKeyObj = {};
    const duplicateValues: ShortcutKeyObj = {};
    _.forEach(shortcutKeys, (value: string[], name: string) => {
      const keyName = value.join('+');
      if (groupingShortcuts[keyName]) {
        duplicateValues[keyName] = groupingShortcuts[keyName];
        groupingShortcuts[keyName].push(name);
      } else {
        groupingShortcuts[keyName] = [name];
      }
    });

    if (Object.keys(duplicateValues).length > 0) {
      // 중복 등록된 단축키가 있다.
      setDuplicateKeys(duplicateValues);
    } else {
      // 중복 등록된 단축키가 없다.
      setIsViewMode(true); // 편집모드 종료

      // 스토어에 저장된 단축키를 로컬 스토리지에 저장.
      localStorage.setItem('shortcutKey', JSON.stringify(shortcutKeys));

      toastr.success('저장되었습니다.', '');
    }
  }, [shortcutKeys, isChange]);

  // [팝업]단축키 안내 - 취소
  const handleCancelSetting = useCallback(() => {
    if (isChange) {
      // 단축키 설정을 변경 내역을 저장할지 확인창 노출
      setIsOpenAlertCancelChanges(true);
    } else {
      // 편집모드 종료
      setIsViewMode(true);
    }
  }, [isChange]);

  // [팝업] 변경내용 취소 확인창 - 예
  const handleConfirmAlertCancelChanges = useCallback(() => {
    // 변경내용으로 저장하지 않음 - 로컬스토리지 내용으로 스토어에 저장된 단축키 덮어쓰기
    const shortcutKeysValue = localStorage.getItem('shortcutKey');
    if (shortcutKeysValue) {
      dispatch(setShortcutKeys(JSON.parse(shortcutKeysValue)));
    }

    // 편집모드 종료
    setIsViewMode(true);
    setIsOpenAlertCancelChanges(false);
  }, []);

  // [팝업] 변경내용 취소 확인창 - 아니오
  const handleCancelAlertCancelChanges = useCallback(() => {
    setIsOpenAlertCancelChanges(false);
  }, []);

  // [팝업] 중복 단축키 확인창 - '확인'
  const handleCloseAlertDuplicateKeys = useCallback(() => {
    setDuplicateKeys(undefined);
  }, []);

  // [팝업] 모든 단축키 초기화 확인 - 확인창 노출.
  const handleOpenAlertResetAllShortcutKeys = useCallback(() => {
    dispatch(setShortcutKeys(config.shortcutKey.key));
    setIsChange(true);
  }, []);

  // [팝업] 단축키 설정 모달 - 팝업 노출.
  const handleOpenModalSetShortcutKey = useCallback(
    (event) => {
      const { currentTarget } = event;
      const { shortcut } = currentTarget.dataset;
      const keyTitle = getShortcutKeyTitle(shortcut, { windSeconds });

      setEditingShortcutKey({
        name: shortcut,
        title: keyTitle,
        key: config.shortcutKey.key[shortcut],
      });

      if (shortcutKeys) {
        const { primary, secondary } = getShortcutKeysValue(shortcutKeys[shortcut as string]);
        setPrimaryKey(primary);
        setSecondaryKey(secondary);
      }

      setIsOpenModalSetShortcutKey(true);
    },
    [windSeconds, shortcutKeys],
  );

  // [팝업] 단축키 설정 모달 > 확인 - 단축키 임시 저장.
  const handleConfirmModalSetShortcutKey = useCallback(() => {
    if (!editingShortcutKey) {
      return;
    }

    const keyName: string = editingShortcutKey.name;
    const keyValue = [primaryKey];
    if (secondaryKey) {
      switch (secondaryKey) {
        case ShortcutKeys.CTRL:
        case ShortcutKeys.SHIFT:
          keyValue[1] = secondaryKey;
          break;
        case ShortcutKeys.CTRL_SHIFT:
          keyValue[1] = ShortcutKeys.SHIFT;
          keyValue[2] = ShortcutKeys.CTRL;
          break;
      }
    }

    // 스토어에 저장
    dispatch(setShortcutKey({ name: keyName, key: keyValue }));

    setEditingShortcutKey(undefined);
    setIsOpenModalSetShortcutKey(false);
    setIsChange(true);
  }, [dispatch, shortcutKeys, primaryKey, secondaryKey, editingShortcutKey]);

  // [팝업] 단축키 설정 모달 > 취소 - 팝업 닫힘.
  const handleCancelModalSetShortcutKey = useCallback(() => {
    setEditingShortcutKey(undefined);
    setIsOpenModalSetShortcutKey(false);
  }, []);

  // [팝업] 단축키 설정 모달 - 기본키 변경
  const handleChangePrimaryKey = useCallback((optionValue) => {
    setPrimaryKey(optionValue);
  }, []);

  // [팝업] 단축키 설정 모달 - 조합키(보조) 변경
  const handleChangeSecondaryKey = useCallback((optionValue) => {
    setSecondaryKey(optionValue);
  }, []);

  // [팝업] 단축키 설정 모달 - 해당 단축키만 초기화
  const handleOpenAlertResetShortcutKeys = useCallback(() => {
    if (editingShortcutKey?.key) {
      const { primary, secondary } = getShortcutKeysValue(editingShortcutKey?.key);
      setPrimaryKey(primary);
      setSecondaryKey(secondary);
    }
  }, [editingShortcutKey]);

  // 모드에 따라 속성을 추가하거나 뺀다.(예: 보기모드에서만 더블 클릭시 창 닫히는 이벤트 실행)
  useEffect(() => {
    if (isViewMode) {
      setAddProperty({ onDoubleClick: onShowHotkey });
      setShortcutProperty({});
    } else {
      setAddProperty({});
      setShortcutProperty({ onClick: handleOpenModalSetShortcutKey });
    }
  }, [isViewMode, handleOpenModalSetShortcutKey]);

  // 단축키안내에서 모드 변경시 부모 컴포넌트도 알 수 있게 전달
  useEffect(() => {
    onChangeViewMode(isViewMode);
  }, [onChangeViewMode, isViewMode]);

  if (!shortcutKeys) {
    return null;
  }
  // 특정 키 입력(keystroke)을 전달
  return (
    <>
      <section
        className={classNames('section-hotkey', {
          'is-windows': isWindows,
          'view-mode': isViewMode,
          'edit-mode': !isViewMode,
        })}
        data-show={showState}
        {...addProperty}
      >
        <div className="inner">
          <h1 className="title">단축키 {isViewMode ? '안내' : '편집'}</h1>

          {isViewMode && (
            <button type="button" className="button-modal-hotkey-close" onClick={onShowHotkey}>
              <span className="text-hidden">단축키 안내 닫기</span>
            </button>
          )}

          {!isViewMode && (
            <StyledResetButton
              type="primary"
              ghost={true}
              shape="round"
              onClick={handleOpenAlertResetAllShortcutKeys}
            >
              설정 초기화
            </StyledResetButton>
          )}

          <StyledSettingButtons>
            {isViewMode ? (
              <>
                <Button
                  icon={<SettingOutlined />}
                  className="btn btn-setting"
                  onClick={handleChangeMode}
                  title={'단축키 설정'}
                />
              </>
            ) : (
              <>
                <Button shape="round" onClick={handleCancelSetting}>
                  취소
                </Button>
                <Button shape="round" type="primary" onClick={handleSaveSetting}>
                  저장
                </Button>
              </>
            )}
          </StyledSettingButtons>
          <div className="wrap">
            <section className="wrap-keystroke-audio">
              <h2 className="stitle">오디오 조작</h2>
              <div className="wrap-shortcut-item">
                <ShortcutItem
                  name={ShortcutEvents.PLAY_PAUSE}
                  shortcutProperty={shortcutProperty}
                />
                <ShortcutItem
                  name={ShortcutEvents.PLAY_CURRENT_LOCATION}
                  shortcutProperty={shortcutProperty}
                />
                <ShortcutItem
                  name={ShortcutEvents.REWIND}
                  shortcutProperty={shortcutProperty}
                  windSeconds={windSeconds}
                />
                <ShortcutItem
                  name={ShortcutEvents.FAST_FORWARD}
                  shortcutProperty={shortcutProperty}
                  windSeconds={windSeconds}
                />
              </div>
              <div className="wrap-shortcut-item">
                <ShortcutItem name={ShortcutEvents.SPEED_UP} shortcutProperty={shortcutProperty} />
                <ShortcutItem
                  name={ShortcutEvents.SPEED_DOWN}
                  shortcutProperty={shortcutProperty}
                />
                <ShortcutItem
                  name={ShortcutEvents.SPEED_RESET}
                  shortcutProperty={shortcutProperty}
                />
                <ShortcutItem name={'empty'} />
              </div>
            </section>

            <section className="wrap-keystroke-text">
              <h2 className="stitle">텍스트 편집</h2>
              <div className="wrap-shortcut-item">
                <ShortcutItem name={ShortcutEvents.SAVE} fixed={true} />
                <ShortcutItem name={ShortcutEvents.UNDO} fixed={true} />
                <ShortcutItem
                  name={ShortcutEvents.REDO}
                  fixed={true}
                  addClass={'wrap-add-keystroke'}
                />
              </div>
            </section>

            <section className="wrap-keystroke-etc">
              <h2 className="stitle">기타</h2>
              <div className="wrap-shortcut-item">
                <ShortcutItem
                  name={ShortcutEvents.FIND_REPLACE}
                  shortcutProperty={shortcutProperty}
                />
                <ShortcutItem
                  name={ShortcutEvents.SPELL_CHECK}
                  shortcutProperty={shortcutProperty}
                />
                <ShortcutItem
                  name={ShortcutEvents.SHORTCUT_KEY}
                  shortcutProperty={shortcutProperty}
                />
              </div>
            </section>
          </div>
        </div>
      </section>

      {isOpenAlertCancelChanges && (
        <ModalAlert
          minWidth="sm"
          title="취소하시겠습니까?"
          content={<>변경한 내역이 취소됩니다.</>}
          isOpen={true}
          textCancel="아니오"
          textConfirm="예"
          onClose={handleCancelAlertCancelChanges}
          onConfirm={handleConfirmAlertCancelChanges}
        />
      )}

      {duplicateKeys && (
        <ModalAlert
          minWidth="sm"
          title="중복된 단축키 설정이 존재합니다."
          content={
            <StyledListDuplicate>
              {_.map(duplicateKeys, (names, keyCode) => {
                const keyValue = shortcutsSymbols(keyCode.split('+')).join(' + ');
                const nameValue = names
                  .map((name) => getShortcutKeyTitle(name, { windSeconds }))
                  .join(', ');
                return (
                  <li key={keyValue}>
                    <Tag>{keyValue}</Tag>
                    <span>{nameValue}</span>
                  </li>
                );
              })}
            </StyledListDuplicate>
          }
          isOpen={true}
          onConfirm={handleCloseAlertDuplicateKeys}
        />
      )}

      {isOpenModalSetShortcutKey && editingShortcutKey && (
        <ModalAlert
          maxWidth="sm"
          title="단축키 설정"
          content={
            <StyledModalSetShortcutKey>
              <p>※OS나 브라우저에서 이미 사용 중인 단축키로 등록 시 작동이 불가능할 수 있습니다.</p>
              <dl>
                <dt>
                  <strong>{editingShortcutKey.title}</strong>
                  <Button
                    type="primary"
                    ghost={true}
                    shape="round"
                    size="small"
                    onClick={handleOpenAlertResetShortcutKeys}
                  >
                    설정 초기화
                  </Button>
                </dt>
                <dd>
                  <div className="wrap">
                    <ConfigProvider
                      theme={{
                        token: {
                          zIndexPopupBase: 10000,
                        },
                      }}
                    >
                      <Select
                        value={secondaryKey}
                        style={{ width: 120 }}
                        onChange={handleChangeSecondaryKey}
                        options={secondaryKeyOptions}
                        showSearch={true}
                        onSearch={selectSearch}
                      />

                      <Select
                        value={primaryKey}
                        style={{ width: 120 }}
                        onChange={handleChangePrimaryKey}
                        options={primaryKeyOptions}
                        showSearch={true}
                        onSearch={selectSearch}
                      />
                    </ConfigProvider>
                  </div>
                </dd>
              </dl>
            </StyledModalSetShortcutKey>
          }
          isOpen={true}
          textConfirm="확인"
          onClose={handleCancelModalSetShortcutKey}
          onConfirm={handleConfirmModalSetShortcutKey}
        />
      )}
    </>
  );
};

export default React.memo(ModalHotkey);

// 단축키 티이틀
export const getShortcutKeyTitle = (name: string, option?: { windSeconds?: number }) => {
  let keyTitle = config.shortcutKey.title[name];
  if ((name === 'rewind' || name === 'fastForward') && option?.windSeconds) {
    keyTitle = option?.windSeconds + keyTitle;
  }
  return keyTitle;
};

// 단축키를 기본키/조합키(보조키) 값으로 각각 구분
const getShortcutKeysValue = (keys: string[]) => {
  const [key1, key2, key3] = keys;
  let secondaryKey = 'none';
  // key2, key3 - 조합키가 2개인 경우 (ctrl && shift)
  if (key3) {
    secondaryKey = ShortcutKeys.CTRL_SHIFT;
  } else if (key2) {
    // key2 - 조합키가 1개인 경우 (ctrl || shift)
    secondaryKey = key2;
  }

  return {
    primary: key1,
    secondary: secondaryKey,
  };
};

// 등록된 단축키 출력
const ShortcutItem = React.memo(
  ({
    name,
    shortcutProperty = {},
    fixed,
    addClass,
    windSeconds,
  }: {
    name: string;
    shortcutProperty?: {};
    fixed?: boolean;
    addClass?: string;
    windSeconds?: number;
  }) => {
    const { shortcutKeys } = useSelector(
      (rootState: RootState) => rootState.workspace.shortcutKeyList,
    );
    if (!shortcutKeys) {
      return null;
    }

    let title = 'empty';
    if (windSeconds) {
      title = getShortcutKeyTitle(name, { windSeconds });
    } else if (name !== title) {
      title = getShortcutKeyTitle(name);
    }

    if (name !== 'empty' && !shortcutKeys[name]) {
      return null;
    }
    const keys = shortcutsSymbols(shortcutKeys[name]);

    return (
      <dl
        className={classNames('shortcut-item', { fixed })}
        {...shortcutProperty}
        data-shortcut={name}
      >
        <dt className="term">{name !== 'empty' && title}</dt>
        <dd className={classNames('description', { [addClass as string]: addClass })}>
          <kbd>
            {name === 'empty' && <>&nbsp;</>}
            {name !== 'empty' &&
              keys.length > 0 &&
              keys.map((keyCode) => {
                return (
                  <kbd key={`${name}_${keyCode}`} className="keystroke">
                    {keyCode}
                  </kbd>
                );
              })}
          </kbd>
          {isWindows && name === 'redo' && (
            <div className="add-description">
              <span>또는</span>
              <kbd>
                <kbd className="keystroke">Ctrl</kbd>
                <kbd className="keystroke">Y</kbd>
              </kbd>
            </div>
          )}
        </dd>
      </dl>
    );
  },
);

// 화면으로 보이는 단축키 기호(툴팁, 단축키 안내)
export const shortcutsSymbols = (keyCodeValues: string[]) => {
  if (!keyCodeValues) {
    return [];
  }

  return [...keyCodeValues].reverse().map((keyCode) => {
    let ctrlOrCommand = null;

    // ios 에서는 ctrl === cmd
    if (keyCode === ShortcutKeys.CTRL) {
      ctrlOrCommand = isWindows ? ShortcutKeys.CTRL : 'Cmd';
    }

    const isArrowKey = _.includes(
      [...Object.values(ShortcutKeys).filter((item) => item.indexOf('Arrow') > -1)],
      keyCode,
    );

    if (isArrowKey) {
      // 방향키
      keyCode = arrowKeysSymbol(keyCode);
    } else if (!_.includes(['Tab', ShortcutKeys.SHIFT, ShortcutKeys.CTRL], keyCode)) {
      // 일부 키는 대문자로 변환하지 않는다.
      keyCode = keyCode.toUpperCase();
    }
    return ctrlOrCommand || keyCode;
  });
};

// [팝업] 단축키 안내 - 설정 초기화
const StyledResetButton = styled(Button)((props) => {
  return `
  position: absolute;
  right: 20px;
  top: 20px;
  `;
});

// [팝업] 단축키 안내 - 보기/편집 모드 버튼
const StyledSettingButtons = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;

  > button {
    &:not(:nth-of-type(1)) {
      margin-left: 10px;
    }
  }

  .btn-setting {
    // 오른쪽 여백 20 (32 - 20 / 2)
    margin-right: calc(((32px - 20px) / -2));
    margin-bottom: calc(((32px - 20px) / -2));
    border-color: transparent !important;
    background: transparent;

    .anticon {
      transform: none;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
    }

    &:hover {
      svg {
        fill: #4096ff;
      }
    }
  }
`;

// [팝업] 단축키 설정
const StyledModalSetShortcutKey = styled.div`
  position: relative;

  > p {
    margin-bottom: 10px;
    color: #ff4d4f;
    line-height: 12px;
  }

  dt {
    strong {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
    }

    button {
      margin-left: 10px;
    }
  }

  dd {
    padding-top: 10px;

    .wrap > div:not(:nth-of-type(1)) {
      margin-left: 10px;
    }
  }
`;
// [팝업] 중복된 단축키 확인 - 중복 단축키 목록
const StyledListDuplicate = styled.ul`
  margin-top: 10px;

  > li {
    display: flex;

    &:not(:nth-of-type(1)) {
      margin-top: 10px;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 3px;
      border-radius: 3px;
      margin-right: 5px;
      background: rgba(0, 0, 0, 0.88);
      vertical-align: top;
      transform: translateY(10px);
    }

    span {
      align-self: start;
      overflow-wrap: break-word;
      word-break: keep-all;
    }
  }
`;
