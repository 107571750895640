import React from 'react';
import { Button as AntdButton, ButtonProps } from 'antd';
import styled from '@emotion/styled';
import classNames from 'classnames';

interface InternalButtonProps extends ButtonProps {
  width?: 'fill';
}

function InternalButton(props: InternalButtonProps, ref: any) {
  const { loading, className, children, ...rest } = props;
  return (
    <StyledButton
      {...rest}
      ref={ref}
      className={classNames(`button`, { [className as string]: className })}
    >
      {children}
    </StyledButton>
  );
}

const Button = React.forwardRef(InternalButton);
export default Button;

const StyledButton = styled(AntdButton)((props) => {
  const {
    width: propsWidth,
    size,
    disabled,
  } = props as { width?: 'fill'; size?: ButtonProps['size']; disabled?: ButtonProps['disabled'] };
  // 너비
  let width = {};
  if (propsWidth === 'fill') {
    width = {
      width: '100% !important',
    };
  }

  // 사이즈에 따른 height, 폰트 사이즈
  let fontSize = {};
  if (size === 'small') {
    fontSize = {
      fontSize: '11px !important',
    };
  }

  let backgroundColor = {};
  if (disabled) {
    backgroundColor = {
      backgroundColor: "#c4c4c4 !important"
    };
  }

  return {
    ...width,
    ...fontSize,
    ...backgroundColor,
  };
});
