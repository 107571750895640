import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MaterialTooltip, { TooltipProps } from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Tooltip = React.memo(
  (props: TooltipProps) => {
    const classes = useStylesBootstrap();
    return (
      <MaterialTooltip arrow={true} classes={classes} {...props}>
        {props.children}
      </MaterialTooltip>
    );
  },
  (prev: any, next: any) => prev === next,
);
export default Tooltip;
