import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import reportWebVitals from './reportWebVitals';
import store, { history } from './app/store';
import ReduxToastr from 'react-redux-toastr';

import './assets/css/reset.css';
import 'antd/dist/reset.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { version } from '../package.json';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';


const NODE_ENV = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;
const REACT_APP_SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
const REACT_APP_SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
// process.env.NODE_ENV 이 development 와 production 인 경우에만 sentry.init()
if (
  REACT_APP_SENTRY_DNS &&
  REACT_APP_SENTRY_RELEASE &&
  (NODE_ENV === 'development' || NODE_ENV === 'production')
) {
  const options: any = {
    dsn: REACT_APP_SENTRY_DNS,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: version + '_' + REACT_APP_SENTRY_RELEASE,
  };
  if (NODE_ENV === 'development') {
    options.environment = NODE_ENV;
  }
  Sentry.init(options);
}

function render() {
  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>

          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates={true}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar={false}
            closeOnToastrClick={false}
          />
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

render();

if (NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { productItems } from "./utils/variables";