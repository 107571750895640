import React from 'react';
import classNames from 'classnames';

const AsideInfoBox = (props: any) => {
  const { title, children, direction, className } = props;

  let addClass = '';
  if (className) {
    addClass = 'aside-' + className;
  }

  return (
    <section className={classNames('box-aside', addClass)} data-direction={direction || 'column'}>
      <h1 className="title">{title || ''}</h1>
      <div className="content">{children}</div>
    </section>
  );
};

export default AsideInfoBox;
