import styled from "@emotion/styled";

/* 순서 없는 목록 */
export const StyledOfUnorderedList = styled.ul((props) => {
  const { color } = props as { color?: string };

  return `
  margin: 0;
  padding: 0;
  
  li {
    &:not(:first-of-type) {
      margin-top: 5px;
    }
    
    padding-left: 7px;
    color: ${color ? color : 'inherit'};
    line-height: 1.3;
    
    &:before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 3px;
      margin-left: -7px;
      margin-right: 4px;
      background: ${color ? color : '#333'};
      vertical-align: middle;
      transform: translateY(-50%);
    }
  }`;
});
