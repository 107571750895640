import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import api from '../../api';
import { AxiosResponse } from 'axios';
import { Error } from '../../api/apiClient';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { pushTaskSpeakers } from './speakersSlice';
import { TaskScriptHistory } from './types';
import { pushTaskScript } from './editorBoxSlice';
import { Task } from "../../models/taskTypes";
import { ErrorCode } from "../../models";

export interface TypeScriptHistoryState {
  isFetching: boolean;
  error: string | null;
  taskScriptHistories?: TaskScriptHistory[];
  selectedScript?: TaskScriptHistory;
  restCount: number;
  lastId?: number;
}

const initialState: TypeScriptHistoryState = {
  isFetching: false,
  error: null,
  restCount: 0,
};

export const scriptHistorySlice = createSlice({
  name: 'WORKSPACE',
  initialState,
  reducers: {
    resetTaskScriptHistories(state) {
      state.isFetching = initialState.isFetching;
      state.error = initialState.error;
      state.restCount = initialState.restCount;
      state.taskScriptHistories = undefined;
      state.selectedScript = undefined;
      state.lastId = undefined;
    },
    startTaskScriptHistoriesFetch(state) {
      state.isFetching = true;
      state.error = null;
    },
    successTaskScriptHistoriesFetch(
      state,
      {
        payload: { taskScriptHistories, restCount },
      }: PayloadAction<{
        taskScriptHistories: TypeScriptHistoryState['taskScriptHistories'];
        restCount: TypeScriptHistoryState['restCount'];
      }>,
    ) {
      const list = _.sortBy(taskScriptHistories, 'id').reverse();
      state.isFetching = false;
      state.taskScriptHistories = list;
      state.lastId = list.length > 0 ? list[list.length - 1].id : undefined;
      state.restCount = restCount || 0;
    },
    failTaskScriptHistoriesFetch(state, { payload }: PayloadAction<any>) {
      state.isFetching = false;
      state.error = payload;
    },

    startTaskScriptHistoryFetch(state) {
      state.isFetching = true;
      state.error = null;
    },
    successTaskScriptHistoryFetch(state, { payload }: PayloadAction<TaskScriptHistory>) {
      state.selectedScript = payload;
      state.isFetching = false;
    },
    failTaskScriptHistoryFetch(state, { payload }: PayloadAction<any>) {
      state.isFetching = false;
      state.error = payload;
    },
  },
});

export default scriptHistorySlice.reducer;

export const {
  resetTaskScriptHistories,
  startTaskScriptHistoriesFetch,
  successTaskScriptHistoriesFetch,
  failTaskScriptHistoriesFetch,
  startTaskScriptHistoryFetch,
  successTaskScriptHistoryFetch,
  failTaskScriptHistoryFetch,
} = scriptHistorySlice.actions;

export const pullTaskHistories =
  (taskUid: Task['uid'], lastId?: number): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(startTaskScriptHistoriesFetch());
      const perPage = 10;
      const { data, meta } = await api.getTaskScriptHistories(
        taskUid,
        perPage,
        lastId,
      );
      const { restCount } = meta as { restCount: number };
      const stateData = getState().workspace.scriptHistory.taskScriptHistories || [];
      const allHistories = _.uniqBy([...stateData, ...data], 'id');

      dispatch(successTaskScriptHistoriesFetch({ taskScriptHistories: allHistories, restCount }));
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error('pullTaskHistories ', err);

      const errorData = (err as AxiosResponse<Error>).data || {};
      let message = _.get(errorData, 'message');
      const errorCode = _.get(errorData, 'code');

      dispatch(failTaskScriptHistoriesFetch(errorData));

      switch (errorCode) {
        case ErrorCode.VALIDATION: {
          message = '잘못된 요청입니다.\n 관리자에게 문의해주세요.';
          break;
        }
        default: {
          if (!errorCode) {
            return;
          }
          message =
            '오류가 발생하였습니다.\n 관리자에게 문의해주세요.' +
            (message ? '\n[' + message + ']' : '') +
            (errorCode ? '\n(code: ' + errorCode + ')' : '');
        }
      }

      toastr.error('오류', message);
    }
  };

export const pullTaskHistory =
  (taskUid: Task['uid'], scriptHistoryId: number, isRollback?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startTaskScriptHistoryFetch());
      const selectedScript = await api.getTaskScriptHistory(taskUid, scriptHistoryId);

      // 되돌리기 실행시
      if (isRollback) {
        const rollbackScript = selectedScript.script.value;
        const rollbackSpeakers = selectedScript.script.speakers || [];

        // 화자 정보 롤백
        await dispatch(
          pushTaskSpeakers({ taskUid, value: rollbackSpeakers, calledByReducer: true }),
        );

        // 선택한 히스토리 ID 로 스크립트 내용 롤백하고 나서 새로고침
        await dispatch(
          pushTaskScript({
            taskUid,
            value: rollbackScript,
            format: selectedScript?.scriptFormat,
            option: {
              refresh: true,
              callLocation: 'rollback',
            },
            calledByReducer: true,
          }),
        );
      }

      dispatch(successTaskScriptHistoryFetch(selectedScript)); // repositories 스토어
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error('pullTaskHistory ', err);

      const errorData = (err as AxiosResponse<Error>).data || {};
      let message = _.get(errorData, 'message') || "'되돌리기'에서 오류가 발생했습니다.";
      const errorCode = _.get(errorData, 'code');
      dispatch(failTaskScriptHistoryFetch(errorData));
      let toastOption = {};

      if (errorCode) {
        switch (errorCode) {
          case ErrorCode.TASK_SCRIPT_HISTORY_NOT_FOUND_ERROR: {
            toastOption = { timeOut: 0, removeOnHover: false };
            message = '해당 편집히스토리를 찾을 수 없습니다.\n 관리자에게 문의해주세요.';
            break;
          }
          case ErrorCode.VALIDATION: {
            message = '잘못된 요청입니다.\n 관리자에게 문의해주세요.';
            break;
          }
          default: {
            message =
              '오류가 발생하였습니다.\n 관리자에게 문의해주세요.' +
              (message ? '\n[' + message + ']' : '') +
              (errorCode ? '\n(code: ' + errorCode + ')' : '');
          }
        }
      }

      toastr.error('오류', message, toastOption);
    }
  };
