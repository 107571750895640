import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';
import { Error } from '../../api/apiClient';
import _ from 'lodash';
import { Task } from '../../models/taskTypes';

const initialState: {
  isUpdating: boolean;
  isTypingComplete: boolean;
} = {
  isUpdating: false,
  isTypingComplete: false,
};

export const typingStatusSlice = createSlice({
  name: 'TYPING-STATUS',
  initialState,
  reducers: {
    startTypingStatusUpdate(state) {
      state.isUpdating = true;
    },
    successTypingStatusUpdate(state) {
      state.isUpdating = false;
      state.isTypingComplete = true;
    },
    failTypingStatusUpdate(state) {
      state.isUpdating = false;
      state.isTypingComplete = false;
    },
  },
});

export default typingStatusSlice.reducer;

export const { startTypingStatusUpdate, successTypingStatusUpdate, failTypingStatusUpdate } =
  typingStatusSlice.actions;

// task 상태를 "타이핑 완료" 상태로 변경 요청
export const pushTypingStatus =
  (taskUid: Task['uid']): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startTypingStatusUpdate());
      await api.updateTaskStatus(taskUid);
      dispatch(successTypingStatusUpdate());

      // '타이핑 완료' 상태 변경시 - 새로고침하여 변경된 상태값으로 다시 호출(편집 불가능 하게 함)
      window.location.reload();
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error('pushTypingStatus ', err);
      dispatch(failTypingStatusUpdate());

      const errorData = (err as AxiosResponse<Error>).data || {};
      let message = _.get(errorData, 'message');
      const errorCode = _.get(errorData, 'code');

      // TODO - 기획서에 없는 것.
      if (!message || !errorCode) {
        message = '작업상태를 변경하면서 문제가 발생하였습니다.';
      }

      toastr.error('오류', message, { timeOut: 4000 });
    }
  };
