import pageInfo from './pages';
import { configFilter } from './configFilter';
import initialShortcutKeys from "./shortcutKey";

export const baseApi = {
  host: process.env.REACT_APP_API_HOST || 'https://api.typex.kr',
  port: process.env.REACT_APP_API_PORT,
  version: '1.0',
  baseUrl: (host: string, version: string, port?: string) => {
    if (port) {
      return `${host}:${port}/api/${version}/`;
    } else {
      return `${host}/api/${version}/`;
    }
  },
};

const env = {
  ...process.env,
  API_HOST: baseApi.host,
  API_VERSION: baseApi.version,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  baseApiURL: baseApi.baseUrl(baseApi.host, baseApi.version, baseApi.port),
};


export const config = {
  api: {
    host: process.env.REACT_APP_API_HOST!,
  },
  env,
  API_TOKEN: 'token',
  page: pageInfo,
  ...configFilter,
  limit: {
    spellcheck: {
      sentence: 5000, // 문단(문장)당 최대 글자 수
      text: 50000, // 한 번 요청시 최대 입력 텍스트
    },
  },
  shortcutKey: initialShortcutKeys
};
