import * as auth from './auth';
import * as editor from './editor';
import * as agent from './agent';


const api = {
  ...auth,
  ...editor,
  ...agent
}
export default api;