import apiClient, { Data } from "./apiClient";
import { Agent } from 'src/models/agentTypes';
import { AgentTaskListData, AgentTaskListFilter } from "../features/agent/tasks/types";

/**
 * 할당받은 작업건 호출
 * @param id
 * @param filter
 */
const getAgentTaskList = async (id: Agent['id'], filter?: AgentTaskListFilter) => {
  const params = {
    // sortKey: "task.id", sortDirection: "DESC", page: 1, perPage: 5, dateKey: 'assignment.created_at', role: 'TYPING'
    ...filter,
  };

  return await apiClient.get<AgentTaskListData>(`/${id}/tasks`, params);
};

export { getAgentTaskList };
