import React, { PropsWithChildren, Ref } from 'react';
import ReactDOM from 'react-dom';
import { ClassNames } from '@emotion/react';

interface BaseProps {
  className: string;

  [key: string]: unknown;
}

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <ClassNames>
      {({ css, cx }) => (
        <button
          type="button"
          {...props}
          ref={ref}
          className={cx(
            className,
            css`
              cursor: pointer;
              color: ${reversed ? (active ? 'black' : '#ccc') : active ? '#0099ff' : '#0099ff'};
            `,
          )}
        />
      )}
    </ClassNames>
  ),
);

export const Icon = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLSpanElement>) => (
    <ClassNames>
      {({ css, cx }) => (
        <span
          {...props}
          ref={ref}
          className={cx(
            'material-icons',
            className,
            css`
              font-size: 18px;
              vertical-align: text-bottom;
            `,
          )}
        />
      )}
    </ClassNames>
  ),
);

export const Menu = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement>) => (
    <ClassNames>
      {({ css, cx }) => (
        <div
          {...props}
          ref={ref}
          className={cx(
            className,
            css`
              & > * {
                display: inline-block;
              }

              & > * + * {
                margin-left: 15px;
              }
            `,
          )}
        />
      )}
    </ClassNames>
  ),
);

export const Portal = ({ children }: { children: any }) => {
  return ReactDOM.createPortal(children, document.body);
};
