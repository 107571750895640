const pageInfo = {
  title: {
    login: '로그인',
    assignmentList: '할당목록',
    pageNotFound: 'page not found',
  },
  link: {
    home: '/agent/assignment',
    login: '/login',
    assignmentList: '/agent/assignment',
    pageNotFound: '/404',
  },
  gnvKeys: {
    assignmentList: ['1'],
  },
};

export default pageInfo;