import { Cookies } from 'react-cookie';

/**
 * 쿠키를 가져온다.
 * @name: 쿠키명
 */
export const getCookie = (name: string) => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return value ? value[2] : null;
};

/**
 * 쿠키가 있는지 확인한다.
 * @name: 쿠키명
 */
export const checkCookie = (name: string) => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return !!value;
};

/**
 * 쿠키를 추가한다.
 * @name: 쿠키명
 * @value: 쿠키값
 * @period: 만료기간 설정
 * @midnight: 만료기간의 시간을 자정으로
 * @jwtExp: jwt의 만료기간
 */

export const setCookie = (props: {
  name: string;
  value: string;
  period?: ['일' | '시' | '분', number];
  midnight?: boolean;
  jwtExp?: number;
}) => {
  const { name, value, period, midnight, jwtExp } = props;
  let setValue = `${name}=${value};`;

  let expiryDate: Date | undefined;
  const date = new Date();

  if (period) {
    const [unit, add] = period;
    switch (unit) {
      case '일':
        date.setDate(date.getDate() + add);
        /*자정*/
        midnight && date.setHours(0, 0, 0, 0);
        break;
      case '시':
        date.setHours(date.getHours() + add);
        break;
      case '분':
        date.setMinutes(date.getMinutes() + add);
        break;
    }
    expiryDate = date;
  } else {
    /*자정*/
    if (midnight) {
      date.setHours(0, 0, 0, 0);
      expiryDate = date;
    }
  }

  if (expiryDate) {
    setValue += `expires=${expiryDate.toUTCString()};`;
  } else if (jwtExp) {
    // new Date(myDecodedToken.exp * 1000)
    setValue += `expires=${new Date(jwtExp)};secure`;
  }
  document.cookie = setValue;
};

/**
 * 쿠키를 삭제한다.
 * @name: 쿠기명
 */
export const delCookie = (name: string) => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  document.cookie = `${name}=;expires=${date.toUTCString()}`;
};

const cookies = new Cookies();

export const removeCookieToken = (name: string) => {
  return cookies.remove(name, { sameSite: 'strict', path: '/' });
};
