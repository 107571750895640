import React from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled as muiStyled } from '@material-ui/core/styles';
import styled from '@emotion/styled';

interface InternalButtonProps extends ButtonProps {
  loading?: boolean;
}

function InternalButton(props: InternalButtonProps, ref: any) {
  const { loading, ...restProps } = props;

  return (
    <StyledButton {...restProps} ref={ref} disabled={loading || props.disabled}>
      {loading && (
        <StyledWrapSpinner>
          <CircularProgress size={'2rem'} />
        </StyledWrapSpinner>
      )}
      {props.children}
    </StyledButton>
  );
}

const ButtonInEditor = React.forwardRef(InternalButton);

export default ButtonInEditor;

const StyledWrapSpinner = styled.span`
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledButton = muiStyled(MuiButton)(({ theme }) => ({
  '&.Mui-disabled': {
    backgroundColor: `#e0e0e0`,
  },
}));
