import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Editor, Range, Element, Ancestor, Descendant } from 'slate'

import ElementComponent from '../components/element'
// import TextComponent from '../components/text'
import { ReactEditor, WindowEditor } from '..'
import { useSlateStatic } from './use-slate-static'
import { NODE_TO_INDEX, NODE_TO_PARENT } from '../utils/weak-maps'
import {
  RenderElementProps,
  RenderLeafProps,
  RenderPlaceholderProps,
} from '../components/editable'
import { SelectedContext } from './use-selected'
import debug from 'debug'
import { UPDATE_STATUS } from '../plugin/window-editor'
import { throttle } from 'lodash'
import useChildren from './use-children'

const log = debug('slate:w')

/**
 * Window.
 * useChildren을 기준으로 windowing을 추가한 버전
 */
const useWindow = (props: {
  decorations: Range[]
  node: Ancestor
  renderElement?: (props: RenderElementProps) => JSX.Element
  renderPlaceholder: (props: RenderPlaceholderProps) => JSX.Element
  renderLeaf?: (props: RenderLeafProps) => JSX.Element
  selection: Range | null
}) => {
  const {
    decorations,
    node,
    renderElement,
    renderPlaceholder,
    renderLeaf,
    selection,
  } = props
  const editor = useSlateStatic() as ReactEditor & WindowEditor

  const window = editor.window
  if (!window) {
    return useChildren(props)
  }

  const path = ReactEditor.findPath(editor, node)
  const children = []
  // const isLeafBlock =
  //   Element.isElement(node) &&
  //   !editor.isInline(node) &&
  //   Editor.hasInlines(editor, node)

  const containerRef = useRef<HTMLDivElement>(null)

  // noinspection JSUnusedLocalSymbols
  const [count, setCount] = useState(0)

  useLayoutEffect(() => {
    if (containerRef.current) {
      window.update(containerRef.current)
    }
  })

  useLayoutEffect(() => {
    if (containerRef.current) {
      window.adjust(containerRef.current)
    }
  })

  // 편집중에 발생한 totalItemCount의 변경을 처리함.
  // itemCount 처음 시작하는 상황(!window.totalItemCount)이면 예외
  const totalCountDelta = window.totalItemCount
    ? Math.max(node.children.length - window.totalItemCount, 0)
    : 0
  window.totalItemCount = node.children.length
  const endIndex = Math.min(
    window.startItemIndex + window.itemCount + totalCountDelta,
    window.totalItemCount
  )
  if (totalCountDelta > 0) {
    window.itemCount = endIndex - window.startItemIndex + 1
  }

  for (let i = window.startItemIndex; i < endIndex; i++) {
    const p = path.concat(i)
    const n = node.children[i] as Descendant
    const key = ReactEditor.findKey(editor, n)
    const range = Editor.range(editor, p)
    const sel = selection && Range.intersection(range, selection)

    const ds = decorations.reduce<Range[]>((acc, dec) => {
      const intersection = Range.intersection(dec, range)
      if (intersection) acc.push(intersection)
      return acc
    }, [])

    if (Element.isElement(n)) {
      children.push(
        <SelectedContext.Provider key={`provider-${key.id}`} value={!!sel}>
          <ElementComponent
            decorations={ds}
            element={n}
            key={key.id}
            renderElement={renderElement}
            renderPlaceholder={renderPlaceholder}
            renderLeaf={renderLeaf}
            selection={sel}
          />
        </SelectedContext.Provider>
      )
    }
    /* else {
      children.push(
        <TextComponent
          decorations={ds}
          key={key.id}
          isLast={isLeafBlock && i === node.children.length - 1}
          parent={node}
          renderPlaceholder={renderPlaceholder}
          renderLeaf={renderLeaf}
          text={n}
        />
      )
    }*/

    NODE_TO_INDEX.set(n, i)
    NODE_TO_PARENT.set(n, node)
  }

  log(
    'windowable rendered: ',
    window.startItemIndex,
    endIndex - 1,
    'top: ',
    window.container.top
  )

  const onScroll = useMemo(() => {
    return throttle(() => {
      window.scrollTo(containerRef.current!)
      window.options.showMinimap && setCount(count => count + 1)
    }, window.options.scrollDelay)
  }, [])

  switch (window.updateStatus) {
    case UPDATE_STATUS.updated:
    case UPDATE_STATUS.need_update:
    case UPDATE_STATUS.is_updating:
      break
    case UPDATE_STATUS.need_render:
      window.updateStatus = UPDATE_STATUS.updated
      break
  }

  const minimapRatio =
    containerRef.current &&
    containerRef.current!.offsetParent!.scrollHeight /
      (containerRef.current!.offsetParent!.clientHeight - 20)

  return (
    <>
      <div
        className="windowable"
        style={{
          height: '100%',
          overflowY: 'scroll',
          position: 'relative',
        }}
        onScroll={onScroll}
      >
        <div
          className="stick"
          contentEditable={false}
          style={{ height: window.totalHeight, width: 1 }}
        >
          &nbsp;
        </div>
        <div
          className="block-container"
          style={{
            position: 'absolute',
            top: window.container.top,
            bottom: window.container.bottom,
          }}
          ref={containerRef}
        >
          {children}
        </div>
      </div>

      {containerRef.current && window.options.showMinimap && (
        <div
          className="minimap-box"
          contentEditable={false}
          style={{
            position: 'fixed',
            zIndex: 1000,
            top: 30,
            left: 30,
            width: 165,
            height: containerRef.current!.offsetParent!.clientHeight,
            fontSize: 10,
            color: 'block',
            backgroundColor: 'white',
            boxShadow: '0 0 5px #9c9c9c',
          }}
        >
          <div
            className="minimap"
            style={{
              position: 'absolute',
              top: 10,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <div
              className="minimap-windowable"
              style={{
                position: 'absolute',
                top: 0,
                left: 90,
                width: 25,
                height:
                  containerRef.current!.offsetParent!.scrollHeight /
                  minimapRatio!,
                backgroundColor: '#bbbbbb',
              }}
            >
              <div
                className="minimap-windowable-bottom"
                style={{
                  position: 'absolute',
                  bottom: -5,
                  left: -115,
                  width: 70,
                  textAlign: 'right',
                  color: '#6b6b6b',
                  fontSize: 10,
                }}
              >
                {containerRef.current!.offsetParent!.scrollHeight} →
              </div>
            </div>
            <div
              className="minimap-container"
              style={{
                position: 'absolute',
                top: containerRef.current!.offsetTop / minimapRatio!,
                left: 90,
                width: 25,
                height: containerRef.current!.offsetHeight / minimapRatio!,
                backgroundColor: '#97bdc6',
              }}
            >
              <div
                className="minimap-container-top"
                style={{
                  position: 'absolute',
                  top: -5,
                  left: -70,
                  width: 70,
                  textAlign: 'right',
                  color: '#286c7e',
                  fontSize: 10,
                }}
              >
                ︎{containerRef.current!.offsetTop} →
              </div>

              <div
                className="minimap-container-bottom"
                style={{
                  position: 'absolute',
                  bottom: -5,
                  left: -70,
                  width: 70,
                  textAlign: 'right',
                  color: '#286c7e',
                  fontSize: 10,
                }}
              >
                ︎
                {containerRef.current!.offsetTop +
                  containerRef.current!.offsetHeight}{' '}
                →
              </div>

              <small
                style={{
                  fontSize: 10,
                  textAlign: 'center',
                }}
              >
                {window.startItemIndex} ~<br />
                {window.startItemIndex + window.itemCount - 1}
              </small>
            </div>
            <div
              className="minimap-frame"
              style={{
                position: 'absolute',
                top:
                  containerRef.current!.offsetParent!.scrollTop / minimapRatio!,
                left: 85,
                width: 35,
                height:
                  containerRef.current!.offsetParent!.clientHeight /
                  minimapRatio!,
                backgroundColor: '#ddd2a0B2',
              }}
            >
              <hr
                className="minimap-frame-threshold"
                style={{
                  position: 'absolute',
                  top: -(editor.window.options.frameThreshold / minimapRatio!),
                  width: '100%',
                  opacity: 1,
                  border: 0,
                  borderTop: '1px dotted #ddd2a0',
                  height: 0,
                  margin: 0,
                }}
              />
              <hr
                className="minimap-frame-threshold"
                style={{
                  position: 'absolute',
                  bottom: -(
                    editor.window.options.frameThreshold / minimapRatio!
                  ),
                  width: '100%',
                  opacity: 1,
                  border: 0,
                  borderTop: '1px dotted #ddd2a0',
                  height: 0,
                  margin: 0,
                }}
              />

              <div
                className="minimap-frame-top"
                style={{
                  position: 'absolute',
                  top: -5,
                  right: -70,
                  width: 70,
                  textAlign: 'left',
                  color: '#987f0e',
                  fontSize: 10,
                  opacity: 1,
                }}
              >
                ← ︎{Math.trunc(containerRef.current!.offsetParent!.scrollTop)}
              </div>

              <div
                className="minimap-frame-bottom"
                style={{
                  position: 'absolute',
                  bottom: -5,
                  right: -70,
                  width: 70,
                  textAlign: 'left',
                  color: '#987f0e',
                  fontSize: 10,
                  opacity: 1,
                }}
              >
                ← ︎
                {Math.trunc(
                  containerRef.current!.offsetParent!.clientHeight +
                    containerRef.current!.offsetParent!.scrollTop
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default useWindow
