import React, {
  PropsWithChildren,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
  forwardRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import _ from 'lodash';

import {
  changeBuffering,
  changeDuration,
  changePlaying,
  changeProgress,
  changeSeek,
  changeSpeed,
} from './playerSlice';

import ModalHotkey, { getShortcutKeyTitle, shortcutsSymbols } from './ModalHotkey';
import { RootState } from '../../app/store';
import { withStyles } from '@material-ui/core/styles';
import MaterialSlider from '@material-ui/core/Slider';
import MaterialSelect, { SelectProps } from '@material-ui/core/Select';
import { MenuItem, MenuProps } from '@material-ui/core';
import { timeStamp } from '../../utils/format';
import Tooltip from '../../components/Tooltip';
import { toastr } from 'react-redux-toastr';
import { PlayerSecondsInProgress, ShortcutEvents } from './types';
import * as Sentry from '@sentry/react';

interface PlayerProps {
  refPlayer: React.RefObject<ReactPlayer>;
  windSeconds: number;
}

export interface RefPlayerProps {
  isViewMode: boolean;
  isShowHotkeyModal: boolean;
  onPlayPause: () => void;
  onRewind: () => void;
  onFastForward: () => void;
  onCurrentWordPlay: () => void;
  onShowHotkey: () => void;
  onChangeSpeed: (actionKey: string) => void;
}

const Player = forwardRef((props: PlayerProps, ref: Ref<RefPlayerProps>) => {
  const speedOptions = useMemo(() => [0.2, 0.4, 0.6, 0.8, 1.0, 1.2, 1.4, 1.6, 1.8, 2.0], []);
  const dispatch = useDispatch();

  const { refPlayer, windSeconds } = props;

  const buffering = useSelector((rootState: RootState) => rootState.workspace.player.buffering);
  const duration = useSelector((rootState: RootState) => rootState.workspace.player.duration);
  const playedSeconds = useSelector(
    (rootState: RootState) => rootState.workspace.player.playedSeconds,
  );

  const url = useSelector((rootState: RootState) => rootState.workspace.player.playerProps.url);
  const playing = useSelector(
    (rootState: RootState) => rootState.workspace.player.playerProps.playing,
  );
  const controls = useSelector(
    (rootState: RootState) => rootState.workspace.player.playerProps.controls,
  );
  const playbackRate = useSelector(
    (rootState: RootState) => rootState.workspace.player.playerProps.playbackRate,
  );

  const { shortcutKeys } = useSelector(
    (rootState: RootState) => rootState.workspace.shortcutKeyList,
  );
  const taskUid = useSelector((rootState: RootState) => rootState.workspace.repositories.task!.uid);

  // 단축키 모달창 - 보기모드/편집모드
  const [isViewMode, setIsViewMode] = useState(true);
  const handleChangeViewMode = useCallback((value: boolean) => {
    setIsViewMode(value);
  }, []);

  // 단축키 모달창 보기
  const [isShowHotkeyModal, setIsShowHotkeyModal] = useState(false);
  const handleShowHotkey = useCallback(() => {
    setIsShowHotkeyModal(!isShowHotkeyModal);
  }, [isShowHotkeyModal, setIsShowHotkeyModal]);

  // 단축키 툴팁
  const shortcutTooltip = useMemo(() => {
    let playPause = getShortcutKeyTitle(ShortcutEvents.PLAY_PAUSE);
    let rewind = getShortcutKeyTitle(ShortcutEvents.REWIND, { windSeconds });
    let fastForward = getShortcutKeyTitle(ShortcutEvents.FAST_FORWARD, { windSeconds });
    let speedReset = getShortcutKeyTitle(ShortcutEvents.SPEED_RESET);
    let shortcutGuide = getShortcutKeyTitle(ShortcutEvents.SHORTCUT_KEY);

    if (shortcutKeys) {
      const playPauseKeys = shortcutsSymbols(shortcutKeys[ShortcutEvents.PLAY_PAUSE]);
      if (playPause && playPauseKeys) {
        playPause = playPause + ' ' + playPauseKeys.join(' + ');
      }

      const rewindKeys = shortcutsSymbols(shortcutKeys[ShortcutEvents.REWIND]);
      if (rewind && rewindKeys) {
        rewind = rewind + ' ' + rewindKeys.join(' + ');
      }

      const fastForwardKeys = shortcutsSymbols(shortcutKeys[ShortcutEvents.FAST_FORWARD]);
      if (fastForward && fastForwardKeys) {
        fastForward = fastForward + ' ' + fastForwardKeys.join(' + ');
      }

      const speedResetKeys = shortcutsSymbols(shortcutKeys[ShortcutEvents.SPEED_RESET]);
      if (speedReset && speedResetKeys) {
        speedReset = speedReset + ' ' + speedResetKeys.join(' + ');
      }

      const shortcutGuideKeys = shortcutsSymbols(shortcutKeys[ShortcutEvents.SHORTCUT_KEY]);
      if (shortcutGuide && shortcutGuideKeys) {
        shortcutGuide = shortcutGuide + ' ' + shortcutGuideKeys.join(' + ');
      }
    }

    return {
      playPause: playPause || '',
      rewind: rewind || '',
      fastForward: fastForward || '',
      speedReset: speedReset || '',
      shortcutGuide: shortcutGuide || '',
    };
  }, [shortcutKeys, windSeconds]);

  // ReactPlayer - 파일 전체 시간 지정
  const handleDuration = useCallback(
    (durationValue: number) => {
      dispatch(changeDuration(durationValue));
    },
    [dispatch],
  );

  // controls - 재생, 일시정지
  const handlePlayPause = useCallback(() => {
    dispatch(changePlaying(!playing));
  }, [dispatch, playing]);

  // controls - 일시 중지 또는 버퍼링 후 재생'
  const handlePlay = useCallback(() => dispatch(changePlaying(true)), [dispatch]);

  // controls - 재생이 끝나면 호출
  const handleEnded = useCallback(() => dispatch(changePlaying(false)), [dispatch]);

  // controls - N초 되감기
  const handleRewind = useCallback(() => {
    const currentTime = refPlayer.current!.getCurrentTime();
    const amount = currentTime - windSeconds;
    refPlayer.current!.seekTo(amount, 'seconds');
  }, [windSeconds, refPlayer]);

  // controls - N초 빨리 감기
  const handleFastForward = useCallback(() => {
    const currentTime = refPlayer.current!.getCurrentTime();
    const amount = currentTime + windSeconds;
    refPlayer.current!.seekTo(amount, 'seconds');
  }, [windSeconds, refPlayer]);

  // controls - 재생 속도 값 변경 - 선택한 값으로 변경
  const handleSelectChangeSpeed = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      dispatch(changeSpeed(parseFloat(event.target.value as string)));
    },
    [dispatch],
  );

  // controls - 재생 속도 값 변경
  const handleChangeSpeed = useCallback(
    (actionKey: string) => {
      // 단축키로 속도 변경.
      if (actionKey === ShortcutEvents.SPEED_RESET) {
        // 재생 속도 초기화
        dispatch(changeSpeed(1.0));
      } else {
        const speedOptionLength = speedOptions.length;
        const currentSpeed = playbackRate;
        const currentSpeedIndex = _.findIndex(speedOptions, (speed) => {
          return speed === currentSpeed;
        });

        let changeSpeedIndex: number = currentSpeedIndex;

        if (actionKey === ShortcutEvents.SPEED_DOWN) {
          // 느리게
          changeSpeedIndex = currentSpeedIndex - 1;
          // 속도 최저 값
          if (changeSpeedIndex < 0) {
            changeSpeedIndex = 0;
          }
        } else if (actionKey === ShortcutEvents.SPEED_UP) {
          // 빠르게
          changeSpeedIndex = currentSpeedIndex + 1;
          // 속도 최고 값
          if (changeSpeedIndex === speedOptionLength) {
            changeSpeedIndex = speedOptionLength - 1;
          }
        }
        dispatch(changeSpeed(speedOptions[changeSpeedIndex]));
      }
    },
    [dispatch, speedOptions, playbackRate],
  );

  // controls - 프로그래스에서 재생 값 받아오기
  const handleProgress = useCallback(
    (stateProgress: PlayerSecondsInProgress) => {
      // state → eg { played: 0.12, playedSeconds: 11.3, loaded: 0.34, loadedSeconds: 16.7 }
      if (!buffering) {
        dispatch(changeProgress(stateProgress));
      }
    },
    [dispatch, buffering],
  );

  // controls - seek: 미디어 재생 레일에서 트랙 위치 변경
  const handleChangeSeek = useCallback(
    (event, value: number | number[]) => {
      dispatch(changeSeek(value as number));
      refPlayer.current!.seekTo(value as number);
    },
    [dispatch, refPlayer],
  );

  // controls - 버퍼링 시작
  const handleBuffer = useCallback(() => {
    dispatch(changeBuffering(true));
  }, [dispatch]);

  // controls - 버퍼링 끝
  const handleBufferEnd = useCallback(() => {
    dispatch(changeBuffering(false));
  }, [dispatch]);

  // controls - 현재 커서 위치에서 재생
  const handleCurrentWordPlay = useCallback(() => {
    const getSelection = document.getSelection()!;
    const focusNode = getSelection && getSelection.focusNode;
    if (getSelection.type === 'Caret' && focusNode && focusNode.nodeType === 3) {
      const length = focusNode.nodeValue!.length;
      const focusOffset = getSelection.focusOffset;
      const parentElement = focusNode.parentElement;
      if (parentElement) {
        const word = parentElement.closest('.word');
        if (word) {
          // Offset 이 length을 2로 나눈 값보다 작거나 같다면 시작값을, 크다면 끝나는 값을 가져온다.
          let time;
          if (length / 2 >= focusOffset) {
            time = Number(word.getAttribute('data-start'));
          } else {
            // 끝나는 시간은 다음 어절의 시작하는 시간과 겹칠수 있어서 0.01 을 뺀 값으로 사용.
            time = Number(word.getAttribute('data-end')) - 0.01;
          }
          refPlayer.current!.seekTo(time, 'seconds');
        }
      }
    }
  }, [refPlayer]);

  // "파일 유효기간이 지났을때" 와 같은 문제로 파일 재생할 수 없는 경우
  const handleOnError = useCallback(
    (error: any, data?: any) => {
      let taskId = '';
      const savedWorkerInfo = sessionStorage.getItem(`tasks.${taskUid}.workerInfo`);

      if (savedWorkerInfo) {
        taskId = JSON.parse(savedWorkerInfo).taskId || '';
      }

      toastr.error('오류', '파일 유효기간 만료 등의 이유로 파일 재생을 할 수 없습니다.', {
        timeOut: 0,
      });
      Sentry.captureException({ 'title': '플레이어 오류', taskID: taskId, error, data });
    },
    [taskUid],
  );

  // 부모 컴포넌트에서 사용할 함수를 선언
  useImperativeHandle(
    ref,
    () => ({
      isViewMode,
      isShowHotkeyModal,
      onPlayPause: handlePlayPause,
      onRewind: handleRewind,
      onFastForward: handleFastForward,
      onCurrentWordPlay: handleCurrentWordPlay,
      onShowHotkey: handleShowHotkey,
      onChangeSpeed: handleChangeSpeed,
    }),
    [
      isViewMode,
      isShowHotkeyModal,
      handlePlayPause,
      handleRewind,
      handleFastForward,
      handleCurrentWordPlay,
      handleShowHotkey,
      handleChangeSpeed,
    ],
  );

  return (
    <>
      <div className="inner">
        <MediaPlayer
          ref={refPlayer}
          className="react-player"
          url={url}
          playing={playing}
          controls={controls}
          playbackRate={playbackRate}
          progressInterval={250}
          onPlay={handlePlay}
          onBuffer={handleBuffer}
          onBufferEnd={handleBufferEnd}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onEnded={handleEnded}
          onError={handleOnError}
        />

        {!buffering && playing ? (
          <Tooltip title={shortcutTooltip.playPause} id="buttonPause">
            <button
              type="button"
              className="button-pause button-color3"
              data-icon="true"
              onClick={handlePlayPause}
            >
              <span className="text-hidden">일시 정지</span>
            </button>
          </Tooltip>
        ) : (
          <Tooltip title={shortcutTooltip.playPause} id="buttonPlay">
            <button
              type="button"
              className="button-play button-color3"
              data-icon="true"
              onClick={handlePlayPause}
            >
              <span className="text-hidden">재생</span>
            </button>
          </Tooltip>
        )}

        <Tooltip title={shortcutTooltip.rewind} id="buttonRewind">
          <button
            type="button"
            className="button-rewind button-color3"
            data-icon="true"
            onClick={handleRewind}
          >
            <span className="number">
              {windSeconds}
              <span className="text-hidden">초 되감기</span>
            </span>
          </button>
        </Tooltip>

        <Tooltip title={shortcutTooltip.fastForward} id="buttonFastForward">
          <button
            type="button"
            className="button-fast-forward button-color3"
            data-icon="true"
            onClick={handleFastForward}
          >
            <span className="number">
              {windSeconds}
              <span className="text-hidden">초 빨리 감기</span>
            </span>
          </button>
        </Tooltip>

        <div className="wrap-rail">
          <em className="time-elapsed">
            {duration === 0 ? '--:--' : timeStamp(playedSeconds)}
            <span className="text-hidden">경과 시간/재생 시간</span>
          </em>
          <em className="time-duration">
            {
              duration === 0 ? '--:--' : timeStamp(duration) // 전체 시간
            }
            <span className="text-hidden">전체 시간</span>
          </em>
          <div className="rail">
            <ProgressSlider
              value={playedSeconds}
              min={0}
              max={duration}
              step={0.1}
              // @ts-ignore
              onChange={handleChangeSeek}
              aria-labelledby="continuous-slider"
            />
          </div>
        </div>

        <div className="wrap-speed">
          <dl className="speed-value">
            <dt className="term">
              <Tooltip title={shortcutTooltip.speedReset} id="speedReset">
                <span>재생속도</span>
              </Tooltip>
            </dt>
            <dd className="description">
              <PlaySpeedSelect
                id="controlSpeedSelect"
                value={playbackRate}
                onChange={handleSelectChangeSpeed}
                disableUnderline={true}
                speedOptions={speedOptions}
              />
            </dd>
          </dl>
        </div>

        <Tooltip title={shortcutTooltip.shortcutGuide} id="buttonShowHotkey">
          <button
            type="button"
            className="button-hotkey button-color3 button-tooltip"
            data-icon="true"
            onClick={handleShowHotkey}
          >
            <span className="text-hidden">단축키</span>
          </button>
        </Tooltip>
      </div>
      {isShowHotkeyModal && (
        <ModalHotkey
          showState={isShowHotkeyModal}
          windSeconds={windSeconds}
          onShowHotkey={handleShowHotkey}
          onChangeViewMode={handleChangeViewMode}
        />
      )}
    </>
  );
});

const MediaPlayer = React.memo(
  React.forwardRef<ReactPlayer, ReactPlayerProps>((props, ref) => (
    <ReactPlayer {...props} ref={ref} />
  )),
);

const ProgressSlider = React.memo(
  withStyles({
    root: {
      paddingTop: 11,
      paddingBottom: 10,
      marginLeft: 5,
      width: 'calc(100% - 5px)',
    },
    thumb: {
      height: 18,
      width: 18,
      backgroundColor: '#fff',
      marginTop: -8,
      marginLeft: 0,
      transform: 'translate(-50%, 0)',
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
      '&:after': {
        display: 'none',
      },
      // cursor: 'pointer'
    },
    track: {
      height: 4,
      borderRadius: 4,
      backgroundColor: '#0099ff',
    },
    rail: {
      height: 4,
      borderRadius: 4,
      backgroundColor: '#a4a4a4',
      opacity: 1,
    },
  })(MaterialSlider),
);

const Select = withStyles({
  root: {
    color: '#0099ff',
  },
  icon: {
    top: 'calc(50% - 10px)',
    fill: '#0099ff',
  },
})(MaterialSelect);

const PlaySpeedSelect = React.memo(
  (props: PropsWithChildren<SelectProps & { speedOptions: number[] }>) => {
    const { speedOptions, ...propPartial } = props;
    const menuProps: Partial<MenuProps> = useMemo(
      () => ({
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }),
      [],
    );

    return (
      <Select {...propPartial} MenuProps={menuProps}>
        {speedOptions.map((option: number, index: number) => {
          let optionText = Number.isInteger(option) ? option + '.0' : option;
          optionText = optionText + 'x';
          return (
            <MenuItem key={index} value={option}>
              {optionText}
            </MenuItem>
          );
        })}
      </Select>
    );
  },
);

export default Player;
