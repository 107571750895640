import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isWindows } from 'react-device-detect';

import '../../assets/sass/editor/editor.scss';

import AsideTaskInfo, { StyledOfAsideInfo } from './AsideTaskInfo';
import AsideSpeaker from './AsideSpeaker';
import EditorBox from './EditorBox';
import Player, { RefPlayerProps } from './Player';
import ReactPlayer from 'react-player';

import { RootState } from '../../app/store';
import { RouteComponentProps, withRouter } from 'react-router';
// @ts-ignore
import koStrings from 'react-timeago/lib/language-strings/ko';
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import classNames from 'classnames';
import Header from './Header';
import ModalCheckOrder from './ModalCheckOrder';
import queryString from 'query-string';
import storage from 'store';
import AsideTypingStatus from './TypingStatus';
import { pushTaskScript } from './editorBoxSlice';
import AsideFindReplace, { StyledAsideFindReplace } from './AsideFindReplace';
import { closeFindReplace, openFindReplace, resetFindReplace } from './findReplaceSlice';
import { TaskAssignmentRole } from '../agent/tasks/types';
import { Helmet } from 'react-helmet-async';
import AsideSpellCheck from './AsideSpellCheck';
import { Task, TaskStatus } from '../../models/taskTypes';
import { changeSpellCheckError, pullSpellCheck, resetSpellCheck } from './spellCheckSlice';
import ModalAlert from './ModalAlert';
import { ErrorCode } from '../../models';
import { resetPlayer } from './playerSlice';
import { config } from '../../config';
import { includes } from 'lodash';
import { ShortcutEvents, ShortcutKeys } from './types';
import { AgentRole } from '../../models/agentTypes';
import { setShortcutKeys } from './shortcutKeysSlice';

const Workspace = ({
  location,
  task,
}: RouteComponentProps & {
  task: Task;
}) => {
  const dispatch = useDispatch();

  const orderDescription = useSelector(
    (rootState: RootState) =>
      rootState.workspace.repositories.order!.requirementDetails.description,
  );
  const isScriptSaved = useSelector(
    (rootState: RootState) => rootState.workspace.editor.isScriptSaved,
  );

  const refPlayer = useRef<ReactPlayer>(null);
  const refPlayerComponent = useRef<RefPlayerProps>(null);

  const agentId = useSelector((rootState: RootState) => rootState.auth.agent.id);
  const taskAssignments = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.task!.taskAssignments,
  );
  const taskStatus = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.task!.status,
  );
  const playFileName = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.playFile!.name,
  );

  const { shortcutKeys } = useSelector(
    (rootState: RootState) => rootState.workspace.shortcutKeyList,
  );

  // 맞춤법 검사의 오류코드 값
  const errorCodeForSpellCheck = useSelector(
    (rootState: RootState) => rootState.workspace.spellCheck.error,
  );

  // [팝업] 찾아바꾸기 노출 상태값
  const isFindReplaceOpen = useSelector(
    (rootState: RootState) => rootState.workspace.findReplace.isOpen,
  );
  // [팝업] 맞춤법 검사 노출 상태값
  const isOpenSpellCheck = useSelector(
    (rootState: RootState) => rootState.workspace.spellCheck.isOpen,
  );

  const roles = useSelector((rootState: RootState) => rootState.auth.agent.roles);

  const [isOpenAlertSentenceOverflow, setIsOpenAlertSentenceOverflow] = useState(false); // '맞춤법 검사' - 하나의 문단이 5000자 이상일 수 없다.

  // {"s+ctrl": "save"} 형태로 단축키 변황하여, 입력 이벤트키와 일치 여부를 확인한다.
  const shortcutKeyMap: { [p: string]: string } = useMemo(() => {
    return Object.entries(shortcutKeys || {}).reduce((prev, item) => {
      const [name, keys] = item;
      return { ...prev, [keys.join('+')]: name };
    }, {});
  }, [shortcutKeys]);

  // 속기사 권한인지 확인
  const isExpert = useMemo(() => {
    return includes(roles, AgentRole.EXPERT);
  }, [roles]);

  // 편집 불가 - 작업 권한의 제한
  const readOnly = useMemo(() => {
    // "속기사" 권한 계정은 모든 작업이 가능하다.
    if (isExpert) {
      return false;
    }

    if (taskAssignments.length === 0) {
      return true;
    }

    let agentRole: undefined | TaskAssignmentRole;
    if (taskStatus === TaskStatus.PROCEEDING_TYPE) {
      agentRole = TaskAssignmentRole.TYPING;
    } else if (taskStatus === TaskStatus.PROCEEDING_QC) {
      agentRole = TaskAssignmentRole.QC;
    }

    const taskAssignmentIndex = taskAssignments.findIndex(
      (AssignmentObj) => AssignmentObj.role === agentRole,
    );

    const taskAssignment = taskAssignments[taskAssignmentIndex];

    if (!taskAssignment) {
      return true;
    }

    const {
      role,
      agent: { id: idOfAssignedAgent },
    } = taskAssignment;

    // 로그인 계정이 해당 작업을 할당 받았는지 확인
    if (idOfAssignedAgent !== agentId) {
      return true;
    }

    // (할당받았던 작업) 현재 작업 상태와 할당작업이 일치 하지 않으면 읽기만 가능
    if (role === TaskAssignmentRole.TYPING && taskStatus !== TaskStatus.PROCEEDING_TYPE) {
      // 할당작업이 '타이핑' 작업인데, 현재 작업 상태가 '타이핑 중'이 아닌 경우
      return true;
    } else if (role === TaskAssignmentRole.QC && taskStatus !== TaskStatus.PROCEEDING_QC) {
      // 할당작업이 '검수' 작업인데, 현재 작업 상태가 '검수 중'이 아닌 경우
      return true;
    } else if (
      // 현재 작업 상태가 "타이핑 중", "검수 중" 이 아닌 경우
      taskStatus !== TaskStatus.PROCEEDING_TYPE &&
      taskStatus !== TaskStatus.PROCEEDING_QC
    ) {
      return true;
    }

    return false;
  }, [agentId, taskAssignments, taskStatus, isExpert]);

  /*
   쿼리 스트링
   - queryStrPopup: 유의 사항 팝업 노출(popup)
   - windSeconds: 되감기/빨리 감기 값을 유동적으로 사용할 수 있게 - 미래를 위해서 추가 (20220412)
   */
  const { queryStrPopup, windSeconds } = useMemo(() => {
    const { popup, wind } = queryString.parse(location.search);
    const querySrtWind = Number(wind);
    let windValue = 3;
    if (!isNaN(querySrtWind) && 0 < querySrtWind && querySrtWind <= 10) {
      windValue = querySrtWind;
    }
    return {
      queryStrPopup: popup,
      windSeconds: windValue,
    };
  }, [location]);

  const taskUid = useMemo(() => {
    return task.uid;
  }, [task]);

  // '내용 설명' 에 넣은 유의사항에 대한 내용을 확인했는지 여부
  const isCheckOrder = useMemo(() => {
    return sessionStorage.getItem(`tasks.${taskUid}.isCheckOrder`) || 'false';
  }, [taskUid]);

  // 저장 버튼(단축키로 저장시에도 사용)
  const handleSave = useCallback(() => {
    // 편집 화면에서 변경한 스크립트가 DB에 저장되지 않았다면, 저장 실행.
    if (!isScriptSaved) {
      dispatch(pushTaskScript({ taskUid, option: { callLocation: 'saveButton' } })); // API 저장 요  청: script
    }
  }, [dispatch, taskUid, isScriptSaved]);

  // [팝업] 찾아 바꾸기 창 노출(창 노출되어 있는 상태에서는 창 닫기)
  const handleFindReplace = useCallback(() => {
    dispatch(openFindReplace());
  }, [dispatch]);

  // [팝업] 찾아 바뚜기 닫기
  const handleCloseFindReplace = useCallback(() => {
    dispatch(closeFindReplace());
  }, [dispatch]);

  // [팝업] 맞춤법 검사
  const handleSpellCheck = useCallback(() => {
    dispatch(pullSpellCheck());
  }, [dispatch]);
  // [팝업] 맞춤법 검사:문단별 제한글자 수를 넘긴 경우 - 닫기
  const handleConfirmAlertSentenceOverflow = useCallback(() => {
    setIsOpenAlertSentenceOverflow(false);
    dispatch(changeSpellCheckError(undefined));
  }, [dispatch]);

  // 단축키 - key down event
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { key, shiftKey, ctrlKey, metaKey, target } = event;

      // 문자 => 아스키 코드 번호
      const keyCode = event.keyCode || key.charCodeAt(0);

      // 입력키의 기본 이벤트 무효화 시킴.
      let disableKeyEvent = false;
      if (key === 'Tab' && target) {
        // 'Editor' 영역에서 'Tab' 키 입력시
        if ((target as Element).classList.contains('wrap-script')) {
          disableKeyEvent = true;
        }
      }

      const inputKeys = [key];
      shiftKey && inputKeys.push(ShortcutKeys.SHIFT);
      (ctrlKey || metaKey) && inputKeys.push(ShortcutKeys.CTRL);

      // 입력된 기본키가 저장된 단축키 목록에 등록된 이벤트 이름.
      let matchingShortcuts = shortcutKeyMap[inputKeys.join('+')];
      // 검색된 단축키가 없고, 입력키가 알파벳 대문자라면, 소문자로 변환해서 단축키 찾기
      if (
        !matchingShortcuts &&
        shiftKey &&
        key.length === 1 &&
        (keyCode === 90 || (keyCode >= 65 && keyCode <= 88))
      ) {
        inputKeys.splice(0, 1, inputKeys[0].toLowerCase());
        matchingShortcuts = shortcutKeyMap[inputKeys.join('+')];
      }

      // 팝업이 노출된 상태에서 단축키 실행되지 않고, 입력키의 기본 이벤트 실행된다.
      let isReturn = false;

      // 입력키가 단축키로 등록되어 있음
      if (matchingShortcuts) {
        // 단축키 실행되지 않는 경우
        if (isOpenSpellCheck) {
          // [팝업] 맞춤법 검사가 실행중
          isReturn = true;
        } else if (isFindReplaceOpen) {
          // [팝업] 찾기 바꾸기 실행중
          isReturn = true;
        } else if (refPlayerComponent.current?.isShowHotkeyModal) {
          // [팝업] 단축키 안내
          if (matchingShortcuts !== ShortcutEvents.SHORTCUT_KEY) {
            // '단축키 안내 팝업' 단축키 실행하지 않은 경우
            isReturn = true;
          } else if (!refPlayerComponent.current?.isViewMode) {
            // 단축키 편집모드
            isReturn = true;
          }
        }

        // 팝업 노출 상태에서 입력키의 기본 이벤트 무효화 시킴.
        if (isReturn && disableKeyEvent) {
          event.preventDefault();
          return;
        }

        if (isReturn) {
          return;
        }

        event.preventDefault();
        switch (matchingShortcuts) {
          case ShortcutEvents.PLAY_PAUSE:
            // 플레이어 재생, 일시 정지
            refPlayerComponent.current?.onPlayPause();
            break;
          case ShortcutEvents.SPEED_UP:
          case ShortcutEvents.SPEED_DOWN:
          case ShortcutEvents.SPEED_RESET:
            // 속도 변경
            refPlayerComponent.current?.onChangeSpeed(matchingShortcuts);
            break;
          case ShortcutEvents.SPELL_CHECK:
            handleSpellCheck();
            break;
          case ShortcutEvents.SHORTCUT_KEY:
            // 단축키 창
            refPlayerComponent.current?.onShowHotkey();
            break;
          case ShortcutEvents.SAVE:
            // 저장
            handleSave();
            break;
          case ShortcutEvents.REWIND:
            // 3초 되감기
            refPlayerComponent.current?.onRewind();
            break;
          case ShortcutEvents.FAST_FORWARD:
            // 3초 빨리 감기
            refPlayerComponent.current?.onFastForward();
            break;
          case ShortcutEvents.PLAY_CURRENT_LOCATION:
            // 현재 커서 위치에서 재생
            refPlayerComponent.current?.onCurrentWordPlay();
            break;
          case ShortcutEvents.FIND_REPLACE:
            // 찾아바꾸기
            handleFindReplace();
            break;
        }
      } else {
        // 입력키가 단축키로 등록되지 않음.
        // 팝업 노출 상태에서 입력키의 기본 이벤트 무효화 시킴.
        if (disableKeyEvent) {
          event.preventDefault();
          return;
        }
      }
    },
    [
      refPlayerComponent,
      shortcutKeyMap,
      handleSave,
      handleFindReplace,
      handleSpellCheck,
      isFindReplaceOpen,
      isOpenSpellCheck,
    ],
  );

  // 맞춤법 검사:문단별 제한글자 수를 넘긴 경우 - 열기
  useEffect(() => {
    if (
      errorCodeForSpellCheck &&
      errorCodeForSpellCheck === String(ErrorCode.INPUT_SENTENCE_OVERFLOW)
    ) {
      setIsOpenAlertSentenceOverflow(true);
    }
  }, [errorCodeForSpellCheck]);

  useEffect(() => {
    const checkSpeakersUpdatingStatus = (event: BeforeUnloadEvent) => {
      // 편집 화면의 스크립트가 변경되었고, 아직 변경 내용이 저장되지 않았다.
      const isScriptChanged = storage.get(`tasks.${taskUid}.isScriptChanged`);
      const isUpdatedSpeakerName = storage.get(`tasks.${taskUid}.updateSpeakerName`);
      /*
        - scriptSaveStateFromStorage === true : '스크립트' 저장이 완료되지 않은 경우.
        - isUpdatedSpeakerName: '화자 정보' 변경이 제대로 마무리 되지 않은 경우(문단별 스크립트 변경까지)에만
       */
      if (isScriptChanged || isUpdatedSpeakerName) {
        // 표준에 따라 기본 동작 방지
        event.preventDefault();
        // Chrome에서는 returnValue 설정이 필요함
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', checkSpeakersUpdatingStatus);
    return () => window.removeEventListener('beforeunload', checkSpeakersUpdatingStatus);
  }, [taskUid]);

  // '찾아 바꾸기' 창 노출시 찾기 입력창에 포커스
  useEffect(() => {
    if (isFindReplaceOpen) {
      const wordFind = document.getElementById('word-find');
      wordFind && wordFind.focus();
    }
  }, [isFindReplaceOpen]);

  useEffect(() => {
    const root = document.querySelector('#root');
    if (root) {
      root.className = 'bg';
    }

    return () => {
      if (root) {
        root.classList.remove('bg');
      }
    };
  }, []);

  // 스토어 초기화
  useEffect(() => {
    return () => {
      // 찾아 바꾸기 - 스토어 초기화
      dispatch(resetFindReplace());
      // 맞춤법 검사 - 스토어 초기화
      dispatch(resetSpellCheck());
      // 플레이어 - 스토어 초기화
      dispatch(resetPlayer());
    };
  }, [dispatch]);

  // 로컬스토리지에서 단축키 설정 값을 확인
  useEffect(() => {
    const shortcutKeysValue = localStorage.getItem('shortcutKey');
    if (shortcutKeys) {
      return;
    }

    if (!shortcutKeysValue) {
      dispatch(setShortcutKeys(config.shortcutKey.key));
      localStorage.setItem('shortcutKey', JSON.stringify(config.shortcutKey.key));
    } else {
      dispatch(setShortcutKeys(JSON.parse(shortcutKeysValue)));
    }
  }, [shortcutKeys]);

  // 단축키 실행
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Helmet>
        <title>{playFileName ? playFileName + ' - ' : ''}typeX Editor</title>
      </Helmet>
      <div
        className={classNames('editor-wrapper', {
          'isWindows': isWindows,
        })}
      >
        <div className="editor-header">
          <Header handleSave={handleSave} readOnly={readOnly} />
        </div>

        <StyledOfAsideInfo className="editor-aside-info">
          <AsideTaskInfo refPlayer={refPlayer} />
        </StyledOfAsideInfo>

        <aside className="editor-aside-edit">
          <div className="wrap-scroll">
            <AsideSpeaker readOnly={readOnly} />
          </div>
          <div className="wrap-aside-inner">
            <AsideSpellCheck readOnly={readOnly} />
            <AsideTypingStatus readOnly={readOnly} />
          </div>
          {isFindReplaceOpen && (
            <StyledAsideFindReplace
              title="찾기/바꾸기"
              placement="right"
              mask={false}
              onClose={handleCloseFindReplace}
              open={isFindReplaceOpen}
            >
              <AsideFindReplace readOnly={readOnly} />
            </StyledAsideFindReplace>
          )}
        </aside>

        <section className="editor-workspace">
          <div className="workspace-edit">
            <EditorBox refPlayer={refPlayer} readOnly={readOnly} />
          </div>
          <div className="workspace-player">
            <Player refPlayer={refPlayer} ref={refPlayerComponent} windSeconds={windSeconds} />
          </div>
        </section>
      </div>

      {
        // 유의사항 팝업
        queryStrPopup === 'visible' && !!orderDescription && JSON.parse(isCheckOrder) === false && (
          <ModalCheckOrder taskUid={taskUid} description={orderDescription} />
        )
      }

      {
        // 팝업 - 맞춤법 검사:문단별 제한글자 수를 넘긴 경우
        isOpenAlertSentenceOverflow && (
          <ModalAlert
            maxWidth="xs"
            title="알림"
            content={
              <>
                5,000자 이상인 문장이 있습니다.
                <br />
                맞춤법 검사를 위해 문장을 분리해주시기를 바랍니다.
              </>
            }
            isOpen={true}
            onConfirm={handleConfirmAlertSentenceOverflow}
          />
        )
      }
    </>
  );
};

export default withRouter(Workspace);
