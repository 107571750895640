import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditSpeakers, ScriptParagraph } from './types';
import { successTaskFetch } from './workspaceSlice';
import api from '../../api';
import { AppThunk } from '../../app/store';
import { actions as toastrActions, AddToastPayload, toastr } from 'react-redux-toastr';

import { AxiosResponse } from 'axios';
import { Error as TypeError } from '../../api/apiClient';
import _ from 'lodash';
import storage from 'store';
import { changeIsEventsFromOutside, commitScript, pushTaskScript } from './editorBoxSlice';
import moment from 'moment';
import { setCookie } from '../../utils/cookie';
import { SpeakerInfo, Task } from '../../models/taskTypes';

const initialState: EditSpeakers = {
  speakers: [],
  isSpeakersUpdating: false,
  error: null,
  errorSpeakersUpdate: null,
  processChangScript: 'ready',
};

export const editorSpeakersSlice = createSlice({
  name: 'EDIT-SPEAKERS',
  initialState,
  reducers: {
    // task 화자정보 변경
    startTaskSpeakersUpdate(state) {
      state.isSpeakersUpdating = true;
      state.errorSpeakersUpdate = null;
    },
    successTaskSpeakersUpdate(state, { payload }: PayloadAction<SpeakerInfo[]>) {
      state.isSpeakersUpdating = false;
      state.errorSpeakersUpdate = null;
      state.speakers = payload;
    },
    failTaskSpeakersUpdate(state, { payload }: PayloadAction<any>) {
      state.isSpeakersUpdating = false;
      state.errorSpeakersUpdate = payload;
    },
    // 화자 자동 지정(등록된 화자가 2명인 경우)
    startSetSpeakerAutomatically(state) {
      state.processChangScript = 'changing';
      state.error = null;
    },
    successSetSpeakerAutomatically(state) {
      state.processChangScript = 'changeSucceed';
      state.error = null;
    },
    failSetSpeakerAutomatically(state, { payload }: PayloadAction<any>) {
      state.processChangScript = 'changeFailed';
      state.error = payload;
    },
    changeProcessChangScript(
      state,
      { payload }: PayloadAction<EditSpeakers['processChangScript']>,
    ) {
      state.processChangScript = payload;
    },
    // 첫번째 문단의 화자 변경
    changeSpeakerOfFirstParagraph(state, { payload }: PayloadAction<undefined | string>) {
      state.speakerOfFirstParagraph = payload;
    },
  },
  extraReducers: {
    [successTaskFetch.type]: (state, { payload: { task } }: PayloadAction<{ task: Task }>) => {
      state.speakers = task.details.speakers.filter((item) => item.name.length > 0);

      // 등록된 화자수가 2명인 경우에만
      if (state.speakers.length === 2) {
        state.speakerOfFirstParagraph = task.script.value[0].data.speaker || undefined;
      }
      return state;
    },
  },
});

export default editorSpeakersSlice.reducer;

export const {
  startTaskSpeakersUpdate,
  successTaskSpeakersUpdate,
  failTaskSpeakersUpdate,
  startSetSpeakerAutomatically,
  successSetSpeakerAutomatically,
  failSetSpeakerAutomatically,
  changeProcessChangScript,
  changeSpeakerOfFirstParagraph,
} = editorSpeakersSlice.actions;

/**
 * 작업건의 전체 화자정보를 수정(화자 추가, 삭제, 화자 이름/특징 변경)
 * @param taskUid
 * @param value - 전체 화자 정보
 * @param calledByReducer - '히스토리' 목록에서 특정 시점으로 '되돌리기'기 화자 정보도 되돌리기. 화자 이름/특징 변경시 연결됨.
 */
export const pushTaskSpeakers =
  ({
    taskUid,
    value,
    calledByReducer,
  }: {
    taskUid: Task['uid'];
    value: SpeakerInfo[];
    calledByReducer?: boolean;
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      const {
        details: { speakers },
      } = await api.updateTaskSpeakers(taskUid, value);

      dispatch(startTaskSpeakersUpdate());
      dispatch(successTaskSpeakersUpdate(speakers));

      // 화자 추가/삭제 경우 - 2명의 화자인 경우 화자 자동 지정 관련 정보를 변경한다.
      if (speakers.length === 2) {
        const speakerOfFirstParagraph =
          getState().workspace.editor.script[0].data.speaker || undefined;
        dispatch(changeSpeakerOfFirstParagraph(speakerOfFirstParagraph));
      }
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error('pushTaskSpeakers ', err);

      const errorData = (err as AxiosResponse<TypeError>).data || {};
      dispatch(failTaskSpeakersUpdate(errorData));

      // TODO - 기획서에 없는 것.
      toastr.error('오류', '화자 정보 수정 중 오류가 발생했습니다.');

      // 컴포넌트 이외에서 호출한 경우 - try catch에서 error를 반환 받아야 함.
      if (calledByReducer) {
        throw new Error(err);
      }
    }
  };

// 특정 화자의 이름/특징 변경
type TypeChangeSpeakersInfo = {
  taskUid: Task['uid'];
  updateSpeakers: SpeakerInfo[];
  beforeName: string;
  afterName: string;
};

export const changeSpeakersInfo =
  ({ taskUid, updateSpeakers, beforeName, afterName }: TypeChangeSpeakersInfo): AppThunk =>
  async (dispatch, getState) => {
    const editorScript = getState().workspace.editor.script as ScriptParagraph[];

    try {
      storage.remove(`tasks.${taskUid}.updateSpeakerName`);
      // API 저장: 화자 task >  detail > speakers
      await dispatch(
        pushTaskSpeakers({
          taskUid,
          value: updateSpeakers,
          calledByReducer: true,
        }),
      );

      // 화자명이 변경 되었을 때에만 진행: 변경전 이름과 변경후 이름이 같다면, 화자명이 아닌 화자 특징을 변경 한 것.
      if (editorScript && beforeName !== afterName) {
        // 삭제, 변경된 이름이 문단별 '화자'로 지정되어 있지 않다면 스크립트 저장 필요 없음.
        const sameSpeakerInParagraph = _.filter(editorScript, (o) => o.data.speaker === beforeName);

        // 변경(삭제)된 화자가 문단별 화자와 겹치는 문단이 있다, 로컬스토리지에 변경전 화자 정보 저장
        if (sameSpeakerInParagraph.length > 0) {
          // 변경 화자 {before: 변경(삭제)전 화자 이름, after: 변경(삭제)후 화자 이름}
          storage.set(`tasks.${taskUid}.updateSpeakerName`, {
            before: beforeName,
            after: afterName,
          });

          // 변경된 '화자 정보' 에 맞게 에디터의 문단별 화자를 변경한 값.
          const changedScript = _.map(editorScript, (item) => {
            if (item.data.speaker === beforeName) {
              return {
                ...item,
                data: {
                  ...item.data,
                  speaker: afterName,
                },
              };
            }
            return { ...item };
          });

          await dispatch(changeSpeakerOfScriptParagraph({ taskUid, changedScript }));
        }
      }

      storage.remove(`tasks.${taskUid}.updateSpeakerName`);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error, { before: beforeName, after: afterName });
      const isUpdatedSpeakerName = storage.get(`tasks.${taskUid}.updateSpeakerName`);
      // task 의 화자 정보는 수정했으나, 스크립트 수정은 실피한 경우
      if (isUpdatedSpeakerName) {
        toastr.error('오류', "변경된 '화자 정보'에 따라 문단별 화자 변경에 실패하였습니다.");
      }
    }
  };

// 화자 자동 지정: 등록된 화자가 2명인 경우
export const changeSetSpeakersAutomatically =
  (taskUid: Task['uid'], firstIndex: number, speakers: SpeakerInfo[]): AppThunk =>
  async (dispatch, getState) => {
    const workspaceEditor = getState().workspace.editor;
    const editorScript = workspaceEditor.script as ScriptParagraph[];

    const secondIndex = firstIndex === 0 ? 1 : 0;
    let currentIndex = firstIndex;

    const changedScript = _.map(editorScript, (item) => {
      /*줄바꿈 문단은 그대로 반환*/
      if (item.data.type === 'lineBreak') {
        return item;
      } else {
        const name = speakers[currentIndex].name;
        currentIndex = firstIndex === currentIndex ? secondIndex : firstIndex;

        return {
          ...item,
          data: {
            ...item.data,
            speaker: name,
          },
        };
      }
    });

    try {
      dispatch(startSetSpeakerAutomatically());
      await dispatch(changeSpeakerOfScriptParagraph({ taskUid, changedScript }));

      // 날짜 형식을 기획서에 맞추기 위해서
      const currentTime = moment().format('YYYY-MM-DD h:mm a').split(' ');
      const currentTimeString = [currentTime[0], currentTime[2], currentTime[1]].join(' ');
      setCookie({
        name: `tasks.${taskUid}.speakerAutomaticallySetDate`,
        value: currentTimeString,
        period: ['일', 2],
      });
      toastr.success('알림', '화자 자동 지정이 완료되었습니다.');
      dispatch(successSetSpeakerAutomatically());
    } catch (error) {
      // TODO - 기획서에 없는 것.
      // tslint:disable-next-line:no-console
      toastr.error('오류', "'화자 자동 지정' 작업중 오류가 발생했습니다.");
      dispatch(failSetSpeakerAutomatically(error));
    }
  };

//  (스크립트)문단별 화자 변경
type TypeChangeSpeakerOfScriptParagraph = {
  taskUid: Task['uid'];
  changedScript: ScriptParagraph[];
};
const changeSpeakerOfScriptParagraph =
  ({ taskUid, changedScript }: TypeChangeSpeakerOfScriptParagraph): AppThunk =>
  async (dispatch) => {
    try {
      // 에디터 편집 불가 상태 알림 토스트창
      dispatch(toastrActions.add(toastNoticeScriptUpdating));
      dispatch(changeIsEventsFromOutside(true));

      // API 저장: 스크립트 task > script
      await dispatch(
        pushTaskScript({
          taskUid,
          value: changedScript,
          option: { callLocation: 'speakerUpdate' },
          calledByReducer: true,
        }),
      );

      // 스토어 저장: 변경된 화자 정보에 맞게 편집 화면의 스크립트 문단에서 "화자"를 변경한 스크립트를 넘겨준다. 스크립트가 db에 저장된 후에 진행되므로 isScriptSaved: true
      dispatch(commitScript({ changedScript, isScriptSaved: true }));
    } catch (error) {
      dispatch(changeIsEventsFromOutside(false));
      throw new Error(error);
    } finally {
      dispatch(toastrActions.remove('noticeScriptUpdating'));
    }
  };

/*
  '화자 정보' 변경후 문단별 화자를 변경된 값으로 바꾸는 중에는 편집 하지 않도록 안내 문구 팝업.
  ['화자 정보' 변경후 문단별 화자를 변경된 값]으로 스크립트를 바꾸고 나서 에디터의 value를 변경된 스크립트로 변경한다.(때문에 이 순간 에디터를 readOnly 상태로 만듬)
*/
const toastNoticeScriptUpdating: AddToastPayload = {
  id: 'noticeScriptUpdating',
  type: 'warning',
  title: "문단별 '화자'를 변경하고 있습니다.",
  position: 'bottom-center',
  message: '해당 창이 사라질 때까지 편집할 수 없습니다.',
  options: {
    timeOut: 0,
    removeOnHover: false,
    className: 'notice-script-updating',
  },
};
