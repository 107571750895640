import React from 'react';
import Linkify from 'react-linkify';

const LinkInText = ({ children }: { children: React.ReactNode }) => {
  const componentDecorator = (decoratedHref: string, decoratedText: string, key: number) => (
    <a target="blank" href={decoratedHref} key={key}>
      {decoratedText}
    </a>
  );

  return <Linkify componentDecorator={componentDecorator}>{children}</Linkify>;
};

export default LinkInText;