import _ from 'lodash';
import { ScriptParagraph } from './types';
import { Task } from "../../models/taskTypes";

/*** 문단 시작 부분에 공백이 있다면 제거 ***/
export const removeSpacesBeginningParagraphs = (paragraphs: Task['script']['value']) => {
  if (typeof paragraphs === 'string') {
    return paragraphs;
  }

  return _.map(paragraphs, (paragraph, index) => {
    return removeSpacesBeginningParagraph(paragraph, index);
  });
};

const removeSpacesBeginningParagraph = (paragraph: ScriptParagraph, index: number) => {
  let resultParagraph = { ...paragraph };
  const firstWord = resultParagraph.children[0];

  if (firstWord) {
    const firstWordText = firstWord.text || '';
    const isEmptyWord = firstWordText.search(/\S/); // -1 이면 공백으로만 이뤄진 단어.
    /*첫번째 단어가 모두 공백인 경우를 위한 변수*/
    const secondWord = resultParagraph.children[1];

    if (secondWord && isEmptyWord === -1) {
      // 삭제되는 단어 다음의 단어 시작 시간은 삭제되는 단어의 시작 시간 data: {dataStart:}
      const dataStart = firstWord.data.dataStart;
      const newParagraph = {
        ...resultParagraph,
        // children: resultParagraph.children.splice(1),
        children: resultParagraph.children.slice(1),
      };

      // newParagraph.children[0].data.dataStart = dataStart;
      const newChildren = newParagraph.children;
      const newFirstWord = newChildren[0];

      newChildren[0] = {
        ...newFirstWord,
        data: {
          ...newFirstWord.data,
          dataStart,
        },
      };
      newParagraph.children = newChildren;

      resultParagraph = removeSpacesBeginningParagraph(newParagraph, index);
      // 0714f
    } else if (!secondWord || isEmptyWord) {
      /*첫번째 단어가 모두 공백인 경우*/
      /* 첫번째 단어의 앞에 공백 */
      // resultParagraph.children[firstIndex].text = firstWordText.replace(/^\s+/,"");
      const children = [...resultParagraph.children];
      children[0] = {
        ...firstWord,
        text: firstWordText.replace(/^\s+/, ''),
      };
      resultParagraph.children = children;
    }
  }

  return resultParagraph;
};
