import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShortcutKeyObj } from "./types";

const initialState: { shortcutKeys?: { [p: string]: string[] } } = {};

export const shortcutKeysSlice = createSlice({
  name: 'SHORTCUT_KEYS',
  initialState,
  reducers: {
    // 모든 단축키 저장
    setShortcutKeys(state, { payload }: PayloadAction<ShortcutKeyObj>) {
      state.shortcutKeys = payload;
    },
    // 단축키 저장
    setShortcutKey(
      state,
      { payload: { name, key } }: PayloadAction<{ name: string; key: string[] }>,
    ) {
      if (state.shortcutKeys) {
        state.shortcutKeys[name] = key;
      }
    },
  },
});

export default shortcutKeysSlice.reducer;

export const { setShortcutKey, setShortcutKeys } = shortcutKeysSlice.actions;
