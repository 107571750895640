import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Player, PlayerSecondsInProgress } from './types';
import { successTaskFetch } from './workspaceSlice';
import { File, Task } from '../../models/taskTypes';

const initialState: Player = {
  buffering: false,
  loadedSeconds: 0,
  playedSeconds: 0,
  playedSecondsForScroll: undefined,
  duration: 0,
  playerProps: {
    url: '',
    playing: false,
    controls: false,
    playbackRate: 1.0,
    pip: false,
    light: false,
    volume: 0.8,
    muted: false,
    loop: false,
  },
};

export const playerSlice = createSlice({
  name: 'PLAYER',
  initialState,
  reducers: {
    resetPlayer(state) {
      state.buffering = initialState.buffering;
      state.loadedSeconds = initialState.loadedSeconds;
      state.playedSeconds = initialState.playedSeconds;
      state.playedSecondsForScroll = initialState.playedSecondsForScroll;
      state.duration = initialState.duration;
      state.playerProps = initialState.playerProps;
    },
    // seek: 미디어 재생 레일에서 트랙 위치 변경
    changeSeek(state: Player, action: PayloadAction<Player['playedSeconds']>) {
      state.playedSeconds = action.payload;
      state.playedSecondsForScroll = action.payload;
    },
    // 프로그래스에서 재생 값 받아오기
    changeProgress(state: Player, action: PayloadAction<PlayerSecondsInProgress>) {
      const { payload } = action;
      state.playedSeconds = payload.playedSeconds;
      state.loadedSeconds = payload.loadedSeconds;
    },
    // 재생 속도 값 변경
    changeSpeed(state, { payload }: PayloadAction<number>) {
      state.playerProps.playbackRate = payload;
    },
    // 재생, 일시정지
    changePlaying(state, { payload }: PayloadAction<boolean>) {
      state.playerProps.playing = payload;
    },
    // 버퍼링
    changeBuffering(state, { payload }: PayloadAction<boolean>) {
      state.buffering = payload;
    },
    //
    changeDuration(state, { payload }: PayloadAction<number>) {
      state.duration = payload;
    },
  },
  extraReducers: {
    [successTaskFetch.type]: (
      state,
      { payload: { task } }: PayloadAction<{ task: Task & { playFile: File } }>,
    ) => {
      state.playerProps.url = task.playFile.downloadLink;
      return state;
    },
  },
});

export default playerSlice.reducer;

export const {
  resetPlayer,
  changeSeek,
  changeProgress,
  changeSpeed,
  changeDuration,
  changeBuffering,
  changePlaying,
} = playerSlice.actions;
