import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Button from '../../components/Button';
import { pullSpellCheck } from './spellCheckSlice';
import ModalSpellCheck from './ModalSpellCheck';

const AsideSpellCheck = ({ readOnly }: { readOnly?: boolean }) => {
  const dispatch = useDispatch();

  const isOpenSpellCheck = useSelector(
    (rootState: RootState) => rootState.workspace.spellCheck.isOpen,
  );

  // 맞춤법 검사 팝업창 실행
  const handleOpenSpellCheck = useCallback(() => {
    dispatch(pullSpellCheck());
  }, [dispatch]);

  return (
    <>
      <Button
        size="large"
        type="primary"
        ghost={true}
        disabled={readOnly}
        onClick={handleOpenSpellCheck}
        className="w-full mb-20px"
      >
        맞춤법 검사
      </Button>

      {/* 팝업 - 맞춤법 검사*/}
      {isOpenSpellCheck && <ModalSpellCheck />}
    </>
  );
};

export default React.memo(AsideSpellCheck);
